import axios, { AxiosRequestConfig } from "axios";
import Cookies from "js-cookie";

import { checkSessionExists, setTokenInCookies } from "../config/appwrite";

const baseForPdf = axios.create({
  baseURL: process.env.REACT_APP_READER_URL,
  timeout: 30000,
});

baseForPdf.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const authToken = Cookies.get("auth_token");
  if (!authToken && config.headers) {
    const session = await checkSessionExists();
    if (session) {
      const token = await setTokenInCookies();
      config.headers["Authorization"] = `Bearer ${token}`;
    }
  } else if (authToken && config.headers) {
    config.headers["Authorization"] = `Bearer ${authToken}`;
  }
  return config;
});

baseForPdf.interceptors.response.use((response: { data: null }) => {
  return response;
});

export default baseForPdf;
