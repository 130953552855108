import React from "react";

interface Props {
  width: string;
}

const TopNotch: React.FC<Props> = ({ width = "24" }) => (
  <svg
    width={width}
    height={width}
    viewBox={`0 0 24 24`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_18059_106511)">
      <path
        d="M23.7941 16.4712L21.9351 14.6123V8.2358L23.1487 7.79827C23.4273 7.69778 23.6131 7.43357 23.6134 7.13734C23.6136 6.84122 23.4282 6.57663 23.1497 6.47581L12.0461 2.45461C11.8914 2.3986 11.7219 2.3986 11.5673 2.45461L0.463694 6.47581C0.186268 6.5763 0.00112922 6.83925 4.33015e-06 7.1343C-0.00102682 7.42935 0.18219 7.69365 0.45882 7.79616L4.55666 9.31415V13.9476C4.55666 14.0914 4.60072 14.2318 4.68298 14.3497C4.72821 14.4146 5.15665 14.9996 6.25361 15.5518C7.63943 16.2494 9.45707 16.6031 11.656 16.6031C13.8532 16.6031 15.6801 16.2501 17.0857 15.5539C18.1935 15.0052 18.6361 14.4262 18.683 14.362C18.7708 14.2417 18.818 14.0966 18.818 13.9477V9.35952L20.5291 8.74266V14.6123L18.6701 16.4712C18.5382 16.6031 18.4641 16.7819 18.4641 16.9683C18.4641 17.1548 18.5382 17.3337 18.6701 17.4655L20.5291 19.3244V20.8841C20.5291 21.2724 20.8438 21.5872 21.2321 21.5872C21.6204 21.5872 21.9352 21.2724 21.9352 20.8841V19.3244L23.7941 17.4655C24.0687 17.191 24.0687 16.7458 23.7941 16.4712ZM11.8067 3.86339L20.8415 7.13538L11.6593 10.4458L2.74713 7.14433L11.8067 3.86339ZM17.4119 13.6674C15.0084 15.8301 7.86638 15.5821 5.96278 13.6744V9.83507L11.4117 11.8536C11.4982 11.8673 11.5798 11.9419 11.8944 11.8558L17.4119 9.86657L17.4119 13.6674ZM21.2321 18.039L20.1614 16.9683L21.2321 15.8977L22.3027 16.9683L21.2321 18.039Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_18059_106511">
        <rect width={24} height={24} fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

export default TopNotch;
