import React from "react";

interface CustomIconProps {
  filled?: boolean;
}

const EmailIcon: React.FC<CustomIconProps> = ({ filled = false }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="transition-all"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 9.34884C2 6.39476 4.39476 4 7.34884 4H16.6512C19.6052 4 22 6.39476 22 9.34884V14.9302C22 17.8843 19.6052 20.2791 16.6512 20.2791H7.34884C4.39476 20.2791 2 17.8843 2 14.9302V9.34884ZM8.0526 9.92477C7.72915 9.66601 7.25718 9.71845 6.99843 10.0419C6.73967 10.3653 6.79211 10.8373 7.11556 11.0961L10.5173 13.8175C11.5217 14.621 12.9488 14.621 13.9532 13.8175L17.3549 11.0961C17.6784 10.8373 17.7308 10.3653 17.4721 10.0419C17.2133 9.71845 16.7413 9.66601 16.4179 9.92477L13.0161 12.6462C12.5596 13.0114 11.9109 13.0114 11.4544 12.6462L8.0526 9.92477Z"
      fill={filled ? "#C3C3C3" : "#94969E"}
    />
  </svg>
);

export default EmailIcon;
