import React, { useEffect, useState } from "react";

import { useRecoilState, useRecoilValue } from "recoil";

import { Button, DatePicker, Empty, Table } from "antd";
import calculateDateDifference from "../../../shared/basic/daysDifference";
import { dateFilter } from "../../../../atoms/dateFilter";
import extractErrorMessage from "../../../shared/basic/formateError";
import { notify } from "../../../shared/basic/notify";
import { ShouldRender } from "../../../shared/basic/ShouldRender";
import { getAllUsers } from "../../../../services/Admin/dashboard";
import { IAllUserDataTable } from "../../../../utils/interfaces/Admin/dashboard";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { CopyOutlined } from "@ant-design/icons";
import { formatIndianCurrency } from "../../../shared/formatIndianCurrency";
import dayjs, { Dayjs } from "dayjs";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

const AllUsers: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IAllUserDataTable>();
  const [page, setPage] = useState<number>(1);
  const dateFilterValue = useRecoilValue(dateFilter);
  const router = useNavigate();
  const zebraLearnBaseUrl = process.env.REACT_APP_ZEBRALEARN_BASE_URL;
  const [, setDateFilter] = useRecoilState(dateFilter);
  const screens = useBreakpoint();
  const { months, remainingDays } = calculateDateDifference(
    dateFilterValue?.start_date,
    dateFilterValue?.end_date
  );

  const handleOrdersList = async (page: number) => {
    try {
      setLoading(true);
      const response = await getAllUsers({
        page,
        start_date: dateFilterValue?.start_date,
        end_date: dateFilterValue?.end_date,
      });

      setData({
        current_page: response?.current_page,
        per_page: response?.per_page,
        total: response?.total,
        usersData: response?.data?.map((user: any) => {
          return {
            name: user?.user?.name,
            id: user?.id,
            key: user?.id,
            joiningData: user?.created_at,
            userId: user?.user_id,
            commission: user?.total_earnings,
            code: user?.code,
            link_clicked_count: user?.link_clicked_count || 0,
          };
        }),
      });

      response?.meta?.current_page && setPage(response?.meta?.current_page);
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (page) {
      handleOrdersList(page);
    }
  }, [page, dateFilterValue]);

  const handleCopyLink = (url: string, code?: string) => {
    navigator.clipboard
      .writeText(`${url}?afc=${code}`)
      .then(() => {
        notify("Associate Link copied to clipboard successfully!!", "success");
      })
      .catch((error) => {
        console.error("Error copying link to clipboard: ", error);
      });
  };

  const columns = [
    {
      title: (
        <span className="text-xs flex py-2 justify-start md:pl-10">
          Associates Name
        </span>
      ),

      key: "name_column",
      render: (text: any) => {
        return (
          <div
            onClick={() => router(`/profile-settings/${text?.userId}`)}
            className="flex gap-x-4 items-center font-medium md:pl-10 justify-start hover:cursor-pointer hover:underline text-center"
          >
            {text?.name}
            <p>( {text?.code} )</p>
          </div>
        );
      },
    },
    {
      title: <span className="text-xs flex py-2 justify-center ">Links</span>,

      key: "name_column",
      render: (record: any) => {
        return (
          <div className="flex gap-x-4 items-center font-medium justify-center hover:cursor-pointer hover:underline text-center">
            <Button
              size="small"
              onClick={() => {
                handleCopyLink(`${zebraLearnBaseUrl}` as string, record?.code);
              }}
              className="text-primary border-[#501801] text-sm"
              icon={<CopyOutlined className="w-4 h-4" />}
            >
              Get Associate Link of Zebralearn
            </Button>
          </div>
        );
      },
    },
    {
      title: <span className="text-xs flex  justify-center">Join Date</span>,
      dataIndex: "joiningData",
      key: "joiningData_column",
      render: (text: string) => {
        return (
          <div className="flex font-medium text-center justify-center ">
            {moment.utc(text).format("DD/MM/YYYY")}
          </div>
        );
      },
    },
    {
      title: (
        <span className="text-xs flex justify-center text-center">User ID</span>
      ),
      dataIndex: "userId",
      key: "userId_column",
      render: (text: string) => {
        return <div className="flex font-medium justify-center ">{text}</div>;
      },
    },
    {
      title: (
        <span className="text-xs flex justify-center text-center">
          Links Clicked
        </span>
      ),
      dataIndex: "link_clicked_count",
      width: 100,
      key: "link_clicked_count",
      render: (text: string) => {
        return <div className="flex font-medium justify-center ">{text}</div>;
      },
    },
    {
      title: (
        <span className="text-xs flex justify-center text-center">
          Total Earning
        </span>
      ),
      dataIndex: "commission",
      key: "commission_column",
      render: (text: string) => {
        return (
          <div className="flex font-medium justify-center ">
            ₹ {formatIndianCurrency(+text)}
          </div>
        );
      },
    },
  ];

  const rowClassName = (_: any, index: number) => {
    return index % 2 === 0 ? "bg-[#131313]" : "bg-bgColor";
  };

  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      setDateFilter({
        start_date: dateStrings[0] as string,
        end_date: dateStrings[1] as string,
      });
    } else {
      setDateFilter({
        start_date: "" as string,
        end_date: "" as string,
      });
    }
  };

  return (
    <div className="bg-readerLightGrayBg rounded-xl ">
      <div className="py-4 px-6 flex flex-col md:flex-row gap-x-2 items-center">
        <p className="font-semibold flex justify-start text-start text-base lg:text-lg whitespace-nowrap">
          All Users{" "}
        </p>
        <ShouldRender check={months || remainingDays}>
          <p className="text-textGrayColor">
            (for last{" "}
            <ShouldRender check={months}>{months} months</ShouldRender>{" "}
            <ShouldRender check={remainingDays}>
              {" "}
              {remainingDays} days
            </ShouldRender>
            )
          </p>
        </ShouldRender>
      </div>
      <ShouldRender check={!screens?.md}>
        <span className="flex py-2 justify-center">
          <DatePicker.RangePicker
            onChange={onRangeChange}
            disabledDate={(current) =>
              current && current > dayjs().endOf("day")
            }
          />
        </span>
      </ShouldRender>

      <div className="customScrollBar ">
        <Table
          dataSource={data?.usersData}
          loading={loading}
          className={`tableHeader  rounded-none customTable overflow-auto customScrollBar`}
          columns={columns}
          rowKey="key"
          pagination={{
            current: page,
            pageSize: data?.per_page,
            total: data?.total,
            onChange: (newPage) => {
              setPage(newPage);
            },
          }}
          rowClassName={rowClassName}
          locale={{
            emptyText: (
              <span>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>No Orders</span>}
                  className={`h-40 flex justify-center items-center flex-col`}
                />
              </span>
            ),
          }}
          scroll={
            data?.usersData?.length
              ? { x: "max-content", y: "100vh" }
              : undefined
          }
        />
      </div>
    </div>
  );
};

export default AllUsers;
