import React, { ReactNode } from "react";

interface CardProps {
  children: ReactNode;
}

const Card: React.FC<CardProps> = ({ children }) => {
  return (
    <div>
      <div className="relative bg-readerLightGrayBg rounded-xl ">
        {children}
      </div>
    </div>
  );
};
export default Card;
