import React from "react";
import { CongratulationsImage } from "../../../../../assets";

const FinalStep: React.FC = () => {
  return (
    <div className="pb-16">
      <span className=" flex justify-center px-7  pb-5">
        <img
          src={CongratulationsImage}
          alt="Logo"
          className="object-contain w-44 h-48"
        />
      </span>
      <span className="flex justify-center flex-col text-center gap-y-2">
        <p className="text-2xl font-medium">Congratulations</p>
        <p className="text-textGrayColor leading-4">
          We are so excited for you to be the part of our zebralearn team,
          congratulation on this first step and all the very best for your
          future endevours
        </p>
      </span>
    </div>
  );
};
export default FinalStep;
