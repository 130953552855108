import React, { useEffect, useState } from "react";
import Card from "../Card";
import {
  getAdminOrderSummary,
  getOrderSummary,
} from "../../../../services/dashboard";
import { notify } from "../../../shared/basic/notify";
import extractErrorMessage from "../../../shared/basic/formateError";
import {
  IOrderSummaryData,
  OrderSummary,
} from "../../../../utils/interfaces/dashboard";
import { ShouldRender } from "../../../shared/basic/ShouldRender";
import { Spin } from "antd";
import moment from "moment";
import { useRecoilValue } from "recoil";
import { dateFilter } from "../../../../atoms/dateFilter";
import calculateDateDifference from "../../../shared/basic/daysDifference";
import { useParams } from "react-router-dom";
import { formatIndianCurrency } from "../../../shared/formatIndianCurrency";

const SummarySection = () => {
  const [loading, setLoading] = useState(false);
  const [summaryData, setSummaryData] = useState<IOrderSummaryData>();
  const dateFilterValue = useRecoilValue(dateFilter);
  const { id } = useParams();

  const { months, remainingDays } = calculateDateDifference(
    dateFilterValue?.start_date,
    dateFilterValue?.end_date
  );

  const handleOrderSummary = async () => {
    try {
      setLoading(true);
      let response;
      if (id) {
        response = await getAdminOrderSummary(
          +id,
          dateFilterValue?.start_date,
          dateFilterValue?.end_date
        );
      } else {
        response = await getOrderSummary(
          dateFilterValue?.start_date,
          dateFilterValue?.end_date
        );
      }

      setSummaryData({
        total_earnings: response?.total_earnings || 0,
        total_tds: response?.total_tds || 0,
        total_registration: response?.total_registration || 0,
        total_click: response?.total_click || 0,
        total_orders: response?.total_orders || 0,
        total_purchases: response?.total_purchases || 0,
      });
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleOrderSummary();
  }, [dateFilterValue]);

  return (
    <div>
      <Card>
        <div className="w-full">
          <span className="flex text-lg items-center gap-x-2 py-3 px-4 border-b-2 border-black">
            <p className="font-semibold">Summary</p>{" "}
            <ShouldRender check={months || remainingDays}>
              <p className="font-light text-textGrayColor text-sm">
                (for last{" "}
                <ShouldRender check={months}>{months} months</ShouldRender>{" "}
                <ShouldRender check={remainingDays}>
                  {" "}
                  {remainingDays} days
                </ShouldRender>
                )
              </p>
            </ShouldRender>
          </span>
          <ShouldRender check={!loading}>
            <span className="flex flex-col gap-y-3 py-5 w-full px-6">
              {summaryData &&
                Object.entries(summaryData).map(([key, value]) => (
                  <span key={key} className="flex justify-between w-full">
                    <p className="text-textGrayColor text-sm">
                      {OrderSummary[key as keyof typeof OrderSummary]}
                    </p>{" "}
                    <p className="font-semibold">
                      {" "}
                      {(key === "total_earnings" ||
                        key === "total_purchases" ||
                        key === "total_tds") &&
                        "₹"}{" "}
                      {formatIndianCurrency(value)}
                      {(key === "total_earnings" ||
                        key === "total_purchases" ||
                        key === "total_tds") &&
                        "/-"}{" "}
                    </p>
                  </span>
                ))}
            </span>
          </ShouldRender>
          <ShouldRender check={loading}>
            <span className="py-10 flex justify-center items-center">
              {" "}
              <Spin size="large" />
            </span>
          </ShouldRender>

          <span className="flex text-xs gap-x-2 font-light justify-end text-textGrayColor py-3 px-4 ">
            <p>Last Updated </p> {moment().format("MMM DD YYYY")}
          </span>
        </div>
      </Card>{" "}
    </div>
  );
};
export default SummarySection;
