import React, { ReactElement, ReactNode } from "react";
import { LogoWhite, loginGirl } from "../../assets";
import BoostRevenue from "../../assets/Icons/BoostRevenue";
import TopNotch from "../../assets/Icons/TopNotch";
import MakeDifference from "../../assets/Icons/MakeDifference";
import ButtonDefault, { ButtonVariants } from "../shared/basic/button";
import clsx from "clsx";
import { ShouldRender } from "../shared/basic/ShouldRender";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Affix } from "antd";

interface Props {
  children?: ReactNode;
  setShowFields: (value: boolean) => void;
  showFields: boolean;
}

const AuthLayout: React.FC<Props> = ({
  children,
  setShowFields,
  showFields,
}) => {
  const screens = useBreakpoint();
  const commissionBenefits: { icon: ReactElement; value: string }[] = [
    {
      icon: (
        <div
          style={{ background: "#0000004D" }}
          className=" flex justify-center items-center p-2.5 w-fit rounded-full"
        >
          <BoostRevenue width={screens.md ? "24" : "16"} />
        </div>
      ),
      value: "Boost your Revenue",
    },
    {
      icon: (
        <div
          style={{ background: "#0000004D" }}
          className=" flex justify-center items-center p-2.5 w-fit rounded-full"
        >
          <TopNotch width={screens.md ? "24" : "16"} />
        </div>
      ),
      value: "Promote top-notch Educational Content",
    },
    {
      icon: (
        <div
          style={{ background: "#0000004D" }}
          className=" flex justify-center items-center p-2.5 w-fit rounded-full"
        >
          <MakeDifference width={screens.md ? "24" : "16"} />
        </div>
      ),
      value: "Make a Difference while you Earn",
    },
  ];

  return (
    <div className="lg:flex -z-20">
      <div
        style={{
          background: "linear-gradient(0deg, #683A27 28.62%, #000000 100%)",
        }}
        className={clsx(
          !showFields ? "block w-full" : "hidden lg:block",
          "relative flex justify-between flex-col min-h-screen text-white  flex-1 z-0"
        )}
      >
        <div className="h-full">
          <div className="flex justify-start text-start w-full px-5 md:px-10 pt-8">
            <img src={LogoWhite} alt="Logo" className="flex h-3.5 w-fit" />
          </div>
          <div className="px-5 md:px-10 pt-10 z-10 flex flex-col gap-y-2  md:gap-y-4">
            <h3 className="text-primary text:sm md:text-2xl">
              Become an Affiliate With Zebralearn
            </h3>
            <h2 className="text-2xl md:text-5xl font-semibold w-2/3">
              India’s First Books Platform
            </h2>

            <ul className="list-disc flex flex-col gap-y-2 md:gap-y-4 text-xl pt-2 md:w-2/3">
              {commissionBenefits.map((benefit, index) => (
                <span className="flex gap-x-3 items-center" key={index}>
                  <span>{benefit?.icon}</span>
                  <span className="text-xs md:text-xl">{benefit?.value}</span>
                </span>
              ))}
            </ul>
            <ShouldRender check={screens.md && !screens.lg}>
              <div className="w-fit mt-4 z-40">
                <ButtonDefault
                  size={4}
                  variant={ButtonVariants.WHITE}
                  className="p-1 w-full"
                  onClick={() => setShowFields(!showFields)}
                >
                  <span className="flex text-sm font-bold gap-x-2 px-4 py-1 whitespace-nowrap">
                    Become an Affiliate
                  </span>
                </ButtonDefault>
              </div>
            </ShouldRender>
          </div>
        </div>

        {/* <div className="absolute  right-0 -z-10 h-1/2 md:h-4/6 xl:h-4/5"> */}
        <img
          className="h-1/2  md:h-4/6 xl:h-4/5 absolute  right-0 bottom-0 -z-10  object-contain"
          src={loginGirl}
          alt=""
        />
        {/* </div> */}

        <ShouldRender check={!screens.md}>
          <Affix offsetBottom={10}>
            <div className="h-full flex justify-center pb-5 px-12">
              <ButtonDefault
                size={4}
                roundedSm={true}
                variant={ButtonVariants.WHITE}
                className="p-1  font-bold  w-full "
                onClick={() => setShowFields(!showFields)}
              >
                <div className="flex text-sm font-bold gap-x-2 px-4  whitespace-nowrap">
                  Become an Affiliate
                </div>
              </ButtonDefault>
            </div>
          </Affix>
        </ShouldRender>
      </div>
      <div
        className={clsx(
          showFields ? "block" : "hidden lg:block",
          "z-50 w-full lg:w-5/12 bg-readerLightGrayBg"
        )}
      >
        {children}
      </div>
    </div>
  );
};
export default AuthLayout;
