import React, { useEffect, useState } from "react";

import { Button, Empty, Select, Table } from "antd";

// import { useNavigate, useParams } from "react-router-dom";

import { getAllShortBookListApi } from "../../services/iframe";
import { Endpoints } from "../../network";
import { notify } from "../shared/basic/notify";
import { getAllUsers } from "../../services/Admin/dashboard";
import extractErrorMessage from "../shared/basic/formateError";
import { CopyOutlined } from "@ant-design/icons";

export const generateUrlForImage = (
  baseUrl: string,
  endpoint: string,
  id: string
): string => {
  const encodedId = encodeURIComponent(id);
  const url = `${baseUrl}${endpoint}${encodedId}`;
  return url;
};

const IFrame: React.FC = () => {
  const [shortBookLoading, setShortBookLoading] = useState(false);
  const [userDataLoading, setUserDataLoading] = useState(false);
  const [selectedAffiliateCodes, setSelectedAffiliateCodes] = useState<
    {
      product_id: string;
      affiliateCode: string;
    }[]
  >([]);

  const [userData, setUserData] = useState<
    {
      label: string;
      value: string;
    }[]
  >();

  const [shortBooks, setShortBooks] = useState<
    {
      _id: string;
      title: string;
      externalLink: string;
      slug: string;
      type: string;
      totalPages: number;
      product_id: string;
    }[]
  >([]);
  const zebraLearnBaseUrl = process.env.REACT_APP_ZEBRALEARN_BASE_URL_IFRAME;

  const handleSelectChange = ({
    value,
    record,
  }: {
    value: string;
    record: any;
  }) => {
    setSelectedAffiliateCodes((prevSelectedAffiliateCodes: any) => {
      const index = prevSelectedAffiliateCodes.findIndex(
        (item: any) => item.product_id === record.product_id
      );

      if (index > -1) {
        const updatedSelectedAffiliateCodes = [...prevSelectedAffiliateCodes];
        updatedSelectedAffiliateCodes[index].affiliateCode = value;
        return updatedSelectedAffiliateCodes;
      } else {
        return [
          ...prevSelectedAffiliateCodes,
          { product_id: record.product_id, affiliateCode: value },
        ];
      }
    });
  };

  const handleCopyLink = (url: string) => {
    navigator.clipboard
      .writeText(
        ` <iframe
      src='${url}'
      allow="fullscreen;"
      width="100%"
      height="600px"
      title="PDF Reader"
      loading="lazy"
      sandbox="allow-same-origin allow-scripts allow-popups"
    ></iframe>`
      )
      .then(() => {
        notify("Iframe Link copied to clipboard successfully!!", "success");
      })
      .catch((error) => {
        console.error("Error copying link to clipboard: ", error);
      });
  };

  const handleUserList = async () => {
    try {
      setUserDataLoading(true);
      const response = await getAllUsers({
        page: 1,
        perPage: 1000,
      });

      setUserData(
        response?.data?.map((user: any) => {
          return {
            label: user?.user?.name,
            value: user?.code,
          };
        })
      );
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setUserDataLoading(false);
    }
  };

  const columns = [
    {
      title: (
        <span className="text-xs flex py-2 justify-start md:pl-10">
          Product ID
        </span>
      ),
      dataIndex: "product_id",

      key: "product_id",
      render: (text: string) => {
        return (
          <div className="flex font-medium md:pl-10 px-10 justify-start text-center">
            {text}
          </div>
        );
      },
    },
    {
      title: <span className="text-xs flex py-2 justify-start ">Title</span>,
      dataIndex: "title",
      key: "title",
      render: (text: string) => {
        return (
          <div className="flex font-medium justify-start text-start">
            {text}
          </div>
        );
      },
    },
    {
      title: (
        <span className="text-xs flex py-2 justify-start md:pl-10">
          Affiliate User
        </span>
      ),
      width: 200,
      key: "product_id",
      render: (record: any) => {
        return (
          <div className="flex justify-center w-full items-center lg:">
            <Select
              placeholder="Select Affiliate User"
              loading={userDataLoading}
              disabled={userDataLoading}
              onChange={(value) => handleSelectChange({ value, record })}
              style={{ width: "100%" }}
              options={userData}
            />
          </div>
        );
      },
    },
    {
      title: (
        <span className="text-xs flex gap-x-2 items-center justify-center text-center">
          IFrams <CopyOutlined className="w-4 h-4" />
        </span>
      ),

      key: "order_date_column",
      render: (record: any) => {
        const selectedAffiliateCode = selectedAffiliateCodes.find(
          (item) => item.product_id === record.product_id
        );
        const secretCode = btoa(
          process.env.REACT_APP_IFRAME_SECRET_KEY as string
        );
        const isProductSelected = !!selectedAffiliateCode;

        return (
          <div className="flex gap-x-2 font-medium justify-center">
            <Button
              type="primary"
              ghost
              disabled={!isProductSelected}
              onClick={() => {
                if (selectedAffiliateCode) {
                  handleCopyLink(
                    `${zebraLearnBaseUrl}/pdfReader/${selectedAffiliateCode.product_id}/${secretCode}?code=${selectedAffiliateCode.affiliateCode}`
                  );
                }
              }}
              size="small"
            >
              No Auth
            </Button>
            
            <Button
              type="primary"
              ghost
              disabled={!isProductSelected}
              onClick={() => {
                if (selectedAffiliateCode) {
                  handleCopyLink(
                    `${zebraLearnBaseUrl}/pdfReader/${selectedAffiliateCode.product_id}/?code=${selectedAffiliateCode.affiliateCode}&email=`
                  );
                }
              }}
              size="small"
            >
              Email Iframe
            </Button>
          </div>
        );
      },
    },
  ];

  const handleGetAllShortBooks = async (title?: string) => {
    try {
      setShortBookLoading(true);
      const response = await getAllShortBookListApi(title);
      const { data } = response;

      const formattedBooks = data
        ?.map((book: any) => ({
          _id: book?._id,
          coverImageUrl: generateUrlForImage(
            `${process.env.NEXT_PUBLIC_API_READER_URL}/`,
            `${Endpoints.getCoverImagesForBooks}/`,
            `${book?._id}`
          ),
          title: book?.title,
          slug: book?.slug,
          product_id: book?.productId,
          type: book?.type,
          totalPages: book?.totalPages,
          key: book?._id,
        }))
        .filter((book: any) => book.type === "short");

      setShortBooks(formattedBooks);
    } catch (error: any) {
      notify(error, "error");
    } finally {
      setShortBookLoading(false);
    }
  };

  useEffect(() => {
    handleGetAllShortBooks();
    handleUserList();
  }, []);

  const rowClassName = (_: any, index: number) => {
    return index % 2 === 0 ? "bg-[#131313]" : "bg-bgColor";
  };

  return (
    <div className="flex flex-col gap-y-6 relative">
      <div className="customScrollBar ">
        <Table
          dataSource={shortBooks}
          loading={shortBookLoading}
          className={` customTable overflow-auto customScrollBar`}
          columns={columns}
          rowKey="key"
          pagination={false}
          rowClassName={rowClassName}
          locale={{
            emptyText: (
              <span>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>No Record</span>}
                  className={`h-40 flex justify-center items-center flex-col`}
                />
              </span>
            ),
          }}
        />
      </div>
    </div>
  );
};

export default IFrame;
