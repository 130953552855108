import { Input, Progress } from "antd";
import { AppwriteException } from "appwrite";
import React, { useState } from "react";

import AuthLayout from "../AuthLayout";
import ButtonDefault, { ButtonVariants } from "../../shared/basic/button";
import { ShouldRender } from "../../shared/basic/ShouldRender";

import appwriteService from "../../../config/appwrite";
import { useNavigate, useSearchParams } from "react-router-dom";
import { notify } from "../../shared/basic/notify";
import PasswordIcon from "../../../assets/Icons/password";
import LockCheckIcon from "../../../assets/Icons/LockCheck";
import { useRecoilState } from "recoil";
import { handleShowFields } from "../../../atoms/showFields";
import { ChevronLeftIcon } from "@heroicons/react/outline";

interface Props {
  isModal?: boolean;
  onCancel?: () => void;
  visible?: boolean;
}

const ResetPassword: React.FC<Props> = () => {
  const [forgotPasswordStep, setForgotPasswordStep] = useState(2);
  const [countdown, setCountdown] = useState(5);
  const [showFields, setShowFields] = useRecoilState(handleShowFields);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState({
    reEnterPassword: "",
    password: "",
  });
  const [passwordErrors, setPasswordErrors] = useState({
    reEnterPassword: "",
    password: "",
  });

  const [passwordStrength, setPasswordStrength] = useState<number>(0);
  const [searchParams] = useSearchParams();
  const secret = searchParams.get("secret");
  const userId = searchParams.get("userId");
  const router = useNavigate();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setPassword({
      ...password,
      [name]: value,
    });
    if (name === "password") {
      const strength = calculatePasswordStrength(value);
      setPasswordStrength(strength);
    }
  };

  const calculatePasswordStrength = (password: string) => {
    let strength = 0;

    // Check password length
    if (password.length >= 8) {
      strength += 1;
    }

    // Check for uppercase letters
    if (/[A-Z]/.test(password)) {
      strength += 1;
    }

    // Check for lowercase letters
    if (/[a-z]/.test(password)) {
      strength += 1;
    }

    // Check for at least one digit
    if (/\d/.test(password)) {
      strength += 1;
    }

    // Check for special characters
    if (/[\W_]/.test(password)) {
      strength += 1;
    }

    return strength;
  };

  const getStrengthText = () => {
    switch (passwordStrength) {
      case 1:
        return "Too Weak";
      case 2:
        return "Weak";
      case 3:
        return "Moderate";
      case 4:
        return "Strong";
      case 5:
        return "Very Strong";
      default:
        return "";
    }
  };

  const submitPassword = async (e: React.FormEvent) => {
    e.preventDefault();

    const validationErrors = {
      reEnterPassword: "",
      password: "",
    };
    if (!password.password) {
      validationErrors.password = "Password is required";
    }
    if (!password.reEnterPassword) {
      validationErrors.reEnterPassword = "Re-Enter Password is requires";
    } else if (password.password !== password.reEnterPassword) {
      validationErrors.reEnterPassword =
        "Password didn`t matched! Please try again";
    }

    setPasswordErrors(validationErrors);

    if (Object.values(validationErrors).every((error) => !error)) {
      try {
        setLoading(true);
        await appwriteService.passwordRecoveryConfirmation({
          password: password.password,
          secret_code: secret as string,
          userId: userId as string,
        });

        setForgotPasswordStep(3);
        startCountdown();
      } catch (error: unknown) {
        if (error instanceof AppwriteException) {
          notify(error.message, "error");
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const startCountdown = () => {
    const intervalId: NodeJS.Timeout = setInterval(() => {
      setCountdown((prevCount: number) => {
        if (prevCount === 1) {
          clearInterval(intervalId);

          router("/");
        }
        return prevCount - 1;
      });
    }, 1000);
  };

  return (
    <AuthLayout setShowFields={setShowFields} showFields={showFields}>
      <div className="flex flex-1 min-h-screen   flex-col justify-center px-4  sm:px-6 lg:flex-none lg:px-12">
        <div className="mx-auto w-full  ">
          <div className="flex items-center gap-y-10 flex-col justify-center md:px-5 xl:px-10">
            <div className={` z-30  flex flex-col gap-y-1 w-full `}>
              <div className=" py-3" />

              <ShouldRender check={forgotPasswordStep === 2}>
                <div
                  onClick={() => setShowFields(!showFields)}
                  className="flex group items-center text-textGrayColor gap-x-2 cursor-pointer hover:underline"
                >
                  <ChevronLeftIcon className=" w-4 h-4" />{" "}
                  <p className="group-hover:underline">Back</p>
                </div>
                <h1 className="text-xl text-fadedWhite flex gap-x-2 justify-center font-medium lg:font-normal lg:text-3xl mt-1 leading-10  text-center w-full">
                  Reset your
                  <b className="text-primary font-medium">password</b>
                </h1>
                <div className="flex justify-center mt-2">
                  <span className="text-textGray text-center text-lg w-[80%]">
                    Use at least 10 characters: uppercase, lowercase, and
                    numbers.
                  </span>
                </div>

                <form
                  onSubmit={submitPassword}
                  className="flex flex-col  gap-2 mt-5 px-5"
                >
                  <div className="col-span-1 flex-col flex gap-y-.5">
                    <Input.Password
                      name="password"
                      value={password.password}
                      placeholder="Password"
                      style={{
                        backgroundColor: "#080808",
                      }}
                      prefix={
                        <span className="pr-2">
                          <PasswordIcon />
                        </span>
                      }
                      onChange={handleChange}
                      size="large"
                      className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
                    />
                    {passwordErrors.password && (
                      <p className="text-red-500">{passwordErrors.password}</p>
                    )}
                    <ShouldRender check={password.password}>
                      {passwordStrength !== null && (
                        <span className="relative pb-2">
                          <Progress
                            percent={(passwordStrength / 5) * 100}
                            showInfo={false}
                            status={
                              passwordStrength >= 3 ? "success" : "exception"
                            }
                          />
                          <p
                            className={`mt-2 text-${
                              passwordStrength >= 3 ? "green" : "red"
                            }-500 absolute text-sm -bottom-1`}
                          >
                            {getStrengthText()}
                          </p>
                        </span>
                      )}
                    </ShouldRender>
                  </div>
                  <div className="col-span-1 flex-col flex gap-y-.5">
                    <Input.Password
                      name="reEnterPassword"
                      placeholder="Re-enter Password"
                      value={password.reEnterPassword}
                      prefix={
                        <span className="pr-2">
                          <LockCheckIcon />
                        </span>
                      }
                      style={{
                        backgroundColor: "#080808",
                      }}
                      onChange={handleChange}
                      size="large"
                      className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
                    />
                    {passwordErrors.reEnterPassword && (
                      <p className="text-red-500">
                        {passwordErrors.reEnterPassword}
                      </p>
                    )}
                  </div>

                  <span className="flex justify-center mt-6">
                    <ButtonDefault
                      size={4}
                      variant={ButtonVariants.WHITE}
                      className="p-1 w-full"
                      disabled={loading}
                      loading={loading}
                      rounded={true}
                    >
                      <span className="flex text-sm font-bold gap-x-2 px-4 py-1 whitespace-nowrap">
                        Reset Password
                      </span>
                    </ButtonDefault>
                  </span>
                </form>
              </ShouldRender>
              <ShouldRender check={forgotPasswordStep === 3}>
                <div className="flex flex-col gap-3">
                  <span className="text-center flex justify-center text-[80px]">
                    &#128516;
                  </span>
                  <div className="flex justify-center">
                    <span className="text-white px-10 text-center">
                      {` Your password has been reset successfully. You'll be
                      redirected to`}
                      <span className="inline-flex items-baseline">
                        <span
                          className="px-1 underline font-semibold text-blue-500"
                          onClick={() => router("/auth/login")}
                        >
                          Login Page{" "}
                        </span>{" "}
                        shortly. Happy exploring!
                      </span>
                    </span>
                  </div>

                  <p className="underline text-blue-500 text-center">
                    Redirecting in {countdown} seconds...
                  </p>
                </div>
              </ShouldRender>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};
export default ResetPassword;
