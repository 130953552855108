import React, { useEffect, useState } from "react";
import { Input } from "antd";

import { notify } from "../../shared/basic/notify";
import { useRecoilValue } from "recoil";
import { UserData } from "../../../utils/interfaces/user";
import { user } from "../../../atoms/toggleTheme/user";
import { formatErrorObject, getAdminRole } from "../../../utils/lib/auth";
import ButtonDefault, { ButtonVariants } from "../../shared/basic/button";
import {
  getUserById,
  updateGetUserById,
  updateProfile,
} from "../../../services/user";
import Step1 from "../../DashboardComp/Dashboard/OnboardingModal/Step1";

import { useParams } from "react-router-dom";
import { ShouldRender } from "../../shared/basic/ShouldRender";

const Profile: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [isFormChangedAdmin, setIsFormChangedAdmin] = useState(false);
  const isAdmin = getAdminRole();

  const userData = useRecoilValue<UserData | undefined>(user);
  const [getByIdUserId, setGetByUserId] = useState<{
    userLife: number;
    channels: string[];
    newUserCommissionPercentage: number;
    commissionPercentage: number;
  }>();

  const [formData, setFormData] = useState({
    fullname: userData?.name || "",
    phone: userData?.phone || "",
  });

  const [errors, setErrors] = useState({
    fullname: "",
    phone: "",
  });

  const [formDataAdmin, setFormDataAdmin] = useState({
    commission_percentage: "",
    user_life: "",
    first_order_commission: "",
  });

  const [errorsAdmin, setErrorsAdmin] = useState({
    commission_percentage: "",
    user_life: "",
    first_order_commission: "",
  });

  const { id } = useParams();

  useEffect(() => {
    if (!id) {
      setFormData({
        fullname: userData?.name || "",
        phone: userData?.phone || "",
      });
    }
  }, [userData, id]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setIsFormChanged(true);

    // Check for empty fields
    if (name === "fullname" && !value.trim()) {
      setErrors({
        ...errors,
        fullname: "Name is required",
      });
    } else {
      setErrors({
        ...errors,
        fullname: "",
      });
    }
  };

  const handleChangeAdmin = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    const inputValue = value;
    const cleanedValue = inputValue.replace(/\D/g, "");

    setFormDataAdmin({
      ...formDataAdmin,
      [name]: cleanedValue,
    });
    setIsFormChangedAdmin(true);
  };

  const handleUpdate = async (data: {
    name?: string;
    email?: string;
    phone?: string;
    password?: string;
  }) => {
    try {
      setLoading(true);
      await updateProfile(data);
      notify(
        `${data?.password ? "Password" : "Profile"} updated successfully!!`,
        "success"
      );
      setIsFormChanged(false);
    } catch (error: any) {
      const message = formatErrorObject(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handlePhoneInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;

    const inputValue = value;
    const cleanedValue = inputValue.replace(/\D/g, "");
    setFormData({ ...formData, phone: cleanedValue });
    setIsFormChanged(true);
    if (!cleanedValue.trim()) {
      setErrors({
        ...errors,
        phone: "Phone number is required",
      });
    } else if (!isValidPhone(cleanedValue)) {
      setErrors({
        ...errors,
        phone: "Enter Valid 10 digit number",
      });
    } else {
      setErrors({
        ...errors,
        phone: "",
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    // Validation
    const validationErrors = {
      fullname: "",
      email: "",
      phone: "",
    };

    if (!formData.fullname) {
      validationErrors.fullname = "Full name is required";
    }

    if (!formData.phone) {
      validationErrors.phone = "Phone number is required";
    } else if (!isValidPhone(formData.phone)) {
      validationErrors.phone = "Enter Valid 10 digit number";
    }

    setErrors(validationErrors);

    if (Object.values(validationErrors).every((error) => !error)) {
      try {
        handleUpdate({
          name: formData.fullname,
          // email: formData.email,
          phone: formData.phone,
        });
      } catch (error) {
        console.error("Error creating/updating address:", error);
      }
    }
  };

  const handleSubmitAdmin = async (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    // Validation
    const validationErrors = {
      commission_percentage: "",
      user_life: "",
      first_order_commission: "",
    };

    if (
      formDataAdmin?.commission_percentage &&
      +formDataAdmin?.commission_percentage > 100
    ) {
      validationErrors.commission_percentage =
        "Commission Percentage cant be more then 100%";
    }
    if (
      formDataAdmin?.first_order_commission &&
      +formDataAdmin?.first_order_commission > 100
    ) {
      validationErrors.first_order_commission =
        "Commission Percentage cant be more then 100%";
    }

    setErrorsAdmin(validationErrors);

    if (Object.values(validationErrors).every((error) => !error)) {
      try {
        await updateGetUserById(
          {
            commission_percentage: +formDataAdmin?.commission_percentage,
            user_life: +formDataAdmin?.user_life,
            first_order_commission: +formDataAdmin?.first_order_commission,
          },
          id as string
        );
        notify("User Data updated successfully! ", "success");
        setIsFormChangedAdmin(false);
      } catch (error) {
        console.error("Error creating/updating address:", error);
      }
    }
  };

  const isValidPhone = (value: string) => {
    const phoneRegex = /^\d{10}$/;

    return phoneRegex.test(value);
  };

  const getUserDetailsById = async (userId: string) => {
    try {
      setLoading(true);
      const data = await getUserById(userId);
      setFormData({
        fullname: data?.user?.name,
        phone: data?.user?.phone,
      });

      setFormDataAdmin({
        first_order_commission: data?.first_order_commission,
        commission_percentage: data?.commission_percentage,
        user_life: data?.user_life,
      });
      setGetByUserId({
        newUserCommissionPercentage: data?.first_order_commission,
        commissionPercentage: data?.commission_percentage,
        userLife: data?.userLife,
        channels: data?.channels.map((product: any) => {
          return product?.profile_link;
        }),
      });
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isAdmin === "true") {
      getUserDetailsById(id as string);
    }
  }, [isAdmin, id]);

  return (
    <div>
      <div className="border-[#444444] border px-4 md:px-8 py-6 rounded-md flex flex-col gap-y-8 ">
        <div className="flex flex-col gap-2">
          <div className="flex flex-col xs:flex-row gap-4">
            <div className="col-span-1 flex-col flex gap-y-.5">
              <label className="text-sm  md:text-base text-fadedWhite pb-2 l flex gap-x-2">
                Name
              </label>
              <Input
                name="fullname"
                value={formData.fullname}
                disabled={!!id}
                // disabled={!editClick}
                onChange={handleChange}
                size="large"
                placeholder="Name"
                className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-md border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
              />
              {errors.fullname && (
                <p className="text-red-500">{errors.fullname}</p>
              )}
            </div>

            <div className="col-span-1 flex-col flex gap-y-.5">
              <label className="text-sm  md:text-base text-fadedWhite pb-2">
                Phone
              </label>
              <div className="relative">
                <Input
                  name="phone"
                  value={formData.phone}
                  disabled={!!id}
                  // disabled={!editClick}
                  onChange={handlePhoneInput}
                  placeholder="Phone Number"
                  size="large"
                  className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-md border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
                  pattern="[0-9]*"
                  maxLength={10}
                />
              </div>
              {errors.phone && <p className="text-red-500">{errors.phone}</p>}
            </div>
          </div>
          <ShouldRender check={!id}>
            <div className="col-span-1 mt-4 flex-col flex gap-y-.5">
              <ButtonDefault
                size={1}
                variant={ButtonVariants.UNSTYLED}
                className="w-full md:w-min p-1 disabled:cursor-not-allowed rounded bg-fadedWhite hover:bg-gray-100 text-black disabled:bg-dropDownMenuBg disabled:text-[#3E3E3E] disabled:border-0"
                loading={loading}
                disabled={
                  !isFormChanged ||
                  loading ||
                  Object.values(errors).some((error) => !!error)
                }
                onClick={handleSubmit}
              >
                <span className="flex text-base  gap-x-2 px-4 font-medium whitespace-nowrap  ">
                  Save changes
                </span>
              </ButtonDefault>
            </div>
          </ShouldRender>
        </div>
        <ShouldRender check={id}>
          <div className="flex flex-col gap-2">
            <div className="grid xs:grid-cols-2 lg:grid-cols-3 gap-4 lg:w-2/3 text-fadedWhite">
              <div className="col-span-1 flex-col flex gap-y-.5">
                <label className="text-sm  md:text-base text-fadedWhite pb-2 whitespace-nowrap l flex gap-x-2">
                  User Life (in years)
                </label>
                <Input
                  name="user_life"
                  value={formDataAdmin?.user_life}
                  onChange={handleChangeAdmin}
                  size="large"
                  placeholder="User Life"
                  className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-md border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
                />
                {errorsAdmin.user_life && (
                  <p className="text-red-500">{errorsAdmin.user_life}</p>
                )}
              </div>

              <div className="col-span-1 flex-col flex gap-y-.5">
                <label className="text-sm  md:text-base  whitespace-nowrap text-fadedWhite pb-2">
                  New user commission %
                </label>
                <div className="relative">
                  <Input
                    name="first_order_commission"
                    value={formDataAdmin?.first_order_commission}
                    // disabled={!editClick}
                    onChange={handleChangeAdmin}
                    placeholder="New user commission %"
                    size="large"
                    className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-md border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
                  />
                </div>
                {errorsAdmin.first_order_commission && (
                  <p className="text-red-500">
                    {errorsAdmin.first_order_commission}
                  </p>
                )}
              </div>
              <div className="col-span-1 flex-col flex gap-y-.5">
                <label className="text-sm  md:text-base  whitespace-nowrap text-fadedWhite pb-2">
                  Repeat order commission %
                </label>
                <div className="relative">
                  <Input
                    name="commission_percentage"
                    value={formDataAdmin?.commission_percentage}
                    // disabled={!editClick}
                    onChange={handleChangeAdmin}
                    placeholder="Repeat order commission %"
                    size="large"
                    className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-md border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
                  />
                </div>
                {errorsAdmin.commission_percentage && (
                  <p className="text-red-500">
                    {errorsAdmin.commission_percentage}
                  </p>
                )}
              </div>
            </div>

            <div className="col-span-1 mt-4 flex-col flex gap-y-.5">
              <ButtonDefault
                size={1}
                variant={ButtonVariants.UNSTYLED}
                className="w-full md:w-min p-1 disabled:cursor-not-allowed rounded bg-fadedWhite hover:bg-gray-100 text-black disabled:bg-dropDownMenuBg disabled:text-[#3E3E3E] disabled:border-0"
                loading={loading}
                disabled={
                  !isFormChangedAdmin ||
                  loading ||
                  Object.values(errorsAdmin).some((error) => !!error)
                }
                onClick={handleSubmitAdmin}
              >
                <span className="flex text-base  gap-x-2 px-4 font-medium whitespace-nowrap  ">
                  Save changes
                </span>
              </ButtonDefault>
            </div>
          </div>
        </ShouldRender>

        <div className="flex flex-col gap-y-4">
          <span>
            <ShouldRender
              check={(!id && getByIdUserId?.channels.length) || !id}
            >
              <span className="text-xl font-medium text-white">
                Platform you’re promoting in
              </span>
            </ShouldRender>

            <ShouldRender check={!id}>
              <span>
                <p className="text-[#BABABA]">
                  Select your platform and add URL
                </p>
              </span>
            </ShouldRender>
          </span>
          <ShouldRender check={id}>
            {getByIdUserId?.channels.map((item, index) => {
              return (
                <span key={item}>
                  {index + 1}. {item}
                </span>
              );
            })}
          </ShouldRender>
          <ShouldRender check={!id}>
            <div className="flex flex-col md:w-1/2 xl:w-1/3">
              <Step1 isModal={true} />
            </div>
          </ShouldRender>
        </div>
      </div>
    </div>
  );
};
export default Profile;
