import { Endpoints } from "../network";
import apiClient from "./base";

export const loginUser = async (
  email: string,
  password: string
): Promise<any> => {
  const response = await apiClient.post(Endpoints.login, {
    email,
    password,
  });

  return response;
};

export const changePassword = async (
  old_password: string,
  new_password: string
): Promise<any> => {
  const response = await apiClient.post(Endpoints.changePassword, {
    old_password,
    new_password,
  });

  return response?.data;
};

export const getUser = async () => {
  const response = await apiClient.get(Endpoints.users);

  return response;
};

export const adminUsers = async (id: number) => {
  const response = await apiClient.get(`Endpoints.adminUsers/${id}`);

  return response;
};

export const getUserById = async (id: string) => {
  const response = await apiClient.get(`${Endpoints.getUserById}/${id}`);

  return response?.data?.data;
};

export const updateGetUserById = async (
  data: {
    commission_percentage: number;
    user_life: number;
    first_order_commission: number;
  },
  id: string
) => {
  const response = await apiClient.patch(`${Endpoints.getUserById}/${id}`, {
    ...data,
  });

  return response?.data?.data;
};

export const updateProfile = async (data: {
  name?: string;
  email?: string;
  phone?: string;
  password?: string;
}) => {
  const response = await apiClient.post(`${Endpoints.updateProfile}`, data);

  return response?.data;
};
