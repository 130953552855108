import React, { useEffect, useState } from "react";
import {
  PlusCircleOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Spin, Typography } from "antd";
import {
  addAffiliateChannels,
  deleteAffiliateChannels,
  getAffiliateChannels,
  updateAffiliateChannels,
} from "../../../../../services/profileSettings";
import extractErrorMessage from "../../../../shared/basic/formateError";
import { notify } from "../../../../shared/basic/notify";
import { ShouldRender } from "../../../../shared/basic/ShouldRender";
import clsx from "clsx";

const { Text } = Typography;

export interface UrlItem {
  id: number;
  url: string;
  new_channel?: boolean;
}

interface Step1Props {
  isModal?: boolean;
}

const Step1: React.FC<Step1Props> = ({ isModal = false }) => {
  const [urls, setUrls] = useState<UrlItem[]>([
    {
      id: new Date().getTime(),
      url: "",
      new_channel: true,
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleAddUrl = () => {
    setUrls((prevUrl) => [
      ...prevUrl,
      {
        id: new Date().getTime(),
        url: "",
        new_channel: true,
      },
    ]);
  };

  const handleRemoveUrl = ({ id, new_channel }: UrlItem) => {
    if (!new_channel) {
      handleDeleteAffiliateChannels(id);
    }
    setUrls(urls.filter((url) => url.id !== id));
  };

  const handleUrlChange = (id: number, value: string) => {
    const updatedUrls = urls.map((url) => {
      if (url.id === id) {
        return { ...url, url: value };
      }
      return url;
    });
    setUrls(updatedUrls);
  };
  const handleSaveUrl = async ({ id, url, new_channel }: UrlItem) => {
    if (new_channel) {
      handleAddAffiliateChannels(url);
    } else {
      handleUpdateAffiliateChannels(url, id);
    }
  };

  const fetchAffiliateChannels = async () => {
    try {
      setLoading(true);
      const response = await getAffiliateChannels();
      setUrls(
        response?.map((channel: any) => {
          return {
            id: channel?.id,
            url: channel?.profile_link,
          };
        })
      );
      // response?.map((channel: any) => {
      //   form.setFieldsValue({ 'url': channel?.id });
      // });
    } catch (error: any) {
      const message = extractErrorMessage(error);
      // notify(message, "error");
      console.log(message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddAffiliateChannels = async (url: string) => {
    try {
      setLoading(true);
      await addAffiliateChannels(url);

      await fetchAffiliateChannels();
      notify("Platform added successfully!", "success");
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
      console.log(message);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAffiliateChannels = async (id: number) => {
    try {
      setLoading(true);
      await deleteAffiliateChannels(id);

      await fetchAffiliateChannels();
      notify("Platform Deleted successfully!", "success");
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
      console.log(message);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateAffiliateChannels = async (url: string, id: number) => {
    try {
      setLoading(true);
      await updateAffiliateChannels(url, id);
      await fetchAffiliateChannels();
      notify("Platform updated successfully!", "success");
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
      console.log(message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAffiliateChannels();
  }, []);
  return (
    <div className="flex flex-col">
      <ShouldRender check={!loading}>
        <Form form={form}>
          {urls.map((url) => (
            <Form.Item
              key={url.id}
              name={`${url.id}`}
              valuePropName={`${url.id}`}
              // rules={[
              //   {
              //     type: "url",
              //     message: "Please enter a valid URL",
              //   },
              // ]}
            >
              <Input
                value={url.url}
                onChange={(e) => handleUrlChange(url.id, e.target.value)}
                suffix={
                  <>
                    <Text>
                      <CloseOutlined
                        style={{ color: "red" }}
                        onClick={() => handleRemoveUrl(url)}
                      />
                    </Text>
                    <Text>
                      {url.url && (
                        <CheckOutlined
                          style={{ color: "green" }}
                          onClick={() => handleSaveUrl(url)}
                        />
                      )}
                    </Text>
                  </>
                }
                placeholder="Enter URL (https://www.example.com)"
                className={clsx(
                  isModal ? "bg-bgcolor" : "bg-[#303030]",
                  `border text-xs w-full focus-within:bg-bgColor  placeholder:text-[#7C7C7C] rounded-md border-bgDarkGray  py-2  text-white  customInputBlack makeBgBlack`
                )}
              />
            </Form.Item>
          ))}
          <ShouldRender check={!urls?.length}>
            <Form.Item>
              <Button
                type="dashed"
                onClick={handleAddUrl}
                block
                icon={<PlusCircleOutlined />}
              >
                Add URL
              </Button>
            </Form.Item>
          </ShouldRender>
          <ShouldRender check={urls?.length}>
            <div className="flex justify-end">
              <Form.Item>
                <Button className="text-xs  " onClick={handleAddUrl} block>
                  <span className="px-4">Add More</span>
                </Button>
              </Form.Item>
            </div>
          </ShouldRender>
        </Form>
      </ShouldRender>
      <ShouldRender check={loading}>
        <span className="py-10 flex justify-center items-center">
          {" "}
          <Spin size="large" />
        </span>
      </ShouldRender>
    </div>
  );
};
export default Step1;
