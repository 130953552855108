import React, { useEffect, useRef, useState } from "react";
import WithdrawSection from "./WithdrawSection";
import RegisterSection from "./RegisterSection";
import ActiveUserSection from "./ActiveUserSection";
import EarningDataTable from "./EarningDataTable";
import SummarySection from "./SummarySection";
import ProductListSection from "./ProductListSection";

import TotalWithdrawAmount from "./TotalWithdrawAmount";
import { useRecoilValue } from "recoil";
import { dateFilter } from "../../../atoms/dateFilter";
import {
  getAdminUserRegistration,
  getUserRegistration,
} from "../../../services/dashboard";
import extractErrorMessage from "../../shared/basic/formateError";
import { notify } from "../../shared/basic/notify";
import { AuthBg } from "../../../assets";
import ButtonDefault, { ButtonVariants } from "../../shared/basic/button";
import { UserData } from "../../../utils/interfaces/user";
import { user } from "../../../atoms/toggleTheme/user";
import { ShouldRender } from "../../shared/basic/ShouldRender";
import OnboardingModal from "./OnboardingModal";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useParams } from "react-router-dom";

const DashboardComp = () => {
  const container1Ref = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [registration, setRegistration] = useState<{
    active_user: number;
    total_registration: number;
  }>();

  const [maxHeight, setMaxHeight] = useState<number>();
  const dateFilterValue = useRecoilValue(dateFilter);
  const userData = useRecoilValue<UserData | undefined>(user);
  const screens = useBreakpoint();
  const { id } = useParams();

  useEffect(() => {
    const container1Height = container1Ref?.current?.scrollHeight;

    setMaxHeight(container1Height);
  }, []);

  const handleUserRegistration = async () => {
    try {
      setLoading(true);
      let response;
      if (id) {
        response = await getAdminUserRegistration(
          +id,
          dateFilterValue?.start_date,
          dateFilterValue?.end_date
        );
      } else {
        response = await getUserRegistration(
          dateFilterValue?.start_date,
          dateFilterValue?.end_date
        );
      }

      setRegistration(response);
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleUserRegistration();
  }, [dateFilterValue]);

  const onCancelCloseModal = () => {
    setVisible(false);
  };

  return (
    <>
      <ShouldRender check={userData?.is_new_user && !id}>
        <div className="relative min-h-[250px] w-full rounded-xl overflow-hidden mb-6">
          <img
            className="absolute inset-0 h-full w-full object-cover filter blur-sm"
            src={AuthBg}
            alt=""
          />
          <div className="absolute inset-0 bg-[#131313] bg-opacity-60"></div>
          <div className="absolute inset-0 flex flex-col justify-center items-center p-8 text-center">
            <p className="text-2xl text-primary mb-4">Tell us about you</p>
            <p className="text-fadedWhite max-w-[500px]">
              To get a link, please describe your website or channel. This helps
              us ensure youll get good conversion.
            </p>
            <ButtonDefault
              size={2}
              variant={ButtonVariants.WHITE}
              className=" w-min mt-5"
              onClick={() => setVisible(true)}
            >
              <span className="flex text-sm font-semibold  text-black px-3 whitespace-nowrap">
                Get Started
              </span>
            </ButtonDefault>
          </div>
        </div>
      </ShouldRender>

      <div className="flex flex-col md:flex-row gap-5 w-full">
        {" "}
        <div
          style={screens.md ? { maxHeight: `${maxHeight}px` } : {}}
          className="md:w-1/2 xl:w-7/12 flex gap-y-5 flex-col "
        >
          <TotalWithdrawAmount />
          <WithdrawSection />

          <EarningDataTable maxHeight={maxHeight as number} />
        </div>
        <div
          className="md:w-1/2 xl:w-5/12 flex gap-y-5 flex-col h-fit "
          ref={container1Ref}
        >
          <div className="flex md:flex-col lg:flex-row gap-5">
            <span className="w-full">
              <RegisterSection
                registration={registration?.total_registration as number}
                loading={loading}
              />
            </span>
            <span className="w-full">
              <ActiveUserSection
                users={registration?.active_user as number}
                loading={loading}
              />
            </span>
          </div>
          <div>
            <SummarySection />
          </div>
          <ShouldRender check={!id}>
            <div>
              <ProductListSection />
            </div>
          </ShouldRender>
        </div>
      </div>
      <OnboardingModal closeModal={onCancelCloseModal} visible={visible} />
    </>
  );
};
export default DashboardComp;
