import { Endpoints } from "../../../network";
import apiClient from "../../base";

interface IProductList {
  filter?: string;
  page?: number;
  start_date?: string;
  end_date?: string;
  book_type?: string;
  name?: string;
  affiliate_user_id?: number;
}

export interface PaymentBody {
  order_ids: React.Key[];
  affiliate_user_id: number;
  amount: number;
  mode: string;
  payment_id: string;
  remark?: string;
  affiliate_settlement_request_id: string;
}

export const getAllUsers = async ({
  page,
  start_date,
  end_date,
  perPage,
}: {
  page: number;
  start_date?: string;
  end_date?: string;
  perPage?: number;
}) => {
  let url = `${Endpoints.getAllUsers}?page=${page}`;
  if (start_date && end_date) {
    url += `&start_date=${start_date}&end_date=${end_date}`;
  }

  if (perPage) {
    url += `&perPage=${perPage}`;
  }

  const response = await apiClient.get(url);

  return response?.data?.data;
};

export const getAdminProductSales = async (
  page: number,
  start_date?: string,
  end_date?: string
) => {
  let url = `${Endpoints.getAdminProductSales}?page=${page}`;
  if (start_date && end_date) {
    url += `&start_date=${start_date}&end_date=${end_date}`;
  }

  const response = await apiClient.get(url);

  return response?.data;
};

export const getAdminPaymentHistory = async (
  page: number,
  start_date?: string,
  end_date?: string
) => {
  let url = `${Endpoints.adminPaymentHistory}?page=${page}`;
  if (start_date && end_date) {
    url += `&start_date=${start_date}&end_date=${end_date}`;
  }
  const response = await apiClient.get(url);
  return response?.data?.data;
};

export const getPaymentHistory = async (
  page: number,
  start_date?: string,
  end_date?: string
) => {
  let url = `${Endpoints.paymentHistory}?page=${page}`;
  if (start_date && end_date) {
    url += `&start_date=${start_date}&end_date=${end_date}`;
  }
  const response = await apiClient.get(url);
  return response?.data?.data;
};

export const getAdminPaymentHistoryDetails = async (id: string) => {
  const response = await apiClient.get(
    `${Endpoints.adminPaymentHistoryDetails}?settlement_request_id=${id}`
  );

  return response?.data?.data;
};

export const getPaymentHistoryDetails = async (id: string) => {
  const response = await apiClient.get(
    `${Endpoints.paymentHistoryDetails}?settlement_request_id=${id}`
  );

  return response?.data?.data;
};
export const getAdminPaymentRequest = async (data: IProductList) => {
  const { filter, end_date, page = 0, start_date } = data;
  let url = `${Endpoints.getAdminPaymentRequest}?page=${page}`;
  if (start_date && end_date) {
    url += `&start_date=${start_date}&end_date=${end_date}`;
  }
  if (filter) {
    url += `&status=${filter}`;
  }

  const response = await apiClient.get(url);

  return response?.data?.data;
};

export const handleOrderToPayList = async (data: IProductList) => {
  const { end_date, start_date, affiliate_user_id } = data;
  let url = `${Endpoints.handleOrderToPayList}?settlement_request_id=${affiliate_user_id}`;
  if (start_date && end_date) {
    url += `&start_date=${start_date}&end_date=${end_date}`;
  }

  const response = await apiClient.get(url);

  return response?.data?.data;
};

export const handleStatusChange = async (id: number, status: string) => {
  const response = await apiClient.post(
    `${Endpoints.handleStatusChange}/${id}/change-status`,
    { status }
  );

  return response?.data?.data;
};

export const calculatePayment = async (
  order_ids: React.Key[],
  affiliate_user_id: number,
  affiliate_settlement_request_id: string
) => {
  const response = await apiClient.post(`${Endpoints.calculatePayment}`, {
    order_ids,
    affiliate_user_id,
    affiliate_settlement_request_id,
  });

  return response?.data?.data;
};

export const handlePayment = async (data: PaymentBody) => {
  const response = await apiClient.post(`${Endpoints.payment}`, {
    ...data,
  });

  return response?.data?.data;
};
