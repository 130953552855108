import React, { useEffect, useState } from "react";

import { useRecoilValue } from "recoil";

import { Empty, Popover, Table } from "antd";
import calculateDateDifference from "../../../shared/basic/daysDifference";
import { dateFilter } from "../../../../atoms/dateFilter";
import extractErrorMessage from "../../../shared/basic/formateError";
import { notify } from "../../../shared/basic/notify";
import { ShouldRender } from "../../../shared/basic/ShouldRender";
import {
  getAdminPaymentHistory,
  getPaymentHistory,
} from "../../../../services/Admin/dashboard";
import { IPaymentHistoryDataTable } from "../../../../utils/interfaces/Admin/dashboard";
import clsx from "clsx";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { getAdminRole } from "../../../../utils/lib/auth";
import { formatIndianCurrency } from "../../../shared/formatIndianCurrency";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

const AdminPaymentHistory: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IPaymentHistoryDataTable>();
  const [page, setPage] = useState<number>(1);
  const isAdmin = getAdminRole();
  const dateFilterValue = useRecoilValue(dateFilter);
  const router = useNavigate();
  const location = useLocation();
  const screens = useBreakpoint();

  const { months, remainingDays } = calculateDateDifference(
    dateFilterValue?.start_date,
    dateFilterValue?.end_date
  );

  const handleAdminPaymentHistory = async (page: number) => {
    try {
      setLoading(true);

      if (isAdmin === "true") {
        const response = await getAdminPaymentHistory(
          page,
          dateFilterValue?.start_date,
          dateFilterValue?.end_date
        );
        setData({
          current_page: response?.current_page,
          per_page: response?.per_page,
          total: response?.total,
          data: response?.data?.map((product: any) => {
            return {
              affiliates_name: product?.user?.name,
              paymentID: product?.id,
              key: product?.id,
              transactionID: product?.payment_id,
              paymentDate: product?.payment_date,
              paymentStatus: product?.status,
              totalAmount: product?.total_amount,
              totalTax: product?.tax_amount,
              paidAmount: product?.amount,
              settlement_request_id: product?.settlement_request_id,
              mode: product?.mode,
              remark: product?.remark,
            };
          }),
        });

        response?.meta?.current_page && setPage(response?.meta?.current_page);
      } else {
        const response = await getPaymentHistory(
          page,
          dateFilterValue?.start_date,
          dateFilterValue?.end_date
        );
        setData({
          current_page: response?.current_page,
          per_page: response?.per_page,
          total: response?.total,
          data: response?.data?.map((product: any) => {
            return {
              affiliates_name: product?.user?.name,
              paymentID: product?.id,
              transactionID: product?.payment_id,
              paymentDate: product?.payment_date,
              paymentStatus: product?.status,
              totalAmount: product?.total_amount,
              totalTax: product?.tax_amount,
              paidAmount: product?.amount,
              settlement_request_id: product?.settlement_request_id,
              mode: product?.mode,
              remark: product?.remark,
            };
          }),
        });

        response?.meta?.current_page && setPage(response?.meta?.current_page);
      }
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (page) {
      handleAdminPaymentHistory(page);
    }
  }, [page, dateFilterValue]);

  const columns = [
    {
      title: (
        <span className="text-xs flex py-2 justify-start md:pl-10">
          Associates
        </span>
      ),

      key: "affiliates_name",
      render: (text: any) => {
        return (
          <div
            onClick={() => {
              router(
                `/dashboard/paymentHistory/${text?.settlement_request_id}${location.search}`
              );
            }}
            className="flex underline cursor-pointer hover:text-primary font-medium justify-start text-center md:pl-10"
          >
            {text?.affiliates_name}
          </div>
        );
      },
    },
    {
      title: (
        <span className="text-xs flex py-2 justify-center">Payment ID</span>
      ),
      dataIndex: "paymentID",
      key: "paymentID",
      render: (text: string) => {
        return (
          <div className="flex font-medium px-5 justify-center items-center text-center">
            {text}
          </div>
        );
      },
    },
    {
      title: (
        <span className="text-xs flex py-2 justify-center">Transaction ID</span>
      ),
      dataIndex: "transactionID",
      key: "transactionID",
      render: (text: string) => {
        return (
          <div className="flex font-medium justify-center text-center">
            {text}
          </div>
        );
      },
    },
    {
      title: (
        <span className="text-xs flex justify-center text-center">
          Payment Date
        </span>
      ),
      dataIndex: "paymentDate",
      key: "paymentDate",
      render: (text: string) => {
        return (
          <div className="flex font-medium justify-center ">
            {moment.utc(text).format("DD/MM/YYYY")}
          </div>
        );
      },
    },
    {
      title: (
        <span className="text-xs flex justify-center">Payment Status</span>
      ),
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      render: (text: string) => {
        return (
          <div
            className={clsx(
              text === "completed" && "text-green-500",
              "flex capitalize font-medium justify-center "
            )}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: <span className="text-xs flex justify-center">Payment Mode</span>,
      dataIndex: "mode",
      key: "mode",
      render: (text: string) => {
        return (
          <div
            className={clsx(
              text === "completed" && "text-green-500",
              "flex capitalize font-medium justify-center "
            )}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: <span className="text-xs flex justify-center">Total Amount</span>,
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (text: string) => {
        return (
          <div className="flex font-medium justify-center ">
            {" "}
            ₹ {formatIndianCurrency(+text)}
          </div>
        );
      },
    },
    {
      title: <span className="text-xs flex justify-center">Total Tax</span>,
      dataIndex: "totalTax",
      key: "totalTax",
      render: (text: string) => {
        return (
          <div className="flex font-medium justify-center ">
            {" "}
            ₹ {formatIndianCurrency(+text)}
          </div>
        );
      },
    },
    {
      title: <span className="text-xs flex justify-center">Paid Amount</span>,
      dataIndex: "paidAmount",
      key: "paidAmount",
      render: (text: string) => {
        return (
          <div className="flex font-medium justify-center ">
            {" "}
            ₹ {formatIndianCurrency(+text)}
          </div>
        );
      },
    },
    {
      title: <span className="text-xs flex justify-center">Remarks</span>,
      dataIndex: "remark",
      key: "remark",
      render: (text: string) => {
        return (
          <Popover content={text}>
            <div className="flex font-medium text-xs hover:text-primary cursor-pointer justify-center underline ">
              Remark
            </div>
          </Popover>
        );
      },
    },
  ];

  const rowClassName = (_: any, index: number) => {
    return index % 2 === 0 ? "bg-[#131313]" : "bg-bgColor";
  };

  return (
    <div className="bg-readerLightGrayBg rounded-xl ">
      <div className="py-4 px-6 flex  gap-x-2 items-center">
        <p className="font-semibold text-base lg:text-lg whitespace-nowrap">
          Payment History
        </p>
        <ShouldRender check={months || remainingDays}>
          <p className="text-textGrayColor">
            (for last{" "}
            <ShouldRender check={months}>{months} months</ShouldRender>{" "}
            <ShouldRender check={remainingDays}>
              {" "}
              {remainingDays} days
            </ShouldRender>
            )
          </p>
        </ShouldRender>
      </div>
      <div className="customScrollBar ">
        <Table
          dataSource={data?.data}
          loading={loading}
          className={`tableHeader  rounded-none customTable overflow-auto customScrollBar`}
          columns={columns}
          rowKey="key"
          pagination={{
            current: page,
            pageSize: data?.per_page,
            total: data?.total,
            onChange: (newPage) => {
              setPage(newPage);
            },
          }}
          rowClassName={rowClassName}
          locale={{
            emptyText: (
              <span>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>No Products</span>}
                  className={`h-40 flex justify-center items-center flex-col`}
                />
              </span>
            ),
          }}
          scroll={
            data?.data?.length && !screens.xl
              ? { x: "max-content", y: "100vh" }
              : undefined
          }
        />
      </div>
    </div>
  );
};

export default AdminPaymentHistory;
