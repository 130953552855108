import React, { useEffect, useState } from "react";

import { useRecoilValue } from "recoil";
import { dateFilter } from "../../../atoms/dateFilter";

import { Empty, Table } from "antd";
import calculateDateDifference from "../../../components/shared/basic/daysDifference";
import extractErrorMessage from "../../../components/shared/basic/formateError";
import { notify } from "../../../components/shared/basic/notify";
import { ShouldRender } from "../../../components/shared/basic/ShouldRender";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import {
  calculatePayment,
  handleOrderToPayList,
} from "../../../services/Admin/dashboard";
import { IOrderListDataTable } from "../../../utils/interfaces/Admin/dashboard";
import moment from "moment";
import ButtonDefault, {
  ButtonVariants,
} from "../../../components/shared/basic/button";
import PaymentModalSummary from "../../../components/AdminComp/PaymentModalSummary";
import { formatIndianCurrency } from "../../../components/shared/formatIndianCurrency";

const PaymentRequestDetails: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [data, setData] = useState<IOrderListDataTable[]>();
  const [summaryData, setSummaryData] = useState<
    | {
        affiliateName: string;
        paymentRequestID: string;
        bankAccountNo: number;
        ifscCode: string;
        totalAmount: number;
        tdsDeduction: number;
        amountToPay: number;
        order_ids: React.Key[];
        affiliate_user_id: number;
        affiliate_settlement_request_id: string;
      }
    | undefined
  >();
  const [userData, setUserData] = useState<{
    name: string;
    id: number;
  }>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [visible, setVisible] = useState(false);
  const dateFilterValue = useRecoilValue(dateFilter);
  const router = useNavigate();
  const location = useLocation();

  const { id } = useParams();

  const { months, remainingDays } = calculateDateDifference(
    dateFilterValue?.start_date,
    dateFilterValue?.end_date
  );

  const onCancelCloseModal = () => {
    setVisible(false);
  };

  const getOrderToPayList = async () => {
    try {
      setLoading(true);
      const response = await handleOrderToPayList({
        start_date: dateFilterValue?.start_date,
        end_date: dateFilterValue?.end_date,
        affiliate_user_id: +(id as string),
      });
      setUserData({ id: response?.user?.id, name: response?.user?.name });
      setSummaryData((prevState: any) => ({
        ...prevState,
        affiliateName: response?.user?.name,
      }));
      setData(
        response?.orders?.map((order: any) => {
          return {
            id: order?.order_id,
            order_date: order?.order_date,
            order_amount: order?.net_amount,
            key: order?.id,
          };
        })
      );
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getOrderToPayList();
    }
  }, [id, dateFilterValue]);

  const columns = [
    {
      title: <span className="text-xs flex py-2 justify-start">Order Id</span>,
      dataIndex: "id",
      width: "60%",
      key: "id_column",
      render: (text: string) => {
        return (
          <div className="flex font-medium  justify-start text-center">
            #{text}
          </div>
        );
      },
    },
    {
      title: <span className="text-xs flex  justify-center">Order Date</span>,
      dataIndex: "order_date",
      key: "order_date_column",
      render: (text: string) => {
        return (
          <div className="flex font-medium text-center justify-center ">
            {moment.utc(text).format("DD/MM/YYYY")}
          </div>
        );
      },
    },
    {
      title: (
        <span className="text-xs flex justify-center text-center">Amount</span>
      ),
      dataIndex: "order_amount",
      key: "order_amount_column",
      render: (text: string) => {
        return (
          <div className="flex font-medium justify-center ">
            ₹ {formatIndianCurrency(+text)}
          </div>
        );
      },
    },
  ];

  const rowClassName = (_: any, index: number) => {
    return index % 2 === 0 ? "bg-[#131313]" : "bg-bgColor";
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handlePayNow = async (
    order_ids: React.Key[],
    affiliate_user_id: number,
    affiliate_settlement_request_id: string
  ) => {
    try {
      setPaymentLoading(true);
      const response = await calculatePayment(
        order_ids,
        affiliate_user_id,
        affiliate_settlement_request_id
      );
      setSummaryData((prevState: any) => ({
        ...prevState,
        paymentRequestID: id,
        bankAccountNo: response?.bank_details?.account_number,
        ifscCode: response?.bank_details?.ifsc_code,
        totalAmount: response?.commission_amount_before_tax,
        tdsDeduction: response?.tax,
        amountToPay: response?.commission_amount_after_tax,
        order_ids: selectedRowKeys,
        affiliate_user_id: userData?.id,
        affiliate_settlement_request_id: id,
      }));
      setVisible(true);
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setPaymentLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-y-6 py-10">
      <span>
        <div
          onClick={() => router(`/dashboard${location.search}`)}
          className="flex group text-textGrayColor gap-x-2 cursor-pointer hover:text-primary"
        >
          <ChevronLeftIcon className=" w-5 h-5" />{" "}
          <p className="group-hover:underline">Back</p>
        </div>
      </span>

      <div className="bg-readerLightGrayBg rounded-xl ">
        <div className="flex justify-between">
          <span className="py-4 px-6 flex  gap-x-2 items-center">
            <p className="font-semibold text-base lg:text-lg whitespace-nowrap">
              {userData?.name}
            </p>
            <ShouldRender check={months || remainingDays}>
              <p className="text-textGrayColor">
                (for last{" "}
                <ShouldRender check={months}>{months} months</ShouldRender>{" "}
                <ShouldRender check={remainingDays}>
                  {" "}
                  {remainingDays} days
                </ShouldRender>
                )
              </p>
            </ShouldRender>
          </span>
          <span className="flex justify-center items-center px-10 py-4">
            <ButtonDefault
              size={2}
              variant={ButtonVariants.PRIMARY}
              className="w-full h-fit"
              loading={paymentLoading}
              disabled={!selectedRowKeys.length}
              onClick={() =>
                handlePayNow(
                  selectedRowKeys,
                  userData?.id as number,
                  id as string
                )
              }
            >
              <span className="flex text-xs font-bold gap-x-2 px-6  whitespace-nowrap">
                Pay Now
              </span>
            </ButtonDefault>
          </span>
        </div>
        <div className="customScrollBar ">
          <Table
            dataSource={data}
            loading={loading}
            rowSelection={rowSelection}
            rowKey="key"
            className={`tableHeader rounded-none customTable overflow-auto customScrollBar`}
            columns={columns}
            rowClassName={rowClassName}
            locale={{
              emptyText: (
                <span>
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={<span>No Request</span>}
                    className={`h-40 flex justify-center items-center flex-col`}
                  />
                </span>
              ),
            }}
            scroll={data?.length ? { x: "max-content", y: "100vh" } : undefined}
          />
        </div>
      </div>
      <PaymentModalSummary
        closeModal={onCancelCloseModal}
        visible={visible}
        data={summaryData}
      />
    </div>
  );
};

export default PaymentRequestDetails;
