import React from "react";

interface Props {
  width: string;
}

const MakeDifference: React.FC<Props> = ({ width = "24" }) => (
  <svg
    width={width}
    height={width}
    viewBox={`0 0 24 24`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.1775 5.78271L22.7321 10.0749C22.6805 10.546 22.1026 10.7485 21.7713 10.4172L20.7185 9.36439L14.0889 15.994C13.6729 16.41 12.9404 16.41 12.5244 15.994L8.87283 12.3424L2.70336 18.5155C2.48985 18.729 2.20642 18.8395 1.92297 18.8395C1.63952 18.8395 1.3561 18.729 1.14259 18.5155C0.71191 18.0848 0.71191 17.3855 1.14259 16.9511L8.09245 10.0012C8.50841 9.58526 9.24094 9.58526 9.65322 10.0012L13.3085 13.6528L19.1578 7.80362L17.9246 6.57047C17.5896 6.23548 17.7921 5.65757 18.2632 5.60971L22.5554 5.1643C22.9124 5.12381 23.2143 5.42566 23.1775 5.78271Z"
      fill="currentColor"
    />
  </svg>
);

export default MakeDifference;
