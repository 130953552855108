import React, { useEffect, useState } from "react";

import { Empty, Table } from "antd";
import {
  getAdminPaymentHistoryDetails,
  getPaymentHistoryDetails,
} from "../../../../../services/Admin/dashboard";
import extractErrorMessage from "../../../../shared/basic/formateError";
import { notify } from "../../../../shared/basic/notify";
import { useNavigate, useParams } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import { IPaymentDetailsDataTable } from "../../../../../utils/interfaces/Admin/dashboard";
import clsx from "clsx";
import moment from "moment";
import { getAdminRole } from "../../../../../utils/lib/auth";
import { formatIndianCurrency } from "../../../../shared/formatIndianCurrency";

const AdminPaymentHistoryDetails: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IPaymentDetailsDataTable[]>();
  const isAdmin = getAdminRole();

  const router = useNavigate();
  const { id } = useParams();

  const handleAdminPaymentHistoryDetails = async (id: string) => {
    try {
      setLoading(true);
      if (isAdmin === "true") {
        const response = await getAdminPaymentHistoryDetails(id);
        setData(
          response?.map((product: any) => {
            return {
              id: product?.commission_order?.order_id,
              status: product?.commission_order?.is_paid,
              order_date: product?.commission_order?.order_date,
              commissionPercentage:
                product?.commission_order?.commission_percentage,
              commissionAmount: product?.commission_order?.commission_amount,
            };
          })
        );
      } else {
        const response = await getPaymentHistoryDetails(id);
        setData(
          response?.map((product: any) => {
            return {
              id: product?.commission_order?.order_id,
              status: product?.commission_order?.is_paid,
              order_date: product?.commission_order?.order_date,
              key: product?.commission_order?.order_id,
              commissionPercentage:
                product?.commission_order?.commission_percentage,
              commissionAmount: product?.commission_order?.commission_amount,
            };
          })
        );
      }
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (id) {
      handleAdminPaymentHistoryDetails(id);
    }
  }, [id]);

  const columns = [
    {
      title: (
        <span className="text-xs flex py-2 justify-start md:pl-10">
          Order ID
        </span>
      ),
      dataIndex: "id",
      width: "40%",
      key: "id_column",
      render: (text: string) => {
        return (
          <div className="flex font-medium md:pl-10 justify-start text-center">
            {text}
          </div>
        );
      },
    },
    {
      title: <span className="text-xs flex  justify-center">Status</span>,
      dataIndex: "status",
      key: "status_column",
      render: (text: boolean) => {
        return (
          <div
            className={clsx(
              text ? "text-green-500" : "text-red-500",
              "flex font-medium text-center justify-center "
            )}
          >
            {text ? "Paid" : "Un-Paid"}
          </div>
        );
      },
    },
    {
      title: (
        <span className="text-xs flex justify-center text-center">
          Order Date
        </span>
      ),
      dataIndex: "order_date",
      key: "order_date_column",
      render: (text: string) => {
        return (
          <div className="flex font-medium justify-center ">
            {" "}
            {moment.utc(text).format("DD/MM/YYYY")}
          </div>
        );
      },
    },
    {
      title: (
        <span className="text-xs flex justify-center">Commission( % )</span>
      ),
      dataIndex: "commissionPercentage",
      key: "commissionPercentage",
      render: (text: string) => {
        return <div className="flex font-medium justify-center ">{text}%</div>;
      },
    },
    {
      title: <span className="text-xs flex justify-center">Commission</span>,
      dataIndex: "commissionAmount",
      key: "commissionAmount",
      render: (text: string) => {
        return (
          <div className="flex font-medium justify-center ">
            {formatIndianCurrency(+text)}
          </div>
        );
      },
    },
  ];

  const rowClassName = (_: any, index: number) => {
    return index % 2 === 0 ? "bg-[#131313]" : "bg-bgColor";
  };

  return (
    <div className="flex flex-col gap-y-6 py-10">
      <span>
        <div
          onClick={() => router(`/dashboard${location.search}`)}
          className="flex group text-textGrayColor gap-x-2 cursor-pointer hover:text-primary"
        >
          <ChevronLeftIcon className=" w-5 h-5" />{" "}
          <p className="group-hover:underline">Back</p>
        </div>
      </span>
      <div className="customScrollBar ">
        <Table
          dataSource={data}
          loading={loading}
          className={` customTable overflow-auto customScrollBar`}
          columns={columns}
          rowKey="key"
          pagination={false}
          rowClassName={rowClassName}
          locale={{
            emptyText: (
              <span>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>No Record</span>}
                  className={`h-40 flex justify-center items-center flex-col`}
                />
              </span>
            ),
          }}
        />
      </div>
    </div>
  );
};

export default AdminPaymentHistoryDetails;
