import React from "react";

interface Props {
  width: string;
}

const BoostRevenue: React.FC<Props> = ({ width = "24" }) => (
  <svg
    width={width}
    height={width}
    viewBox={`0 0 24 24`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5118 10.559C17.6761 9.53091 16.7208 8.71181 15.6721 8.12278C15.8439 7.91958 15.9794 7.68131 16.0669 7.41577C16.4103 6.37003 15.9087 5.12784 14.6284 4.72317L16.4232 1.59356C16.6497 1.19859 16.4462 0.695531 16.0096 0.568594C14.7119 0.191297 13.3629 0 12 0C10.6371 0 9.28809 0.191297 7.99041 0.568594C7.55325 0.695672 7.35061 1.19906 7.57678 1.59356L9.37158 4.72322C8.09634 5.12583 7.58841 6.36619 7.93327 7.41633C8.0205 7.68117 8.15597 7.9193 8.32823 8.12259C7.27941 8.71163 6.324 9.53081 5.48822 10.559C3.81755 12.6143 2.85938 15.2069 2.85938 17.6719C2.85938 22.7855 6.9397 24 12 24C17.0639 24 21.1406 22.7835 21.1406 17.6719C21.1406 15.2069 20.1825 12.6143 18.5118 10.559ZM9.25763 1.69772C10.1547 1.50408 11.0737 1.40625 12 1.40625C12.9263 1.40625 13.8453 1.50408 14.7424 1.69772L13.194 4.39781C12.4009 4.28709 11.5994 4.28714 10.8061 4.39786L9.25763 1.69772ZM9.717 6.08887C10.9271 5.69194 12.1931 5.6167 13.4239 5.86275C13.4253 5.86303 13.4268 5.86331 13.4283 5.86359C13.7152 5.92116 14.0001 5.99606 14.282 6.08855C14.6505 6.21 14.8519 6.60858 14.7311 6.97641C14.6433 7.2428 14.4115 7.42627 14.1388 7.45598C13.4377 7.23647 12.7227 7.125 12 7.125C11.2788 7.125 10.5651 7.236 9.86541 7.45463C9.58852 7.42613 9.35616 7.24134 9.26911 6.97706C9.14812 6.60858 9.3495 6.20995 9.717 6.08887ZM12 22.6031C6.50644 22.6031 4.26562 21.1678 4.26562 17.6719C4.26562 15.5558 5.13061 13.2285 6.57938 11.446C8.10727 9.56639 10.0323 8.53125 12 8.53125C14.1659 8.53125 16.0604 9.77259 17.4206 11.446C18.8694 13.2285 19.7344 15.5558 19.7344 17.6719C19.7344 21.1678 17.4936 22.6031 12 22.6031Z"
      fill="currentColor"
    />
    <path
      d="M9.56 17.216V15.992H11.112C11.512 15.992 11.7947 15.9067 11.96 15.736C12.1253 15.5653 12.208 15.3573 12.208 15.112C12.208 14.84 12.12 14.616 11.944 14.44C11.7733 14.2587 11.488 14.168 11.088 14.168H9.392V13.392H14.408V14.168H12.568L12.832 13.744C13.152 13.8987 13.3947 14.12 13.56 14.408C13.7253 14.6907 13.808 14.992 13.808 15.312C13.808 15.712 13.7067 16.056 13.504 16.344C13.3013 16.6267 13.0133 16.8427 12.64 16.992C12.272 17.1413 11.832 17.216 11.32 17.216H9.56ZM12.224 19L10.768 16.736H12.448L13.92 19H12.224ZM9.392 15.28V14.504H14.408V15.28H9.392Z"
      fill="currentColor"
    />
  </svg>
);

export default BoostRevenue;
