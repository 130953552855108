import moment from "moment";

interface DateDiff {
  months: number;
  remainingDays: number;
}

const calculateDateDifference = (
  startDateStr: string,
  endDateStr: string
): DateDiff => {
  // Define start and end dates
  const startDate = moment(startDateStr, "YYYY-MM-DD");
  const endDate = moment(endDateStr, "YYYY-MM-DD");

  // Calculate the difference
  const difference = endDate.diff(startDate, "days");

  // Calculate months and remaining days
  const months = Math.floor(difference / 30);
  const remainingDays = difference % 30;

  // If remaining days are 24 or more, adjust the month count
  const adjustedMonths = remainingDays >= 24 ? months + 1 : months;

  return { months: adjustedMonths, remainingDays };
};

export default calculateDateDifference;
