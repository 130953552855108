import React, { useEffect, useState } from "react";
import Card from "../Card";
import { getProductsList } from "../../../../services/dashboard";
import extractErrorMessage from "../../../shared/basic/formateError";
import { notify } from "../../../shared/basic/notify";
import { IProductListData } from "../../../../utils/interfaces/dashboard";
import { ShouldRender } from "../../../shared/basic/ShouldRender";
import { Spin } from "antd";
import ButtonDefault, { ButtonVariants } from "../../../shared/basic/button";
import { getCode } from "../../../../utils/lib/auth";
import { useNavigate } from "react-router-dom";

const ProductListSection = () => {
  const [loading, setLoading] = useState(false);
  const [productListData, setProductListData] = useState<IProductListData>();
  const code = getCode() || "";
  const router = useNavigate();
  const handleProductList = async () => {
    try {
      setLoading(true);
      const response = await getProductsList({
        category_id: 7,
      });
      setProductListData({
        current_page: response?.meta?.current_page,
        per_page: response?.meta?.per_page,
        total: response?.meta?.total,
        products: response?.data?.map((product: any) => {
          return {
            image: product?.thumbnail_image,
            title: product?.name,
            id: product?.id,
            externalLink: product?.external_link,
          };
        }),
      });
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleCopyLink = (url: string) => {
    navigator.clipboard
      .writeText(`${url}?afc=${code}`)
      .then(() => {
        notify("Product link copied successfully to clipboard!!", "success");
      })
      .catch((error) => {
        console.error("Error copying link to clipboard: ", error);
      });
  };

  useEffect(() => {
    handleProductList();
  }, [code]);

  return (
    <div>
      <Card>
        <div className="w-full">
          <span className="flex text-lg py-3 px-4 border-b-2 border-black">
            <p className="font-semibold">Product Links</p>
          </span>
          <ShouldRender check={!loading}>
            <span className="flex flex-col gap-y-4 py-5 w-full px-6">
              {productListData?.products?.slice(0, 4).map((item) => {
                return (
                  <span key={item?.id} className="flex gap-x-4">
                    <img src={item?.image} className="w-16 h-20 rounded-md" />
                    <div className="flex flex-col gap-y-2 justify-between py-2">
                      {" "}
                      <span className="">
                        <p className="font-semibold">{item?.title}</p>
                      </span>
                      <span
                        onClick={() =>
                          handleCopyLink(item.externalLink as string)
                        }
                        className="text-primary hover:text-darkPrimary underline cursor-pointer"
                      >
                        Get Link
                      </span>
                    </div>
                  </span>
                );
              })}
            </span>
          </ShouldRender>

          <ShouldRender check={loading}>
            <span className="py-10 flex justify-center items-center">
              {" "}
              <Spin size="large" />
            </span>
          </ShouldRender>
          <span className="flex justify-center w-full p-4">
            <ButtonDefault
              size={4}
              variant={ButtonVariants.UNSTYLED}
              className="p-1 w-full font-semibold hover:bg-[#101010] border border-bgLightGray  bg-bgColor"
              onClick={() => router("/products")}
            >
              View All
            </ButtonDefault>
          </span>
        </div>
      </Card>{" "}
    </div>
  );
};
export default ProductListSection;
