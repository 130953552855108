import React from "react";

interface ParseProps {
  text: string;
  maxWords: number;
  className?: string;
}

const Parser: React.FC<ParseProps> = ({ text, maxWords, className }) => {
  const parsedText = text?.split(" ")?.slice(0, maxWords).join(" ");
  const ellipsis = text?.split(" ")?.length > maxWords ? "..." : "";

  return (
    <p className={` ${className}`}>
      {parsedText}
      {ellipsis}
    </p>
  );
};

export default Parser;
