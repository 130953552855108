import clsx from "clsx";
import { forOwn } from "lodash";
import React, { ButtonHTMLAttributes, DetailedHTMLProps } from "react";

export enum ButtonVariants {
  WHITE = "WHITE",
  PRIMARY = "PRIMARY",
  UNSTYLED = "UNSTYLED",
  GRAY = "GRAY",
}
type ButtonSize = 0 | 1 | 2 | 3 | 4 | 5;
type ButtonTheme = "apprentice" | "corporate";
interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  size: ButtonSize;
  variant: ButtonVariants;
  theme?: ButtonTheme;
  loading?: boolean;
  rounded?: boolean;
  roundedMd?: boolean;
  roundedSm?: boolean;
  spinColor?: string;
}
interface Styles {
  sizes: { [key: string]: string };
}

const Spinner = ({ color = "currentColor" }: { color?: string }) => (
  <svg className="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke={color}
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill={color}
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);

const commonStyles = () => ({
  [ButtonVariants.WHITE]: clsx(
    "text-gray-700 font-medium focus:ring-primaryLight bg-white border border-gray-300 hover:bg-gray-50 focus:ring-offset-2 focus:ring-2 disabled:text-gray-400 disabled:bg-gray-200 disabled:cursor-not-allowed"
  ),
  [ButtonVariants.GRAY]: clsx(
    "text-fadeWhite font-medium focus:ring-primaryLight bg-bgDarkGray  hover:bg-dividerColor focus:ring-offset-2 focus:ring-2 disabled:text-gray-400 disabled:bg-gray-200 disabled:cursor-not-allowed"
  ),
  [ButtonVariants.PRIMARY]: clsx(
    "text-white bg-primary focus:ring-primary hover:bg-darkPrimary hover:ring-primary font-medium focus:ring-offset-2 focus:ring-2 disabled:text-gray-400 disabled:bg-gray-200 disabled:cursor-not-allowed"
  ),
  [ButtonVariants.UNSTYLED]: "font-inter",
});

const commonButtonSizes: Styles["sizes"] = {
  "0": clsx("px-[4px] py-[2px] text-xs"),
  "1": clsx("px-[11px] py-[7px] text-xs"),
  "2": clsx("px-[13px] py-[9px] text-sm"),
  "3": clsx("px-[17px] py-[9px] text-sm"),
  "4": clsx("px-[17px] py-[9px] text-base"),
  "5": clsx("px-[25px] py-[13px] text-base"),
};

const variantsToStylesMappings: (
  theme: ButtonTheme
) => Record<ButtonVariants, Styles> = () => {
  const styles: Record<ButtonVariants, Styles> = {
    WHITE: {
      sizes: { ...commonButtonSizes },
    },
    PRIMARY: {
      sizes: { ...commonButtonSizes },
    },
    UNSTYLED: {
      sizes: { ...commonButtonSizes },
    },
    GRAY: {
      sizes: { ...commonButtonSizes },
    },
  };

  forOwn(styles, (value, key) => {
    forOwn(value.sizes, (v, k) => {
      value.sizes[k] = clsx(v, commonStyles()[key as ButtonVariants]);
    });
  });

  return styles;
};

const ButtonDefault: React.FC<ButtonProps> = ({
  children,
  variant,
  size,
  theme = "corporate",
  className,
  rounded = true,
  loading = false,
  roundedMd = false,
  roundedSm = false,
  spinColor = "currentColor",
  ...props
}) => {
  return (
    <>
      <button
        className={clsx(
          variantsToStylesMappings(theme)[variant].sizes[size],
          className,
          rounded &&
            ` rounded-xl ${
              roundedMd
                ? "lg:rounded-2xl"
                : roundedSm
                ? "lg:rounded-lg"
                : "lg:rounded-xl"
            } `,
          "flex justify-center items-center "
        )}
        {...props}
      >
        {loading && <Spinner color={spinColor} />}
        {children}
      </button>
    </>
  );
};

export default ButtonDefault;
