import React, { useEffect, useState } from "react";

import { useRecoilValue } from "recoil";

import { Empty, Table } from "antd";
import calculateDateDifference from "../../../shared/basic/daysDifference";
import { dateFilter } from "../../../../atoms/dateFilter";
import extractErrorMessage from "../../../shared/basic/formateError";
import { notify } from "../../../shared/basic/notify";
import { ShouldRender } from "../../../shared/basic/ShouldRender";
import { getAdminProductSales } from "../../../../services/Admin/dashboard";
import { IProductListDataTable } from "../../../../utils/interfaces/dashboard";
import { formatIndianCurrency } from "../../../shared/formatIndianCurrency";

const AdminProductSales: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IProductListDataTable>();
  const [page, setPage] = useState<number>(1);
  const dateFilterValue = useRecoilValue(dateFilter);

  const { months, remainingDays } = calculateDateDifference(
    dateFilterValue?.start_date,
    dateFilterValue?.end_date
  );

  const handleOrdersList = async (page: number) => {
    try {
      setLoading(true);
      const response = await getAdminProductSales(
        page,
        dateFilterValue?.start_date,
        dateFilterValue?.end_date
      );
      setData({
        current_page: response?.meta?.current_page,
        per_page: response?.meta?.per_page,
        total: response?.meta?.total,
        data: response?.data?.map((product: any, index: number) => {
          return {
            name: product?.product_name,
            type: product?.product?.category,
            key: index,
            commission: product?.total_commission,
            quantity: product?.total_quantity,
          };
        }),
      });

      response?.meta?.current_page && setPage(response?.meta?.current_page);
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (page) {
      handleOrdersList(page);
    }
  }, [page, dateFilterValue]);

  const columns = [
    {
      title: (
        <span className="text-xs flex py-2 justify-start md:pl-10">
          Products Name
        </span>
      ),
      dataIndex: "name",
      key: "name_column",
      render: (text: string) => {
        return (
          <div className="flex font-medium md:pl-10 justify-start whitespace-nowrap text-center">
            {text}
          </div>
        );
      },
    },
    {
      title: <span className="text-xs flex  justify-center">Type</span>,
      dataIndex: "type",
      key: "type_column",
      render: (text: string) => {
        return (
          <div className="flex font-medium text-center justify-center xl:pl-5 2xl:pl-0">
            {text}
          </div>
        );
      },
    },
    {
      title: (
        <span className="text-xs flex justify-center text-center">
          Quantity
        </span>
      ),
      dataIndex: "quantity",
      key: "quantity_column",
      render: (text: string) => {
        return (
          <div className="flex font-medium justify-center w-full px-5 xl:pl-5 2xl:pl-0">
            {text}
          </div>
        );
      },
    },
    {
      title: <span className="text-xs flex justify-center">Commission</span>,
      dataIndex: "commission",
      key: "commission",
      render: (text: string) => {
        return (
          <div className="flex font-medium justify-center ">
            {formatIndianCurrency(+text)}
          </div>
        );
      },
    },
  ];

  const rowClassName = (_: any, index: number) => {
    return index % 2 === 0 ? "bg-[#131313]" : "bg-bgColor";
  };

  return (
    <div className="bg-readerLightGrayBg rounded-xl ">
      <div className="py-4 px-6 flex  gap-x-2 items-center">
        <p className="font-semibold text-base lg:text-lg whitespace-nowrap">
          Product Sales{" "}
        </p>
        <ShouldRender check={months || remainingDays}>
          <p className="text-textGrayColor">
            (for last{" "}
            <ShouldRender check={months}>{months} months</ShouldRender>{" "}
            <ShouldRender check={remainingDays}>
              {" "}
              {remainingDays} days
            </ShouldRender>
            )
          </p>
        </ShouldRender>
      </div>
      <div className="customScrollBar ">
        <Table
          dataSource={data?.data}
          loading={loading}
          className={`tableHeader  rounded-none customTable overflow-auto customScrollBar`}
          columns={columns}
          rowKey="key"
          pagination={{
            current: page,
            pageSize: data?.per_page,
            total: data?.total,
            onChange: (newPage) => {
              setPage(newPage);
            },
          }}
          rowClassName={rowClassName}
          locale={{
            emptyText: (
              <span>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>No Products</span>}
                  className={`h-40 flex justify-center items-center flex-col`}
                />
              </span>
            ),
          }}
        />
      </div>
    </div>
  );
};

export default AdminProductSales;
