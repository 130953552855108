import React from "react";
import Card from "../Card";
import CardData from "../CardData";

import { ShouldRender } from "../../../shared/basic/ShouldRender";
import { Spin } from "antd";

interface IActiveUserSection {
  loading: boolean;
  users: number;
}

const ActiveUserSection: React.FC<IActiveUserSection> = ({
  loading,
  users,
}) => {
  const cardDate = {
    title: "Active users",
    price: users,
    date: "30days",
  };

  return (
    <div>
      <Card>
        <div className="px-5 xl:px-12 py-10">
          <ShouldRender check={!loading}>
            <CardData data={cardDate} />
          </ShouldRender>
          <ShouldRender check={loading}>
            <span className="py-10 flex justify-center items-center">
              {" "}
              <Spin size="large" />
            </span>
          </ShouldRender>
        </div>
      </Card>{" "}
    </div>
  );
};
export default ActiveUserSection;
