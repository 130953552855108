import React from "react";
import ButtonDefault, { ButtonVariants } from "../../shared/basic/button";
import clsx from "clsx";
import { getAdminRole, getCode } from "../../../utils/lib/auth";
import { notify } from "../../shared/basic/notify";
import { ShouldRender } from "../../shared/basic/ShouldRender";
import Parser from "../../shared/Parser";

interface IProductCard {
  data: {
    image: string;
    title: number;
    id: number;
    desc?: string;
    externalLink?: string;
    slug?: string;
  };
  isCourse?: boolean;
}

const ProductCard: React.FC<IProductCard> = ({ data, isCourse = false }) => {
  const { image, title, desc, externalLink, slug } = data;
  const isAdmin = getAdminRole();

  const courseBaseUrl = process.env.REACT_APP_COURSES_BASE_URL;

  const code = getCode() || "";

  const handleCopyLink = (url: string) => {
    navigator.clipboard
      .writeText(`${url}?afc=${code}`)
      .then(() => {
        notify("Product link copied successfully to clipboard!!", "success");
      })
      .catch((error) => {
        console.error("Error copying link to clipboard: ", error);
      });
  };

  return (
    <div className="flex flex-col md:flex-row justify-between border-b gap-x-5 border-b-bgLightGray">
      <span className="flex gap-x-4 py-5">
        <img
          src={image}
          className={clsx(
            !isCourse ? "w-28 h-36 rounded-sm" : "w-52 h-28 rounded-lg"
          )}
        />
        <div className="flex gap-y-2 justify-between ">
          <span className="lg:py-6">
            <p className="font-semibold text-xl">{title}</p>
            <p className=" lg:w-1/2 text-textDarkGrayColor ">
              <Parser
                maxWords={20}
                text={desc?.replace(/<[^>]*>?/gm, "") || ""}
              />
            </p>
          </span>
        </div>
      </span>
      <span className="flex flex-col gap-y-2 items-center justify-center">
        <ShouldRender check={isAdmin !== "true"}>
          <ButtonDefault
            size={2}
            variant={ButtonVariants.WHITE}
            className="p-1 w-full"
            onClick={() => {
              if (isCourse) {
                handleCopyLink(
                  `${courseBaseUrl}/#/course/info/${slug}` as string
                );
              } else {
                handleCopyLink(externalLink as string);
              }
            }}
          >
            {" "}
            <span rel="noreferrer" className="px-5 whitespace-nowrap">
              Copy link
            </span>
          </ButtonDefault>
        </ShouldRender>

        <ButtonDefault
          size={2}
          variant={ButtonVariants.GRAY}
          className="p-1 w-full"
        >
          {" "}
          <a
            href={`${
              isCourse ? `${courseBaseUrl}/#/course/info/${slug}` : externalLink
            }?afc=${code}`}
            target="_blank"
            rel="noreferrer"
            className="px-5 whitespace-nowrap "
          >
            Know more
          </a>
        </ButtonDefault>
      </span>
    </div>
  );
};
export default ProductCard;
