import React, { useState } from "react";
import AuthLayout from "../AuthLayout";
import { Divider, Input } from "antd";
import { useLogin } from "../../../hooks/useAuth/useLogin";
import { useRecoilState } from "recoil";
import { authModalControl } from "../../../atoms/toggleTheme/authModalControl";
import EmailIcon from "../../../assets/Icons/Mail";
import PasswordIcon from "../../../assets/Icons/password";
import ButtonDefault, { ButtonVariants } from "../../shared/basic/button";
import { useNavigate } from "react-router-dom";
import ForgotPasswordModal from "../ForgotPassword";
import { AppleLogo, GoogleIcon } from "../../../assets";
import appwriteService from "../../../config/appwrite";
import { ShouldRender } from "../../shared/basic/ShouldRender";
import { handleShowFields } from "../../../atoms/showFields";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

const Login = () => {
  const { onLogin, loading } = useLogin();
  const [visible, setVisible] = useRecoilState(authModalControl);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [appleLoading, setAppleLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [showFields, setShowFields] = useRecoilState(handleShowFields);
  const showAppleLogin = process.env.REACT_APP_SHOW_APPLE_LOGIN === "true";

  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const screens = useBreakpoint();
  const router = useNavigate();
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name === "email__"
        ? "email"
        : name === "password__"
        ? "password"
        : name]: value,
    });
  };
  const onCancelCloseForgotPasswordModal = () => {
    setVisible((prevState) => ({
      ...prevState,
      forgotPassword: false,
    }));
  };
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Validation
    const validationErrors = {
      email: "",
      password: "",
    };
    if (!formData.email) {
      validationErrors.email = "Email or phone number is required";
    } else if (!isValidEmailOrPhone(formData.email)) {
      validationErrors.email = "Invalid email or phone number";
    }

    if (!formData.password) {
      validationErrors.password = "Password is required";
    }

    setErrors(validationErrors);

    if (Object.values(validationErrors).every((error) => !error)) {
      onLogin(formData.email, formData.password);
    }
  };

  const isValidEmailOrPhone = (value: string) => {
    // Your combined validation logic for email and phone number
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const phoneRegex = /^\d{10}$/;

    return emailRegex.test(value) || phoneRegex.test(value);
  };

  const handleGoogleLogin = async () => {
    setGoogleLoading(true);
    try {
      await appwriteService.loginWithGoogle();
    } catch (error) {
      console.error("Error logging in with Google:", error);
    } finally {
      setGoogleLoading(false);
      setVisible((prevState) => ({
        ...prevState,
        login: false,
        signUp: false,
      }));
    }
  };

  const handleAppleLogin = async () => {
    setAppleLoading(true);
    try {
      await appwriteService.loginWithApple();
    } catch (error) {
      console.error("Error logging in with Google:", error);
    } finally {
      setAppleLoading(false);
      setVisible((prevState) => ({
        ...prevState,
        login: false,
        signUp: false,
      }));
    }
  };

  return (
    <>
      <AuthLayout setShowFields={setShowFields} showFields={showFields}>
        <div className="flex flex-1 min-h-screen   flex-col justify-center px-4  sm:px-6 lg:flex-none lg:px-12">
          <div className="mx-auto w-full  ">
            <div className="flex items-center gap-y-10 flex-col justify-center md:px-5 xl:px-10">
              <div className={` z-30  flex flex-col gap-y-1 w-full `}>
                <div className=" py-3" />
                <div className="py-3 flex flex-col gap-y-2  lg:mt-10">
                  <ShouldRender check={!screens?.lg}>
                    <div
                      onClick={() => setShowFields(!showFields)}
                      className="flex group items-center text-textGrayColor gap-x-2 cursor-pointer hover:underline"
                    >
                      <ChevronLeftIcon className=" w-4 h-4" />{" "}
                      <p className="group-hover:underline">Back</p>
                    </div>
                  </ShouldRender>

                  <h1 className="text-2xl lg:font-semibold flex justify-center gap-x-2 font-medium lg:text-2xl mt-1 leading-10 text-fadedWhite text-center w-full">
                    Welcome to Zebralearn
                  </h1>
                  <span className="text-sm lg:text-base text-textDarkGrayColor text-center flex justify-center">
                    Enter your credentials to login
                  </span>
                </div>

                <form
                  onSubmit={handleSubmit}
                  className="flex flex-col mt-2 gap-y-3 "
                >
                  <div className="col-span-1 flex-col flex gap-y-.5">
                    <Input
                      type="text"
                      name="email__"
                      autoComplete="off"
                      style={{
                        backgroundColor: "#141414",
                      }}
                      value={formData.email}
                      placeholder="Email"
                      prefix={
                        <span className="pr-2">
                          <EmailIcon filled={true} />
                        </span>
                      }
                      onChange={handleChange}
                      size="large"
                      className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
                    />
                    {errors.email && (
                      <p className="text-red-500">{errors.email}</p>
                    )}
                  </div>
                  <div className="col-span-1 flex-col flex ">
                    <Input.Password
                      type="text"
                      name="password__"
                      autoFocus={false}
                      style={{
                        backgroundColor: "#141414",
                      }}
                      autoComplete="off"
                      placeholder="Password"
                      value={formData.password}
                      prefix={
                        <span className="pr-2">
                          <PasswordIcon />
                        </span>
                      }
                      onChange={handleChange}
                      size="large"
                      className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
                    />
                    {errors.password && (
                      <p className="text-red-500">{errors.password}</p>
                    )}
                    <span
                      onClick={() =>
                        setVisible((prevState) => ({
                          ...prevState,
                          login: false,
                          signUp: false,
                          forgotPassword: true,
                        }))
                      }
                      className="font-medium text-primary mt-1 cursor-pointer hover:text-darkPrimary"
                    >
                      Forgot password?
                    </span>
                  </div>

                  <span className="flex justify-center mt-2">
                    <ButtonDefault
                      size={4}
                      variant={ButtonVariants.WHITE}
                      className="p-1 w-full"
                      disabled={loading}
                      loading={loading}
                      // onClick={() => router("/dashboard")}
                    >
                      <span className="flex text-sm font-bold gap-x-2 px-4 py-1 whitespace-nowrap">
                        Login
                      </span>
                    </ButtonDefault>
                  </span>
                  <span className="text-center font-medium flex text-white justify-center text-base pt-4">
                    Don’t have an account?
                    <p
                      onClick={() => router("/signUp")}
                      className="text-primary  px-1 hover:font-bold hover:underline cursor-pointer transition-all duration-300 ease-in-out"
                    >
                      Sign up
                    </p>
                  </span>
                  <span className="flex justify-center">
                    <span className="text-center text-base text-fadedWhite w-1/3">
                      <Divider
                        style={{
                          margin: "8px 0",
                          // borderColor: '',
                          // color: "white",
                        }}
                        className="text-fadedWhite text-sm"
                      >
                        OR
                      </Divider>
                    </span>
                  </span>
                  <span className="flex relative justify-center ">
                    <ButtonDefault
                      size={4}
                      variant={ButtonVariants.UNSTYLED}
                      className="disabled:bg-bgDarkGray hover:bg-[#181818] disabled:text-gray-300 text-fadedWhite bg-black w-full  border border-bgDarkGray shadow-sm text-base"
                      rounded={true}
                      loading={googleLoading}
                      spinColor="gray"
                      disabled={googleLoading}
                      onClick={(e) => {
                        e.preventDefault();
                        handleGoogleLogin();
                      }}
                    >
                      <span className="flex  w-full justify-center text-white   items-center font-medium gap-x-3 px-8  whitespace-nowrap">
                        <span className="w-7 h-7 absolute left-4">
                          <img
                            src={GoogleIcon}
                            alt="GoogleLogo"
                            className="object-contain"
                          />
                        </span>{" "}
                        Continue with Google
                      </span>
                    </ButtonDefault>
                  </span>
                  <ShouldRender check={showAppleLogin}>
                    <span className="flex justify-center ">
                      <ButtonDefault
                        size={4}
                        variant={ButtonVariants.UNSTYLED}
                        loading={appleLoading}
                        spinColor="gray"
                        rounded={true}
                        disabled={appleLoading}
                        className="disabled:bg-bgDarkGray hover:bg-[#181818] disabled:text-gray-300 text-fadedWhite bg-black w-full  border border-bgDarkGray shadow-sm text-base"
                        onClick={(e) => {
                          e.preventDefault();
                          handleAppleLogin();
                        }}
                      >
                        <span className="flex relative w-full items-end justify-center text-white   font-medium gap-x-3 px-8  whitespace-nowrap">
                          <span className="w-5  absolute left-1">
                            <img
                              src={AppleLogo}
                              alt="GoogleLogo"
                              className="object-contain"
                            />
                          </span>{" "}
                          Continue with Apple
                        </span>
                      </ButtonDefault>
                    </span>
                  </ShouldRender>

                  <div className="flex justify-center pb-4">
                    <p className="text-center text-fadedWhite font-medium text-base md:w-2/3 ">
                      By using our service, you are agreeing to our{" "}
                      <b className="text-primary">Terms</b> and{" "}
                      <b className="text-primary">conditions</b>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ForgotPasswordModal
          onCancel={onCancelCloseForgotPasswordModal}
          visible={visible.forgotPassword}
        />
      </AuthLayout>
    </>
  );
};
export default Login;
