import { Tabs } from "antd";
import React, { useState, useEffect } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { pageTitle } from "../../components/shared/basic/customHead";
import { useRecoilState } from "recoil";
import { UserData } from "../../utils/interfaces/user";
import { user } from "../../atoms/toggleTheme/user";
import { getUser } from "../../services/user";
import { ShouldRender } from "../../components/shared/basic/ShouldRender";
import CustomLoader from "../../components/shared/Loading";
import AllUsers from "../../components/AdminComp/Dashboard/AllUsers";
import AdminProductSales from "../../components/AdminComp/Dashboard/AdminProductSales";
import AdminPaymentRequest from "../../components/AdminComp/Dashboard/AdminPaymentRequest";
import AdminPaymentHistory from "../../components/AdminComp/Dashboard/AdminPaymentHistory";
import PaymentRequestUser from "../../components/DashboardComp/Dashboard/PaymentRequestUser";
import DashboardComp from "../../components/DashboardComp/Dashboard";
import ProductSale from "../../components/DashboardComp/ProductSale";
import { setAdminRole } from "../../utils/lib/auth";
import { Roles } from "../../utils/interfaces/roles";

const DashboardPage = () => {
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("1");
  const [, setUserData] = useRecoilState<UserData | undefined>(user);
  const [items, setItems] = useState<any>([]);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    try {
      const { data } = await getUser();
      const isAdmin =
        data?.data?.roles &&
        data.data.roles.some((role: any) => {
          const adminRoles = [
            Roles.SUPER_ADMIN,
            Roles.MANAGER,
            Roles.AFFILIATE_MANAGER,
            Roles.ADMIN,
          ];
          return adminRoles.includes(role.name);
        });

      await setAdminRole(isAdmin as boolean);

      setUserData(data?.data);
      setLoading(false);

      const tabs = isAdmin
        ? [
            {
              key: "1",
              label: (
                <div className="text-sm font-medium px-1">All Affiliate</div>
              ),
              children: <AllUsers />,
            },
            {
              key: "2",
              label: (
                <div className="text-sm font-medium px-1">Product sales</div>
              ),
              children: <AdminProductSales />,
            },
            {
              key: "3",
              label: (
                <div className="text-sm font-medium px-1">Payment History</div>
              ),
              children: <AdminPaymentHistory />,
            },
            {
              key: "4",
              label: (
                <div className="text-sm font-medium px-1">Payment Request</div>
              ),
              children: <AdminPaymentRequest />,
            },
          ]
        : [
            {
              key: "1",
              label: <div className="text-sm font-medium px-1">Dashboard</div>,
              children: <DashboardComp />,
            },
            {
              key: "2",
              label: (
                <div className="text-sm font-medium px-1">Product sales</div>
              ),
              children: <ProductSale />,
            },
            {
              key: "3",
              label: (
                <div className="text-sm font-medium px-1">Payment History</div>
              ),
              children: <AdminPaymentHistory />,
            },
            {
              key: "4",
              label: (
                <div className="text-sm font-medium px-1">Payment Request</div>
              ),
              children: <PaymentRequestUser />,
            },
          ];

      setItems(tabs);

      if (searchParams.get("tab")) {
        setActiveTab(searchParams.get("tab") as string);
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
      setLoading(false);
    }
  };

  const handleTabChange = (key: string) => {
    setActiveTab(key);
    setSearchParams({ tab: key });
  };

  return (
    <div>
      <Helmet>
        <title>{pageTitle("Dashboard")}</title>
      </Helmet>
      <ShouldRender check={!loading}>
        <div className="relative">
          <Tabs
            activeKey={activeTab}
            items={items}
            onTabClick={handleTabChange}
            className="customTab"
          ></Tabs>
        </div>
      </ShouldRender>
      <ShouldRender check={loading}>
        <CustomLoader />
      </ShouldRender>
      <Outlet />
    </div>
  );
};

export default DashboardPage;
