export const Endpoints = {
  login: `/api/v3/auth/login`,
  signUp: `/api/v3/affiliate-users`,
  emailExist: `/api/v3/auth/email-exist`,
  forgotPasswordSendEmail: `/api/v3/auth/password/forget_request`,
  forgotPasswordCheckOtp: `/api/v3/auth/password/confirm_otp`,
  forgotPasswordResetPassword: `/api/v3/auth/password/confirm_reset`,
  users: `/api/v3/affiliate/user-info`,
  updateProfile: `api/v3/profile/update`,
  getOrdersList: `/api/v3/affiliate/orders`,
  getOrderSummary: `/api/v3/affiliate/summary`,
  getUserRegistration: `/api/v3/affiliate/user-registration`,
  getProductsList: `/api/v3/products/index_search`,
  getTotalEarning: `/api/v3/affiliate/earning`,
  affiliateCheck: `/api/v3/affiliate/check`,
  getCommission: `/api/v3/affiliate/commission`,
  getProductSales: `/api/v3/affiliate/product-sales`,
  changePassword: `/api/v3/password/change_password`,
  paymentRequest: `/api/v3/affiliate/settlement-requests`,

  // Bank Info
  addBankDetails: `/api/v3/affiliate/bank-details`,

  affiliateChannels: `/api/v3/affiliate/channels`,

  // Admin
  getAllUsers: `/api/v3/affiliate-users`,
  getAdminProductSales: `/api/v3/affiliate/admin/product-sales`,
  getAdminPaymentRequest: `/api/v3/affiliate/admin/settlement-requests`,
  handleStatusChange: `/api/v3/affiliate/settlement-requests`,
  handleOrderToPayList: `/api/v3/affiliate/order-to-pay-list`,

  // Calculate Payment
  calculatePayment: `/api/v3/affiliate/admin/calculate-payment`,
  payment: `/api/v3/affiliate/admin/payment-store`,
  adminPaymentHistory: `/api/v3/affiliate/payments`,
  paymentHistory: `/api/v3/affiliate/my-payments`,
  adminPaymentHistoryDetails: `/api/v3/affiliate/admin/payment-history-details`,

  paymentHistoryDetails: `/api/v3/affiliate/payment-history-details`,

  withDrawAmount: `/api/v3/affiliate/settlement-requests`,

  getUserById: `/api/v3/affiliate-users`,

  //Admin fetch user
  adminUsers: `/api/v3/admin/affiliate/user-info`,
  getAdminTotalEarning: `/api/v3/admin/affiliate/earning`,
  getAdminOrdersList: `/api/v3/admin/affiliate/orders`,
  getAdminCommission: `/api/v3/admin/affiliate/commission`,
  getAdminProductSalesUser: `/api/v3/admin/affiliate/product-sales`,
  getAdminOrderSummary: `/api/v3/admin/affiliate/summary`,
  getAdminUserRegistration: `/api/v3/admin/affiliate/user-registration`,

  // AppWrite Api
  appWriteUser: `/api/v3/auth/appwrite-user`,

  // Readers Api
  getAllShortBooks: `api/books/shorts`,
  getCoverImagesForBooks: `api/books/cover-file`,

  // IFrame
  getXlsxFile: `/api/v3/affiliate/iframe-excel`,
};
