import Cookies from "js-cookie";
import { get, isArray, isObjectLike, has } from "lodash";

export const setToken = async (token: string) => {
  const expirationTime = new Date(Date.now() + 13 * 60 * 1000);
  Cookies.set("auth_token", token, { expires: expirationTime });
};

export const setAdminRole = async (value: boolean) => {
  return Cookies.set("admin_role", value?.toString());
};

export const getAdminRole = () => {
  return Cookies.get("admin_role");
};

export const removeAdminRole = () => {
  Cookies.remove("admin_role");
};

export const setCode = async (code: string) => {
  Cookies.set("afc", code);
};

export const getCode = () => {
  return Cookies.get("afc");
};

export const removeAuth = () => {
  Cookies.remove("auth_token");
};

export const formatErrorObject = (
  error: any,
  path = "response.data"
): string => {
  const errorObj = path ? get(error, path) : error;
  let errorMessage = errorObj?.message;
  if (has(errorMessage, "message")) {
    errorMessage = errorMessage.message;
  }
  return isObjectLike(errorMessage) || isArray(errorMessage)
    ? JSON.stringify(errorMessage)
    : errorMessage;
};
