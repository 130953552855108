import React, { useEffect, useState } from "react";
import { getProductsList } from "../../../services/dashboard";
import extractErrorMessage from "../../shared/basic/formateError";
import { notify } from "../../shared/basic/notify";
import { useRecoilValue } from "recoil";
import { dateFilter } from "../../../atoms/dateFilter";
import { IProductListData } from "../../../utils/interfaces/dashboard";
import ProductCard from "../ProductCard";
import { Pagination, PaginationProps, Spin } from "antd";
import { ShouldRender } from "../../shared/basic/ShouldRender";
import { searchKeywords } from "../../../atoms/searchKeywords";
import { debounce } from "lodash";

const Books: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const searchKeyword = useRecoilValue(searchKeywords);
  const dateFilterValue = useRecoilValue(dateFilter);
  const { end_date, start_date } = dateFilterValue;

  const [productListData, setProductListData] = useState<IProductListData>();

  const handleProductList = async (keyword?: string) => {
    try {
      setLoading(true);
      const response = await getProductsList({
        category_id: 6,
        end_date,
        start_date,
        page,
        book_type: "hardcover",
        name: keyword,
      });

      setProductListData({
        current_page: response?.meta?.current_page,
        per_page: response?.meta?.per_page,
        total: response?.meta?.total,
        products: response?.data?.map((product: any) => {
          return {
            image: product?.thumbnail_image,
            title: product?.name,
            id: product?.id,
            desc: product?.description,
            externalLink: product?.external_link,
            slug: product?.slug,
          };
        }),
      });
      response?.meta?.current_page && setPage(response?.meta?.current_page);
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };

  const delayedSearch = debounce((keyword: string) => {
    handleSearch(keyword);
  }, 500);
  const handleSearch = async (keyword: string) => {
    if (keyword !== undefined) {
      handleProductList(keyword);
    }
  };
  useEffect(() => {
    delayedSearch(searchKeyword);
    return () => {
      delayedSearch.cancel();
    };
  }, [searchKeyword]);
  useEffect(() => {
    handleProductList();
  }, [page]);

  const onChange: PaginationProps["onChange"] = (pageNumber: number) => {
    setPage(pageNumber);
  };

  return (
    <div>
      <div className="relative  rounded-xl flex flex-col gap-y-5 mt-5">
        <ShouldRender check={!loading}>
          {productListData?.products?.map((product) => {
            return (
              <span key={product?.id}>
                <ProductCard data={product} />
              </span>
            );
          })}
          <div className="flex justify-end pt-5">
            <Pagination
              current={productListData?.current_page}
              total={productListData?.total}
              pageSize={productListData?.per_page || 0}
              onChange={onChange}
            />
          </div>
        </ShouldRender>
        <ShouldRender check={loading}>
          <span className="py-28 flex justify-center items-center">
            {" "}
            <Spin size="large" />
          </span>
        </ShouldRender>
      </div>
    </div>
  );
};
export default Books;
