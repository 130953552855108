import React, { useEffect, useState } from "react";
import { Button, Input, Switch } from "antd";

import extractErrorMessage from "../../shared/basic/formateError";
import { notify } from "../../shared/basic/notify";
import {
  addBankDetails,
  getBankDetails,
  updateBankDetails,
  updateBankDetailsStatus,
} from "../../../services/profileSettings";
import clsx from "clsx";
import { useRecoilState } from "recoil";
import { triggerHandleSubmitStep2 } from "../../../atoms/triggerHandleSubmitStep2";
import { ShouldRender } from "../../shared/basic/ShouldRender";
import { onBoardingSteps } from "../../../atoms/onBoardingSteps";
import { useParams } from "react-router-dom";
import { getUserById } from "../../../services/user";

interface PaymentManagementProps {
  isModal?: boolean;
}

const PaymentManagement: React.FC<PaymentManagementProps> = ({
  isModal = false,
}) => {
  const [formData, setFormData] = useState({
    account_number: "",
    ifsc_code: "",
    bank_name: "",
    branch: "",
  });
  const [bankDetailId, setBankDetailId] = useState<string>();
  const [isVerified, setIsVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [triggerHandleSubmitStep2Value, setTriggerHandleSubmitStep2Value] =
    useRecoilState(triggerHandleSubmitStep2);
  const [onboardingStepsValue, setOnboardingStepsValue] =
    useRecoilState(onBoardingSteps);
  const { id } = useParams();

  const [errors, setErrors] = useState({
    account_number: "",
    ifsc_code: "",
    bank_name: "",
    branch: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e?: React.FormEvent) => {
    e?.preventDefault();

    const validationErrors = {
      account_number: "",
      ifsc_code: "",
      bank_name: "",
      branch: "",
    };

    if (!formData.account_number) {
      validationErrors.account_number = "Account Number is required";
    } else if (isNaN(Number(formData.account_number))) {
      validationErrors.account_number = "Account Number must be a valid number";
    }
    if (!formData.ifsc_code) {
      validationErrors.ifsc_code = "IFSC code is required";
    }
    if (!formData.bank_name) {
      validationErrors.bank_name = "Bank name is required";
    }
    if (!formData.branch) {
      validationErrors.branch = "Branch name is required";
    }

    setErrors(validationErrors);

    if (Object.values(validationErrors).every((error) => !error)) {
      try {
        setLoading(true);
        if (bankDetailId) {
          await updateBankDetails(
            {
              account_number: formData?.account_number,
              bank_name: formData?.bank_name,
              branch: formData?.branch,
              ifsc_code: formData?.ifsc_code,
            },
            bankDetailId
          );
          notify("Bank details updated successfully!", "success");
        } else {
          await addBankDetails({
            account_number: formData?.account_number,
            bank_name: formData?.bank_name,
            branch: formData?.branch,
            ifsc_code: formData?.ifsc_code,
          });
          notify("Bank details added successfully!", "success");
        }
        setOnboardingStepsValue(onboardingStepsValue + 1);
      } catch (error: any) {
        const message = extractErrorMessage(error);
        notify(message, "error");
      } finally {
        setLoading(false);
      }
    }
  };

  const fields: (keyof typeof formData)[] = [
    "account_number",
    "ifsc_code",
    "bank_name",
    "branch",
  ];
  const isAnyFieldEmpty = fields.some((field) => !formData[field]);

  const fetchBankDetails = async () => {
    try {
      setLoading(true);
      const response = await getBankDetails();
      if (response?.id) {
        setFormData({
          account_number: response?.account_number,
          bank_name: response?.bank_name,
          branch: response?.branch,
          ifsc_code: response?.ifsc_code,
        });
        setBankDetailId(response?.id);
      }
    } catch (error: any) {
      const message = extractErrorMessage(error);
      // notify(message, "error");
      console.log(message);
    } finally {
      setLoading(false);
    }
  };

  const getUserDetailsById = async (userId: string) => {
    try {
      setLoading(true);
      const data = await getUserById(userId);
      setFormData({
        account_number: data?.bank_details?.account_number,
        ifsc_code: data?.bank_details?.ifsc_code,
        bank_name: data?.bank_details?.bank_name,
        branch: data?.bank_details?.branch,
      });
      setBankDetailId(data?.bank_details?.id);
      setIsVerified(data?.bank_details?.is_verified);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getUserDetailsById(id as string);
    } else {
      fetchBankDetails();
    }
  }, [id]);

  const triggerThroughNext = async () => {
    try {
      if (triggerHandleSubmitStep2Value) {
        await handleSubmit();
        setTriggerHandleSubmitStep2Value(!triggerHandleSubmitStep2Value);
      }
    } catch (error: any) {
      const message = extractErrorMessage(error);
      console.log(message);
    }
  };

  useEffect(() => {
    triggerThroughNext();
  }, [triggerHandleSubmitStep2Value]);

  const onChange = async (checked: boolean) => {
    try {
      setLoading(true);

      await updateBankDetailsStatus(checked, bankDetailId as string);
      setIsVerified(checked);
      notify("Bank details status updated successfully!", "success");
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="flex flex-col gap-y-5">
      <div
        className={clsx(
          !isModal &&
            "border-[#444444] border px-4 md:px-8 py-6 rounded-xl flex flex-col gap-y-8 "
        )}
      >
        <div
          className={clsx(
            !isModal && "md:w-1/2 lg:w-1/3",
            "flex flex-col gap-y-4"
          )}
        >
          <form className="flex flex-col mt-2 gap-y-4">
            <div className="col-span-1 flex-col flex gap-y-2">
              <label
                htmlFor="account_number"
                className="text-textGraylight font-medium"
              >
                Bank Account Number
              </label>
              <Input
                name="account_number"
                id="account_number"
                disabled={!!id}
                placeholder="Bank Account number"
                value={formData.account_number}
                onChange={handleChange}
                size="large"
                className={clsx(
                  !isModal ? "bg-bgColor" : "bg-[#303030]",
                  "border text-sm focus-within:bg-bgColor placeholder:text-textDarkGrayColor rounded-lg border-bgDarkGray py-2 text-white  customInputBlack makeBgBlack"
                )}
              />
              {errors.account_number && (
                <p className="text-red-500">{errors.account_number}</p>
              )}
            </div>
            <div className="col-span-1 flex-col flex gap-y-2">
              <label
                htmlFor="ifsc_code"
                className="text-textGraylight font-medium"
              >
                IFSC code
              </label>
              <Input
                name="ifsc_code"
                placeholder="IFSC code"
                disabled={!!id}
                value={formData.ifsc_code}
                onChange={handleChange}
                size="large"
                className={clsx(
                  !isModal ? "bg-bgColor" : "bg-[#303030]",
                  "border text-sm focus-within:bg-bgColor placeholder:text-textDarkGrayColor rounded-lg border-bgDarkGray py-2 text-white  customInputBlack makeBgBlack"
                )}
              />
              {errors.ifsc_code && (
                <p className="text-red-500">{errors.ifsc_code}</p>
              )}
            </div>
            <div className="col-span-1 flex-col flex gap-y-2">
              <label
                htmlFor="bank_name"
                className="text-textGraylight font-medium"
              >
                Bank Name
              </label>
              <Input
                name="bank_name"
                placeholder="Bank Name"
                disabled={!!id}
                value={formData.bank_name}
                onChange={handleChange}
                size="large"
                className={clsx(
                  !isModal ? "bg-bgColor" : "bg-[#303030]",
                  "border text-sm focus-within:bg-bgColor placeholder:text-textDarkGrayColor rounded-lg border-bgDarkGray py-2 text-white  customInputBlack makeBgBlack"
                )}
              />
              {errors.bank_name && (
                <p className="text-red-500">{errors.bank_name}</p>
              )}
            </div>
            <div className="col-span-1 flex-col flex gap-y-2">
              <label
                htmlFor="branch"
                className="text-textGraylight font-medium"
              >
                Branch Name
              </label>
              <Input
                name="branch"
                placeholder="Branch Name"
                disabled={!!id}
                value={formData.branch}
                onChange={handleChange}
                size="large"
                className={clsx(
                  !isModal ? "bg-bgColor" : "bg-[#303030]",
                  "border text-sm focus-within:bg-bgColor placeholder:text-textDarkGrayColor rounded-lg border-bgDarkGray py-2 text-white  customInputBlack makeBgBlack"
                )}
              />
              {errors.branch && <p className="text-red-500">{errors.branch}</p>}
            </div>
          </form>
          <ShouldRender check={id}>
            <span className="flex flex-col gap-y-3 w-fit">
              <span className="text-fadedWhite font-medium">
                Verify Bank Details
              </span>
              <Switch
                checkedChildren="Verified"
                unCheckedChildren="Un-Verified"
                loading={loading}
                size="default"
                onChange={onChange}
                disabled={isAnyFieldEmpty}
                value={isVerified}
              />
            </span>
          </ShouldRender>
        </div>
      </div>
      <ShouldRender check={!isModal && !id}>
        <span>
          <Button
            disabled={loading}
            loading={loading}
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Save changes
          </Button>
        </span>
      </ShouldRender>
    </div>
  );
};
export default PaymentManagement;
