import { Spin } from "antd";
import clsx from "clsx";
import React, { FC } from "react";

interface Props {
  width?: string;
  height?: string;
}

const CustomLoader: FC<Props> = ({ width, height }) => (
  <div
    className={clsx(
      "fixed top-0 left-0 w-full h-full flex items-center justify-center z-50",
      "bg-transparent backdrop-blur-xl",
      width || "w-full",
      height || "h-screen"
    )}
  >
    {/* <img src="/icon_loader.gif" className="w-[640px] pb-[100px]" alt="Loading" /> */}
    <Spin size="large" className="w-[640px] pb-24" />
  </div>
);

export default CustomLoader;
