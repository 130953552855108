import React from "react";
import { CustomIconProps } from "../../utils/interfaces/user/icons";

const PersonIcon: React.FC<CustomIconProps & { outlined?: boolean }> = ({
  width,
  height,
  fill,
  outlined,
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "24"}
      height={height ? height : "24"}
      viewBox="0 0 24 24"
      fill="none" // Always set fill to "none"
      stroke="#737373"
      strokeWidth={outlined ? "1.5" : "0"}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
    >
      <path
        d="M18.1394 21.6198C17.2594 21.8798 16.2194 21.9998 14.9994 21.9998H8.99937C7.77937 21.9998 6.73937 21.8798 5.85938 21.6198C6.07937 19.0198 8.74937 16.9697 11.9994 16.9697C15.2494 16.9697 17.9194 19.0198 18.1394 21.6198Z"
        fill={outlined ? "none" : fill}
      />
      <path
        stroke="#737373"
        d="M15 2H9C4 2 2 4 2 9V15C2 18.78 3.14 20.85 5.86 21.62C6.08 19.02 8.75 16.97 12 16.97C15.25 16.97 17.92 19.02 18.14 21.62C20.86 20.85 22 18.78 22 15V9C22 4 20 2 15 2ZM12 14.17C10.02 14.17 8.42 12.56 8.42 10.58C8.42 8.60002 10.02 7 12 7C13.98 7 15.58 8.60002 15.58 10.58C15.58 12.56 13.98 14.17 12 14.17Z"
      />
      <path
        d="M15.5819 10.58C15.5819 12.56 13.9819 14.17 12.0019 14.17C10.0219 14.17 8.42188 12.56 8.42188 10.58C8.42188 8.60002 10.0219 7 12.0019 7C13.9819 7 15.5819 8.60002 15.5819 10.58Z"
        fill={outlined ? "none" : fill}
      />
    </svg>
  );
};

export default PersonIcon;
