import React, { useEffect, useState } from "react";

import { useRecoilValue } from "recoil";

import { Empty, Table } from "antd";
import calculateDateDifference from "../../../shared/basic/daysDifference";
import { dateFilter } from "../../../../atoms/dateFilter";
import extractErrorMessage from "../../../shared/basic/formateError";
import { notify } from "../../../shared/basic/notify";
import { ShouldRender } from "../../../shared/basic/ShouldRender";
import { IRequestPaymentDataTable } from "../../../../utils/interfaces/dashboard";
import { paymentRequest } from "../../../../services/dashboard";
import clsx from "clsx";
import moment from "moment";

export enum RequestStatus {
  approved = "Approved",
  rejected = "Rejected",
  pending = "Pending",
}

const PaymentRequestUser: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IRequestPaymentDataTable>();
  const [page, setPage] = useState<number>(1);
  const dateFilterValue = useRecoilValue(dateFilter);

  const { months, remainingDays } = calculateDateDifference(
    dateFilterValue?.start_date,
    dateFilterValue?.end_date
  );

  const getColorForRequestStatus = (status: string) => {
    switch (status) {
      case RequestStatus.approved:
        return "text-green-500";
      case RequestStatus.rejected:
        return "text-red-500";

      case RequestStatus.pending:
        return "text-blue-500";

      default:
        return "text-gray-500";
    }
  };

  const handleOrdersList = async (page: number) => {
    try {
      setLoading(true);
      const response = await paymentRequest(
        page,
        dateFilterValue?.start_date,
        dateFilterValue?.end_date
      );
      setData({
        current_page: response?.current_page,
        per_page: response?.per_page,
        total: response?.total,
        data: response?.data?.map((product: any) => {
          return {
            id: product?.id,
            key: product?.id,
            date: product?.created_at,
            status: product?.status,
          };
        }),
      });

      response?.meta?.current_page && setPage(response?.meta?.current_page);
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (page) {
      handleOrdersList(page);
    }
  }, [page, dateFilterValue]);

  const columns = [
    {
      title: (
        <span className="text-xs flex py-2 justify-start md:pl-10">
          Request ID
        </span>
      ),
      dataIndex: "id",
      key: "id_column",
      width: "60%",
      render: (text: string) => {
        return (
          <div className="flex font-medium md:pl-10 justify-start text-center">
            {text}
          </div>
        );
      },
    },
    {
      title: <span className="text-xs flex  justify-center">Date</span>,
      dataIndex: "date",
      key: "date",
      render: (text: string) => {
        return (
          <div className="flex font-medium text-center justify-center ">
            {moment.utc(text).format("DD/MM/YYYY")}
          </div>
        );
      },
    },

    {
      title: <span className="text-xs flex justify-center">Status</span>,
      dataIndex: "status",
      key: "status",
      render: (status: string) => {
        return (
          <div
            className={clsx(
              getColorForRequestStatus(
                RequestStatus[status as keyof typeof RequestStatus]
              ),
              "capitalize font-medium text-center  whitespace-nowrap flex justify-center  px-4 xl:px-0 xl:pl-5 2xl:pl-0"
            )}
          >
            {RequestStatus[status as keyof typeof RequestStatus]}
          </div>
        );
      },
    },
  ];

  const rowClassName = (_: any, index: number) => {
    return index % 2 === 0 ? "bg-[#131313]" : "bg-bgColor";
  };

  return (
    <div className="bg-readerLightGrayBg rounded-xl ">
      <div className="py-4 px-6 flex  gap-x-2 items-center">
        <p className="font-semibold text-base lg:text-lg whitespace-nowrap">
          Product Sales{" "}
        </p>
        <ShouldRender check={months || remainingDays}>
          <p className="text-textGrayColor">
            (for last{" "}
            <ShouldRender check={months}>{months} months</ShouldRender>{" "}
            <ShouldRender check={remainingDays}>
              {" "}
              {remainingDays} days
            </ShouldRender>
            )
          </p>
        </ShouldRender>
      </div>
      <div className="customScrollBar ">
        <Table
          dataSource={data?.data}
          loading={loading}
          className={`tableHeader  rounded-none customTable overflow-auto customScrollBar`}
          columns={columns}
          rowKey="key"
          pagination={{
            current: page,
            pageSize: data?.per_page,
            total: data?.total,
            onChange: (newPage) => {
              setPage(newPage);
            },
          }}
          rowClassName={rowClassName}
          locale={{
            emptyText: (
              <span>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>No Products</span>}
                  className={`h-40 flex justify-center items-center flex-col`}
                />
              </span>
            ),
          }}
        />
      </div>
    </div>
  );
};

export default PaymentRequestUser;
