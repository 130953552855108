import { atom } from "recoil";

export const dateFilter = atom<{ start_date: string; end_date: string }>({
  key: "dateFilter",
  default: { start_date: "", end_date: "" },
});

export const dateFilterCsv = atom<{ start_date: string; end_date: string }>({
  key: "dateFilterCsv",
  default: { start_date: "", end_date: "" },
});
