import { Endpoints } from "../../network";
import apiClient from "../base";

interface IProductList {
  category_id?: number;
  page?: number;
  start_date?: string;
  end_date?: string;
  book_type?: string;
  name?: string;
}

export const getOrdersList = async (
  page: number,
  start_date?: string,
  end_date?: string
) => {
  let url = `${Endpoints.getOrdersList}?page=${page}`;
  if (start_date && end_date) {
    url += `&start_date=${start_date}&end_date=${end_date}`;
  }

  const response = await apiClient.get(url);

  return response?.data?.data;
};

export const getAdminOrdersList = async (
  id: number,
  page: number,
  start_date?: string,
  end_date?: string
) => {
  let url = `${Endpoints.getAdminOrdersList}?page=${page}&user_id=${id}`;
  if (start_date && end_date) {
    url += `&start_date=${start_date}&end_date=${end_date}`;
  }

  const response = await apiClient.get(url);

  return response?.data?.data;
};

export const paymentRequest = async (
  page: number,
  start_date?: string,
  end_date?: string
) => {
  let url = `${Endpoints.paymentRequest}?page=${page}`;
  if (start_date && end_date) {
    url += `&start_date=${start_date}&end_date=${end_date}`;
  }

  const response = await apiClient.get(url);

  return response?.data?.data;
};

export const getProductSales = async (
  page: number,
  start_date?: string,
  end_date?: string
) => {
  let url = `${Endpoints.getProductSales}?page=${page}`;
  if (start_date && end_date) {
    url += `&start_date=${start_date}&end_date=${end_date}`;
  }

  const response = await apiClient.get(url);

  return response?.data;
};

export const getAdminProductSales = async (
  id: number,
  page: number,
  start_date?: string,
  end_date?: string
) => {
  let url = `${Endpoints.getProductSales}?page=${page}&user_id=${id}`;
  if (start_date && end_date) {
    url += `&start_date=${start_date}&end_date=${end_date}`;
  }

  const response = await apiClient.get(url);

  return response?.data;
};

export const getOrderSummary = async (
  start_date?: string,
  end_date?: string
) => {
  let url = Endpoints.getOrderSummary;
  if (start_date && end_date) {
    url += `?start_date=${start_date}&end_date=${end_date}`;
  }

  const response = await apiClient.get(url);

  return response?.data?.data;
};
export const getAdminOrderSummary = async (
  id: number,
  start_date?: string,
  end_date?: string
) => {
  let url = `${Endpoints.getAdminOrderSummary}`;
  url += `?user_id=${id}`;
  if (start_date && end_date) {
    url += `&start_date=${start_date}&end_date=${end_date}`;
  }

  const response = await apiClient.get(url);

  return response?.data?.data;
};

export const getUserRegistration = async (
  start_date?: string,
  end_date?: string
) => {
  let url = Endpoints.getUserRegistration;
  if (start_date && end_date) {
    url += `?start_date=${start_date}&end_date=${end_date}`;
  }

  const response = await apiClient.get(url);

  return response?.data?.data;
};

export const getAdminUserRegistration = async (
  id: number,
  start_date?: string,
  end_date?: string
) => {
  let url = Endpoints.getAdminUserRegistration;
  url += `?user_id=${id}`;
  if (start_date && end_date) {
    url += `&start_date=${start_date}&end_date=${end_date}`;
  }

  const response = await apiClient.get(url);

  return response?.data?.data;
};

export const getProductsList = async (data: IProductList) => {
  const { category_id, end_date, page = 1, start_date, book_type, name } = data;
  let url = Endpoints.getProductsList;

  const queryParams = [];

  if (page !== undefined && page !== null && page !== 0) {
    queryParams.push(`page=${page}`);
  }

  if (start_date && end_date) {
    queryParams.push(`start_date=${start_date}`);
    queryParams.push(`end_date=${end_date}`);
  }

  if (category_id) {
    queryParams.push(`category_id=${category_id}`);
  }

  if (book_type) {
    queryParams.push("book_type=hardcover");
  }

  if (name) {
    queryParams.push(`name=${name}`);
  }

  if (queryParams.length > 0) {
    url += `?${queryParams.join("&")}`;
  }

  const response = await apiClient.get(url);

  return response?.data;
};

export const getTotalEarning = async (
  start_date?: string,
  end_date?: string
) => {
  let url = Endpoints.getTotalEarning;
  if (start_date && end_date) {
    url += `?start_date=${start_date}&end_date=${end_date}`;
  }

  const response = await apiClient.get(url);

  return response?.data?.data;
};

export const getAdminTotalEarning = async (
  id: number,
  start_date?: string,
  end_date?: string
) => {
  let url = `${Endpoints.getAdminTotalEarning}`;
  url += `?user_id=${id}`;
  if (start_date && end_date) {
    url += `&start_date=${start_date}&end_date=${end_date}`;
  }

  const response = await apiClient.get(url);

  return response?.data?.data;
};

export const getCommission = async (start_date?: string, end_date?: string) => {
  let url = Endpoints.getCommission;
  if (start_date && end_date) {
    url += `?start_date=${start_date}&end_date=${end_date}`;
  }

  const response = await apiClient.get(url);

  return response?.data?.data;
};

export const getAdminCommission = async (
  id: number,
  start_date?: string,
  end_date?: string
) => {
  let url = `${Endpoints.getAdminCommission}`;
  url += `?user_id=${id}`;
  if (start_date && end_date) {
    url += `&start_date=${start_date}&end_date=${end_date}`;
  }

  const response = await apiClient.get(url);

  return response?.data?.data;
};

export const handleWithdraw = async () => {
  const response = await apiClient.post(Endpoints.withDrawAmount);

  return response?.data?.data;
};
