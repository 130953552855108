import React, { useEffect, useState } from "react";

import { useRecoilValue } from "recoil";

import { Empty, Select, Table } from "antd";
import calculateDateDifference from "../../../shared/basic/daysDifference";
import { dateFilter } from "../../../../atoms/dateFilter";
import extractErrorMessage from "../../../shared/basic/formateError";
import { notify } from "../../../shared/basic/notify";
import { ShouldRender } from "../../../shared/basic/ShouldRender";
import {
  getAdminPaymentRequest,
  handleStatusChange,
} from "../../../../services/Admin/dashboard";
import { IPaymentRequestsDataTable } from "../../../../utils/interfaces/Admin/dashboard";
import moment from "moment";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import FilterDropDown from "./FilterDropDown";
import { useNavigate, useSearchParams } from "react-router-dom";

const AdminPaymentRequest: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<{ value: string; id: string }>({
    value: "",
    id: "",
  });
  const [data, setData] = useState<IPaymentRequestsDataTable>();
  const [page, setPage] = useState<number>(1);
  const dateFilterValue = useRecoilValue(dateFilter);
  const [searchParams] = useSearchParams();

  const screens = useBreakpoint();
  const router = useNavigate();

  const { months, remainingDays } = calculateDateDifference(
    dateFilterValue?.start_date,
    dateFilterValue?.end_date
  );

  const handleAdminPaymentRequest = async ({
    page,
    filter,
  }: {
    page?: number;
    filter?: string;
  }) => {
    try {
      setLoading(true);
      const response = await getAdminPaymentRequest({
        page: page,
        start_date: dateFilterValue?.start_date,
        end_date: dateFilterValue?.end_date,
        filter: filter,
      });

      setData({
        current_page: response?.current_page,
        per_page: response?.per_page,
        total: response?.total,
        data: response?.data?.map((request: any) => {
          return {
            name: request?.user?.name,
            date: request?.updated_at,
            id: request?.id,
            key: request?.id,
            status: request?.status,
            affiliate_user_id: request?.id,
          };
        }),
      });
      response?.meta?.current_page && setPage(response?.meta?.current_page);
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (page) {
      handleAdminPaymentRequest({ page: page });
    }
  }, [page, dateFilterValue]);

  const handleUpdateStatus = async (
    id: number | null,
    status: string | null
  ) => {
    try {
      const response = await handleStatusChange(id as number, status as string);

      if (response) {
        notify("Status Updated successfully!", "success");
      }
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    }
  };
  const handleConfirm = (selectedValue: string) => {
    handleAdminPaymentRequest({ filter: selectedValue });
  };
  const columns = [
    {
      title: (
        <span className="text-xs flex py-2 justify-start lg:md:pl-10">
          Name
        </span>
      ),
      dataIndex: "name",
      key: "name_column",
      width: screens?.lg ? "40%" : "",
      render: (text: string) => {
        return (
          <div className="flex lg:md:pl-10 font-medium whitespace-nowrap justify-start text-center">
            {text}
          </div>
        );
      },
    },
    {
      title: <span className="text-xs flex  justify-center">Date</span>,
      dataIndex: "date",
      key: "date_column",
      width: screens?.lg ? "15%" : "",
      render: (text: string) => {
        return (
          <div className="flex font-medium text-center justify-center ">
            {moment.utc(text).format("DD/MM/YYYY")}
          </div>
        );
      },
    },
    {
      title: (
        <span className="text-xs flex  justify-center text-center">
          Payment Request ID
        </span>
      ),
      dataIndex: "id",
      width: screens?.lg ? "15%" : "",
      key: "id_column",
      render: (text: string) => {
        return (
          <div className="flex font-medium justify-center min-w-28">{text}</div>
        );
      },
    },
    {
      title: <span className="text-xs flex justify-center">Status</span>,
      key: "status",
      filters: [
        { value: "pending", text: "Pending" },
        { value: "approved", text: "Approved" },
        { value: "in_process", text: "In-Process" },
      ],

      width: screens?.lg ? "15%" : "",
      filterDropdown: (props: any) => (
        <FilterDropDown
          {...props}
          confirm={() => handleConfirm(props?.selectedKeys)}
          clearFilters={() => handleConfirm(props?.selectedKeys)}
        />
      ),

      render: (record: any) => {
        return (
          <div className="flex justify-center w-full items-center lg:">
            <Select
              placeholder="Change Status"
              value={
                status.value && status.id === record?.id
                  ? status.value
                  : record?.status
              }
              onChange={(value) => {
                setStatus({ value: value, id: record?.id });
                handleUpdateStatus(record?.id, value);
              }}
              style={{ width: "100%" }}
              options={[
                { value: "pending", label: "Pending" },
                { value: "approved", label: "Approved" },
                { value: "rejected", label: "Rejected" },
              ]}
            />
          </div>
        );
      },
    },
    {
      title: <span className="text-xs flex justify-center">Action</span>,

      key: "Action",
      width: screens?.lg ? "15%" : "",
      render: (record: any) => {
        return (
          <ShouldRender check={record?.status !== "completed"}>
            <div
              onClick={() =>
                router(
                  `/dashboard/${record?.id}?tab=${searchParams.get("tab")}`
                )
              }
              className="flex gap-x-4 underline cursor-pointer hover:text-primary justify-center lg:"
            >
              View
            </div>
          </ShouldRender>
        );
      },
    },
  ];

  const rowClassName = (_: any, index: number) => {
    return index % 2 === 0 ? "bg-[#131313]" : "bg-bgColor";
  };

  return (
    <div className="bg-readerLightGrayBg rounded-xl ">
      <div className="py-4 px-6 flex  gap-x-2 items-center">
        <p className="font-semibold text-base lg:text-lg whitespace-nowrap">
          Payment Request{" "}
        </p>
        <ShouldRender check={months || remainingDays}>
          <p className="text-textGrayColor">
            (for last{" "}
            <ShouldRender check={months}>{months} months</ShouldRender>{" "}
            <ShouldRender check={remainingDays}>
              {" "}
              {remainingDays} days
            </ShouldRender>
            )
          </p>
        </ShouldRender>
      </div>
      <div className="customScrollBar ">
        <Table
          dataSource={data?.data}
          loading={loading}
          className={`tableHeader  rounded-none customTable overflow-auto customScrollBar`}
          columns={columns}
          rowKey="key"
          pagination={{
            current: page,
            pageSize: data?.per_page,
            total: data?.total,
            onChange: (newPage) => {
              newPage > 0 && setPage(newPage);
            },
          }}
          rowClassName={rowClassName}
          locale={{
            emptyText: (
              <span>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>No Requests</span>}
                  className={`h-40 flex justify-center items-center flex-col`}
                />
              </span>
            ),
          }}
          scroll={
            data?.data?.length ? { x: "max-content", y: "100vh" } : undefined
          }
        />
      </div>
    </div>
  );
};

export default AdminPaymentRequest;
