import { XIcon } from "@heroicons/react/outline";
import { Input, Modal, Select } from "antd";

import React, { useState } from "react";

import clsx from "clsx";

import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import ButtonDefault, { ButtonVariants } from "../../shared/basic/button";
import { paymentMethordData as paymentMethodData } from "../../../utils/data";
import { handlePayment } from "../../../services/Admin/dashboard";
import extractErrorMessage from "../../shared/basic/formateError";
import { notify } from "../../shared/basic/notify";
import { useNavigate } from "react-router-dom";
import { formatIndianCurrency } from "../../shared/formatIndianCurrency";

interface Props {
  visible: boolean;
  data:
    | {
        affiliateName: string;
        paymentRequestID: string;
        bankAccountNo: number;
        ifscCode: string;
        totalAmount: number;
        tdsDeduction: number;
        amountToPay: number;
        order_ids: React.Key[];
        affiliate_user_id: number;
        affiliate_settlement_request_id: string;
      }
    | undefined;
  closeModal: () => void;
}

const PaymentModalSummary: React.FC<Props> = ({
  closeModal,
  visible,
  data,
}) => {
  const screens = useBreakpoint();
  const router = useNavigate();
  const [formData, setFormData] = useState({
    transactionID: "",
    method: "",
    remark: "",
  });

  const [errors, setErrors] = useState({
    transactionID: "",
    method: "",
    remark: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validation
    const validationErrors = {
      transactionID: "",
      method: "",
      remark: "",
    };
    if (!formData.transactionID) {
      validationErrors.transactionID = "Transaction ID  is required";
    }

    if (!formData.method) {
      validationErrors.method = "Method  is required";
    }

    setErrors(validationErrors);

    if (Object.values(validationErrors).every((error) => !error)) {
      try {
        const response = await handlePayment({
          affiliate_user_id: data?.affiliate_user_id as number,
          amount: data?.amountToPay as number,
          mode: formData?.method,
          order_ids: data?.order_ids as React.Key[],
          payment_id: formData?.transactionID,
          remark: formData?.remark,
          affiliate_settlement_request_id:
            data?.affiliate_settlement_request_id as string,
        });
        router(`/dashboard/paymentHistory/${response?.id}`);
        notify("Payment completed successfully!", "success");
      } catch (error: any) {
        const message = extractErrorMessage(error);
        notify(message, "error");
      }
    }
  };

  const handleSelectChange = (value: string, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  return (
    <Modal
      open={visible}
      maskClosable={false}
      closable={false}
      centered={true}
      footer={null}
      mask={true}
      width={550}
      className="customModalPaddingZero"
      style={
        !screens?.md
          ? { top: 0, left: 0, right: 0, bottom: 0, margin: 0, padding: 0 }
          : {}
      }
      onCancel={closeModal}
    >
      <div
        className={clsx(
          !screens?.md && "min-h-[90vh] overflow-hidden justify-between",
          "flex flex-col gap-3 "
        )}
      >
        <div className="flex justify-between items-center px-8 py-4">
          <span className="flex flex-col">
            <p className="text-xl font-medium">Payment summary </p>
            <p className="font-thin text-textGrayColor">Payment Request ID</p>
          </span>
          <span
            onClick={closeModal}
            className="bg-[#303030] cursor-pointer w-min h-min group flex justify-center items-center rounded-full p-1"
          >
            <XIcon className="w-4 h-4 group-hover:text-textGrayColor text-[#9A9A9A]" />
          </span>
        </div>
      </div>

      <div
        className={clsx(
          !screens?.md && " fixed bottom-0 left-0 bg-[#222222]",
          "flex w-full gap-x-3.5 border-t-2 border-t-[#303030] py-2 "
        )}
      >
        <div className="flex flex-col w-full">
          <div className="py-5">
            <form className="flex flex-col  px-8 gap-2 w-full">
              <div className="flex gap-x-4 w-full">
                <Input
                  type="text"
                  name="transactionID"
                  placeholder="Enter Transaction ID "
                  style={{
                    backgroundColor: "#080808",
                  }}
                  value={formData?.transactionID}
                  onChange={handleChange}
                  size="large"
                  className="border text-sm   placeholder:text-[#7c7c7c] rounded-lg border-none DarkGray  py-2  text-white  customInputBlack makeBgBlack"
                />
                {errors.transactionID && (
                  <p className="text-red-500">{errors.transactionID}</p>
                )}
                <Select
                  style={{ height: 40, width: "100%" }}
                  dropdownStyle={{
                    backgroundColor: "#080808",
                    color: "#ffffff",
                  }}
                  showSearch
                  value={formData.method}
                  placeholder="Select Method"
                  onChange={(value) => handleSelectChange(value, "method")}
                  options={paymentMethodData}
                  className="rounded-xl border border-bgDarkGray  antdSelect antdSelectDropdown"
                />{" "}
                {errors.method && (
                  <p className="text-red-500">{errors.method}</p>
                )}
              </div>
              <Input.TextArea
                name="remark"
                style={{
                  backgroundColor: "#080808",
                }}
                placeholder="Type remark"
                value={formData?.remark}
                onChange={handleChange}
                rows={4}
                size="large"
                className="border-none focus:ring-0 text-sm placeholder:text-[#7C7C7C] rounded-xl px-4 border-bgDarkGray customInput py-3  text-textDarkGrayColor bg-backgroundGray"
              />
              {errors.remark && <p className="text-red-500">{errors.remark}</p>}
            </form>
            <div className="flex flex-col gap-y-2  mt-4">
              <span className="flex gap-y-1 flex-col px-8">
                <p className="text-sm text-primary mb-1">Personal Details</p>
                <span className="flex justify-between">
                  <p>Associate Name</p>
                  <p>{data?.affiliateName}</p>
                </span>
                <span className="flex justify-between">
                  <p>Payment Request ID</p>
                  <p>{data?.paymentRequestID}</p>
                </span>
                <span className="flex justify-between">
                  <p>Bank Account No</p>
                  <p>{data?.bankAccountNo}</p>
                </span>
                <span className="flex justify-between">
                  <p>IFSC Code</p>
                  <p>{data?.ifscCode}</p>
                </span>
              </span>
              <span className="flex gap-y-1 flex-col px-8">
                <p className="text-sm text-primary mb-1">Amount Details</p>
                <span className="flex justify-between">
                  <p>Total Amount</p>
                  <p className="flex whitespace-nowrap">
                    ₹ {formatIndianCurrency(data?.totalAmount as number)}
                  </p>
                </span>

                <span className="flex justify-between">
                  <p>TDS deduction</p>
                  <p className="flex whitespace-nowrap">
                    - ₹ {data?.tdsDeduction}
                  </p>
                </span>
                <span className="flex justify-between mt-2">
                  <p>Amount to Pay</p>
                  <p className="flex whitespace-nowrap text-xl font-semibold">
                    ₹ {formatIndianCurrency(data?.amountToPay as number)}
                  </p>
                </span>
              </span>
            </div>
          </div>
          <div className=" border-t-2  border-t-[#303030] py-4">
            <div className="flex w-full gap-x-3.5 px-8">
              <ButtonDefault
                size={1}
                variant={ButtonVariants.GRAY}
                roundedSm={true}
                className="w-1/3 p-1 "
                onClick={closeModal}
              >
                <span className="flex text-xs  gap-x-2 px-4 font-medium whitespace-nowrap  ">
                  Cancel
                </span>
              </ButtonDefault>
              <ButtonDefault
                size={1}
                variant={ButtonVariants.UNSTYLED}
                roundedSm={true}
                onClick={(e) => handleSubmit(e)}
                className="w-2/3 p-1  disabled:cursor-not-allowed rounded bg-fadedWhite hover:bg-gray-100 text-black disabled:bg-dropDownMenuBg disabled:text-[#3E3E3E] disabled:border-0"
              >
                <span className="flex text-sm font-bold  gap-x-2 px-4  py-1 whitespace-nowrap  ">
                  Pay ₹{data?.amountToPay}/-
                </span>
              </ButtonDefault>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PaymentModalSummary;
