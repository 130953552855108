import { Empty, Table } from "antd";
import React, { useEffect, useState } from "react";
import {
  getAdminOrdersList,
  getOrdersList,
} from "../../../../services/dashboard";
import extractErrorMessage from "../../../shared/basic/formateError";
import { notify } from "../../../shared/basic/notify";
import { IOrderListData } from "../../../../utils/interfaces/dashboard";
import { OrderStatus } from "../../../../utils/data";
import clsx from "clsx";
import moment from "moment";
import { dateFilter } from "../../../../atoms/dateFilter";
import { useRecoilValue } from "recoil";
import calculateDateDifference from "../../../shared/basic/daysDifference";
import { ShouldRender } from "../../../shared/basic/ShouldRender";
import { formatIndianCurrency } from "../../../shared/formatIndianCurrency";
import { useParams } from "react-router-dom";

interface IEarningDataTableProps {
  maxHeight: number;
}

const EarningDataTable: React.FC<IEarningDataTableProps> = ({
  maxHeight = 0,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IOrderListData>();
  const [page, setPage] = useState<number>(1);
  const dateFilterValue = useRecoilValue(dateFilter);
  const { id } = useParams();

  const { months, remainingDays } = calculateDateDifference(
    dateFilterValue?.start_date,
    dateFilterValue?.end_date
  );

  const handleOrdersList = async (page: number) => {
    try {
      setLoading(true);
      let response;
      if (id) {
        response = await getAdminOrdersList(
          +id,
          page,
          dateFilterValue?.start_date,
          dateFilterValue?.end_date
        );
      } else {
        response = await getOrdersList(
          page,
          dateFilterValue?.start_date,
          dateFilterValue?.end_date
        );
      }

      setData({
        current_page: response?.current_page,
        per_page: response?.per_page,
        total: response?.total,
        data: response?.data?.map((order: any) => {
          return {
            id: order?.order_id,
            key: order?.order_id,
            date: order?.updated_at,
            commission: order?.commission_percentage,
            orderStatus: order?.order_status,
            amount: order?.net_amount,
            payment_status: order?.payment_status,
            sale: order?.grand_total,
          };
        }),
      });
      setPage(response?.current_page);
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleOrdersList(page);
  }, [page, dateFilterValue]);

  const getColorForStatus = (status: string) => {
    switch (status) {
      case OrderStatus.refunded:
        return "text-red-500";
      case OrderStatus.cancelled:
        return "text-yellow-500";
      case OrderStatus.pending:
        return "text-blue-500";
      case OrderStatus.completed:
        return "text-green-500";
      case OrderStatus.delivered:
        return "text-green-500";
      case OrderStatus.DELIVERED:
        return "text-green-500";
      case OrderStatus.CANCELED:
        return "text-red-500";
      default:
        return "text-gray-500";
    }
  };

  const columns = [
    Table.SELECTION_COLUMN,
    {
      title: <span className="text-xs flex py-2 justify-center">Order ID</span>,
      dataIndex: "id",
      key: "id_column",
      render: (text: string) => {
        return (
          <div className="flex font-medium justify-center text-center">
            {text}
          </div>
        );
      },
    },
    {
      title: <span className="text-xs flex  justify-center">Date</span>,
      dataIndex: "date",
      width: "8%",
      key: "date_column",
      render: (text: string) => {
        return (
          <div className="flex font-medium text-center justify-center">
            {moment.utc(text).format("DD/MM/YYYY")}
          </div>
        );
      },
    },
    {
      title: (
        <span className="text-xs flex justify-center text-center">
          Commission %
        </span>
      ),
      dataIndex: "commission",
      key: "commission_column",
      render: (text: string) => {
        return (
          <div className="flex font-medium justify-center px-4">{text}%</div>
        );
      },
    },
    {
      title: (
        <span className="text-xs flex justify-center text-center">Sales</span>
      ),
      dataIndex: "sale",
      key: "sale_column",
      render: (text: string) => {
        return (
          <div className="flex font-medium capitalize justify-center whitespace-nowrap xl:pl-5 2xl:pl-0">
            ₹ {text}
          </div>
        );
      },
    },
    {
      title: <span className="text-xs flex justify-center">Order Status</span>,
      dataIndex: "orderStatus",
      key: "orderStatus_column",
      render: (text: string) => {
        return (
          <div
            className={clsx(
              getColorForStatus(text),
              "capitalize font-medium text-center xl:pl-5 2xl:pl-0"
            )}
          >
            {text}
          </div>
        );
      },
    },
    // {
    //   title: (
    //     <span className="text-xs flex justify-center">Payment Status</span>
    //   ),
    //   dataIndex: "payment_status",
    //   key: "payment_status_column",
    //   render: (text: string) => {
    //     return (
    //       <div
    //         className={clsx(
    //           getColorForPaymentStatus(Status[text as keyof typeof Status]),
    //           "capitalize font-medium text-center  whitespace-nowrap flex justify-center  px-4 xl:px-0 xl:pl-5 2xl:pl-0"
    //         )}
    //       >
    //         {Status[text as keyof typeof Status]}
    //       </div>
    //     );
    //   },
    // },
    {
      title: (
        <span className="text-xs flex justify-center">Commission ( ₹ )</span>
      ),
      dataIndex: "amount",
      key: "amount_column",
      render: (text: string) => {
        return (
          <div className="flex font-medium capitalize justify-center px-4 whitespace-nowrap">
            ₹ {formatIndianCurrency(+text)}
          </div>
        );
      },
    },
  ];

  const rowClassName = (_: any, index: number) => {
    return index % 2 === 0 ? "bg-[#131313]" : "bg-bgColor";
  };

  return (
    <div className="bg-readerLightGrayBg rounded-xl ">
      <div className="py-4 px-6 flex  gap-x-2 items-center">
        <p className="font-semibold text-base lg:text-lg whitespace-nowrap">
          Earnings Overview{" "}
        </p>
        <ShouldRender check={months || remainingDays}>
          <p className="text-textGrayColor">
            (for last{" "}
            <ShouldRender check={months}>{months} months</ShouldRender>{" "}
            <ShouldRender check={remainingDays}>
              {" "}
              {remainingDays} days
            </ShouldRender>
            )
          </p>
        </ShouldRender>
      </div>
      <div className="customScrollBar ">
        <Table
          dataSource={data?.data}
          loading={loading}
          className={`tableHeader  rounded-none customTable overflow-auto customScrollBar`}
          columns={columns}
          rowKey="key"
          pagination={{
            current: page,
            pageSize: data?.per_page,
            total: data?.total,
            onChange: (newPage) => {
              setPage(newPage);
            },
          }}
          rowClassName={rowClassName}
          locale={{
            emptyText: (
              <span>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>No Orders</span>}
                  className={`h-40 flex justify-center items-center flex-col`}
                />
              </span>
            ),
          }}
          scroll={
            data?.data.length
              ? { x: "max-content", y: maxHeight + 85 }
              : undefined
          }
        />
      </div>
    </div>
  );
};

export default EarningDataTable;
