import React from "react";
import { Button, Radio } from "antd";
import { FilterDropdownProps } from "antd/lib/table/interface"; // Adjust the import path as needed

export interface StatusFilter {
  value: string;
  text: string;
}

const statusFilters: StatusFilter[] = [
  { value: "pending", text: "Pending" },
  { value: "approved", text: "Approved" },
  { value: "in_process", text: "In-Process" },
];

const FilterDropDown: React.FC<FilterDropdownProps> = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  close,
}) => (
  <div className="flex flex-col" style={{ padding: 8 }}>
    <Radio.Group
      className="flex flex-col "
      onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
      value={
        selectedKeys && selectedKeys.length > 0 ? selectedKeys[0] : undefined
      }
    >
      {statusFilters?.map((filter) => (
        <Radio className="p-1" key={filter.value} value={filter.value}>
          {filter.text}
        </Radio>
      ))}
    </Radio.Group>
    <div style={{ marginTop: 8 }}>
      <Button
        type="primary"
        onClick={() => {
          confirm();
          close();
        }}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        OK
      </Button>
      <Button
        onClick={() => {
          clearFilters && clearFilters();
          setSelectedKeys([]);
          close();
        }}
        size="small"
        style={{ width: 90 }}
      >
        Reset
      </Button>
    </div>
  </div>
);

export default FilterDropDown;
