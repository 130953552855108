export const countryList = [
  { name: "United States", code: "+1" },
  { name: "United Kingdom", code: "+44" },
  { name: "Canada", code: "+1" },
  { name: "Australia", code: "+61" },
  { name: "India", code: "+91" },
  { name: "Germany", code: "+49" },
  { name: "France", code: "+33" },
  { name: "Japan", code: "+81" },
  { name: "Brazil", code: "+55" },
  { name: "China", code: "+86" },
  { name: "Russia", code: "+7" },
  { name: "South Korea", code: "+82" },
  { name: "Mexico", code: "+52" },
  { name: "South Africa", code: "+27" },
  { name: "Nigeria", code: "+234" },
  { name: "Spain", code: "+34" },
  { name: "Italy", code: "+39" },
  { name: "Sweden", code: "+46" },
  { name: "Norway", code: "+47" },
  { name: "Switzerland", code: "+41" },
  { name: "New Zealand", code: "+64" },
  { name: "Greece", code: "+30" },
  { name: "Argentina", code: "+54" },
  { name: "Turkey", code: "+90" },
  { name: "Egypt", code: "+20" },
  { name: "Singapore", code: "+65" },
  { name: "Malaysia", code: "+60" },
  { name: "Thailand", code: "+66" },
  { name: "Vietnam", code: "+84" },
  { name: "Philippines", code: "+63" },
];

export const letterColors = {
  A: "#FF5733",
  B: "#33FF57",
  C: "#5733FF",
  D: "#FF33F6",
  E: "#33F6FF",
  F: "#FF5733",
  G: "#33FF57",
  H: "#5733FF",
  I: "#FF33F6",
  J: "#33F6FF",
  K: "#FF5733",
  L: "#33FF57",
  M: "#5733FF",
  N: "#FF33F6",
  O: "#33F6FF",
  P: "#FF5733",
  Q: "#33FF57",
  R: "#5733FF",
  S: "#FF33F6",
  T: "#33F6FF",
  U: "#FF5733",
  V: "#33FF57",
  W: "#5733FF",
  X: "#FF33F6",
  Y: "#33F6FF",
  Z: "#FF5733",
};

export const EarningDataTableData = [
  {
    id: 12313,
    date: "23/2/32",
    commission: 3,
    orderStatus: "completed",
    amount: 65400,
  },
  {
    id: 12311,
    date: "23/2/12",
    commission: 5,
    orderStatus: "pending",
    amount: 65402,
  },

  {
    id: 32311,
    date: "23/2/15",
    commission: 2,
    orderStatus: "cancelled",
    amount: 6542,
  },
  {
    id: 32411,
    date: "23/2/35",
    commission: 19,
    orderStatus: "refunded",
    amount: 652,
  },
  {
    id: 22411,
    date: "23/2/35",
    commission: 19,
    orderStatus: "refunded",
    amount: 652,
  },
  {
    id: 3241,
    date: "23/2/35",
    commission: 19,
    orderStatus: "refunded",
    amount: 652,
  },
  {
    id: 32211,
    date: "23/2/35",
    commission: 19,
    orderStatus: "refunded",
    amount: 652,
  },
  {
    id: 12411,
    date: "23/2/35",
    commission: 19,
    orderStatus: "refunded",
    amount: 652,
  },
];

export enum OrderStatus {
  refunded = "refunded",
  cancelled = "cancelled",
  pending = "pending",
  completed = "completed",
  delivered = "delivered",
  CANCELED = "CANCELED",
  DELIVERED = "DELIVERED",
}

export const DummyStatus = {
  delivered: 0,
  pending: 0,
  CANCELED: 0,
  refunded: 0,
};

export const onBoardingStepsHeadingData: {
  [key: number]: { heading: string; subheading: string };
} = {
  1: {
    heading: "Where do you plan to promote brands?",
    subheading: "Add all the relevant channels",
  },
  2: {
    heading: "Enter your bank details to receive payments",
    subheading: "Please check details",
  },
};

export const paymentMethordData = [
  { value: "Debit Card", label: "Debit Card" },
  { value: "Credit Card", label: "Credit Card" },
  { value: "UPI", label: "Unified Payments Interface (UPI)" },
  { value: "Mobile Wallets", label: "Mobile Wallets" },
  { value: "Net Banking", label: "Net Banking" },
  { value: "BHIM", label: "BHIM (Bharat Interface for Money)" },
  { value: "RTGS", label: "RTGS (Real-Time Gross Settlement)" },
  { value: "NEFT", label: "NEFT (National Electronic Funds Transfer)" },
  { value: "IMPS", label: "IMPS (Immediate Payment Service)" },
  { value: "AEPS", label: "Aadhaar Enabled Payment System (AEPS)" },
];
