export enum Roles {
  SUPER_ADMIN = "Super Admin",
  CUSTOMER = "customer",
  AFFILIATE = "affiliate",
  MANAGER = "manager",
  AFFILIATE_MANAGER = "affiliate-manager",
  API_USER = "api-user",
  SUPPORT = "support",
  FINANCE = "finance",
  ADMIN = "Admin",
}
