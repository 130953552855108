export interface IOrderListData {
  current_page: number;
  per_page: number;
  total: number;
  data: {
    id: number;
    date: string;
    commission: string;
    orderStatus: string;
    amount: string;
  }[];
}

export interface IProductListDataTable {
  current_page: number;
  per_page: number;
  total: number;
  data: {
    name: string;
    type: string;
    commission: string;
    quantity: string;
  }[];
}

export interface IRequestPaymentDataTable {
  current_page: number;
  per_page: number;
  total: number;
  data: {
    id: string;
    date: string;
    status: string;
  }[];
}

export interface IOrderSummaryData {
  total_earnings: string;
  total_registration: string;
  total_click: string;
  total_orders: string;
  total_purchases: string;
  total_tds: string;
}

export enum OrderSummary {
  total_earnings = "Total Earnings",
  total_registration = "Total Registration",
  total_click = "Total Click",
  total_orders = "Total Orders",
  total_purchases = "Total Purchases",
  total_tds = "Total TDS",
}

export interface IProductListData {
  current_page: number;
  per_page: number;
  total: number;
  products: {
    image: string;
    title: number;
    id: number;
    desc?: string;
    externalLink?: string;
    slug?: string;
  }[];
}
