import { Divider, Input, Progress } from "antd";
import clsx from "clsx";

import React, { useState } from "react";

import ButtonDefault, { ButtonVariants } from "../../shared/basic/button";
import { ShouldRender } from "../../shared/basic/ShouldRender";
import CustomIcon from "../../../assets/Icons/User";
import PasswordIcon from "../../../assets/Icons/password";
import EmailIcon from "../../../assets/Icons/Mail";
import { useSignUp } from "../../../hooks/useAuth/useSignUp";
import AuthLayout from "../AuthLayout";
import { useNavigate } from "react-router-dom";
import { AppleLogo, GoogleIcon } from "../../../assets";
import appwriteService from "../../../config/appwrite";
import { useRecoilState } from "recoil";
import { authModalControl } from "../../../atoms/toggleTheme/authModalControl";
import { handleShowFields } from "../../../atoms/showFields";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

const SignUp: React.FC = () => {
  const { onSignUp, loading } = useSignUp();

  const [passwordStrength, setPasswordStrength] = useState<number>(0);

  const [formData, setFormData] = useState({
    fullname: "",
    username: "",
    email: "",
    phone: "",
    password: "",
    retryPassword: "",
  });

  const [errors, setErrors] = useState({
    fullname: "",
    username: "",
    email: "",
    // phone: "",
    password: "",
    retryPassword: "",
  });
  const [, setVisible] = useRecoilState(authModalControl);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [appleLoading, setAppleLoading] = useState(false);
  const [showFields, setShowFields] = useRecoilState(handleShowFields);

  const showAppleLogin = process.env.REACT_APP_SHOW_APPLE_LOGIN === "true";
  const screens = useBreakpoint();
  const router = useNavigate();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name === "_email" ? "email" : name === "_password" ? "password" : name]:
        value,
    });
    if (name === "_password") {
      const strength = calculatePasswordStrength(value);
      setPasswordStrength(strength);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Validation
    const validationErrors = {
      fullname: "",
      username: "",
      email: "",
      // phone: "",
      password: "",
      retryPassword: "",
    };
    if (!formData.email) {
      validationErrors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      validationErrors.email = "Invalid email";
    }
    if (!formData.fullname) {
      validationErrors.fullname = "Full Name is required";
    }
    // if (!formData.phone) {
    //   validationErrors.phone = "Phone number is required";
    // } else if (!isValidPhone(formData.phone)) {
    //   validationErrors.phone = "Enter Valid 10 digit number";
    // }
    if (!formData.password) {
      validationErrors.password = "Password is required";
    } else if (formData.password.length < 8) {
      validationErrors.password = "Password must be at least 8 characters long";
    }
    // if (!formData.username) {
    //   validationErrors.username = 'Username is required';
    // }

    setErrors(validationErrors);

    if (Object.values(validationErrors).every((error) => !error)) {
      const signUpData = {
        email: formData.email,
        password: formData.password,
        name: formData.fullname,
        phone: formData.phone,
      };

      onSignUp(signUpData);
    }
  };

  const calculatePasswordStrength = (password: string) => {
    let strength = 0;

    // Check password length
    if (password.length >= 8) {
      strength += 1;
    }

    // Check for uppercase letters
    if (/[A-Z]/.test(password)) {
      strength += 1;
    }

    // Check for lowercase letters
    if (/[a-z]/.test(password)) {
      strength += 1;
    }

    // Check for at least one digit
    if (/\d/.test(password)) {
      strength += 1;
    }

    // Check for special characters
    if (/[\W_]/.test(password)) {
      strength += 1;
    }

    return strength;
  };

  const getStrengthText = () => {
    switch (passwordStrength) {
      case 1:
        return "Too Weak";
      case 2:
        return "Weak";
      case 3:
        return "Moderate";
      case 4:
        return "Strong";
      case 5:
        return "Very Strong";
      default:
        return "";
    }
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const handleGoogleLogin = async () => {
    setGoogleLoading(true);
    try {
      await appwriteService.loginWithGoogle();
    } catch (error) {
      console.error("Error logging in with Google:", error);
    } finally {
      setGoogleLoading(false);
      setVisible((prevState) => ({
        ...prevState,
        login: false,
        signUp: false,
      }));
    }
  };

  const handleAppleLogin = async () => {
    setAppleLoading(true);
    try {
      await appwriteService.loginWithApple();
    } catch (error) {
      console.error("Error logging in with Google:", error);
    } finally {
      setAppleLoading(false);
      setVisible((prevState) => ({
        ...prevState,
        login: false,
        signUp: false,
      }));
    }
  };

  return (
    <AuthLayout setShowFields={setShowFields} showFields={showFields}>
      <div className="flex flex-1 min-h-screen   flex-col justify-center px-4  sm:px-6 lg:flex-none lg:px-12">
        <div className="mx-auto w-full  ">
          <div className="flex items-center gap-y-10 flex-col scroll-px-0.5  md:px-10 justify-center">
            <div className={` z-30  flex flex-col gap-y-1 w-full pb-5`}>
              <div className="border-b-2 border-backgroundGray py-3" />
              <div className={clsx("py-3 flex flex-col  ")}>
                <ShouldRender check={!screens?.lg}>
                  <div
                    onClick={() => setShowFields(!showFields)}
                    className="flex group items-center text-textGrayColor gap-x-2 cursor-pointer hover:underline"
                  >
                    <ChevronLeftIcon className=" w-4 h-4" />{" "}
                    <p className="group-hover:underline">Back</p>
                  </div>
                </ShouldRender>
                <h1 className="text-xl flex justify-start gap-x-2 font-medium lg:font-semibold lg:text-2xl mt-1 leading-10 text-fadedWhite text-center w-full">
                  Create account
                </h1>
                <span className="text-sm lg:text-base text-textDarkGrayColor  flex justify-start">
                  Sign up or Create your account from here
                </span>
              </div>

              <form
                // onSubmit={handleSubmit}
                className="flex flex-col mt-2 gap-y-3"
              >
                <div className="col-span-1 flex-col flex">
                  <Input
                    name="fullname"
                    value={formData.fullname}
                    placeholder="Full Name"
                    prefix={
                      <span className="pr-2">
                        <CustomIcon filled={true} />
                      </span>
                    }
                    onChange={handleChange}
                    style={{
                      backgroundColor: "#141414",
                    }}
                    size="large"
                    autoComplete="off"
                    className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white  customInputBlack makeBgBlack"
                  />
                  {errors.fullname && (
                    <p className="text-red-500">{errors.fullname}</p>
                  )}
                </div>
                <div className="col-span-1 flex-col flex gap-y-.5">
                  <Input
                    type="text"
                    name="_email"
                    autoFocus={false}
                    autoComplete="new-password"
                    value={formData.email}
                    style={{
                      backgroundColor: "#141414",
                    }}
                    placeholder="Email"
                    prefix={
                      <span className="pr-2">
                        <EmailIcon filled={true} />
                      </span>
                    }
                    onChange={handleChange}
                    size="large"
                    className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
                  />
                  {errors.email && (
                    <p className="text-red-500">{errors.email}</p>
                  )}
                </div>
                <div className="col-span-1 flex-col flex ">
                  <Input.Password
                    name="_password"
                    placeholder="Password"
                    value={formData.password}
                    style={{
                      backgroundColor: "#141414",
                    }}
                    prefix={
                      <span className="pr-2">
                        <PasswordIcon />
                      </span>
                    }
                    onChange={handleChange}
                    size="large"
                    autoComplete="new-password"
                    className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
                  />
                  {errors.password && (
                    <p className="text-red-500">{errors.password}</p>
                  )}
                  <ShouldRender check={formData.password}>
                    {passwordStrength !== null && (
                      <span className="relative">
                        <Progress
                          percent={(passwordStrength / 5) * 100}
                          showInfo={false}
                          status={
                            passwordStrength >= 3 ? "success" : "exception"
                          }
                        />
                        <p
                          className={`text-sm text-${
                            passwordStrength >= 3 ? "green" : "red"
                          }-500 `}
                        >
                          {getStrengthText()}
                        </p>
                      </span>
                    )}
                  </ShouldRender>

                  {/* <span
                onClick={() => router.push('/auth/forgot-password')}
                className='font-medium text-primary mt-1 cursor-pointer hover:text-darkPrimary'
              >
                Forgot password?
              </span> */}
                </div>
                {/* <div className="col-span-1 flex-col flex">
                  <Input
                    name="phone"
                    value={formData.phone}
                    placeholder="Phone Number"
                    style={{
                      backgroundColor: "#141414",
                    }}
                    prefix={
                      <span className="pr-2">
                        <CustomIcon filled={true} />
                      </span>
                    }
                    onChange={handlePhoneInput}
                    size="large"
                    autoComplete="off"
                    className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
                  />
                  {errors.phone && (
                    <p className="text-red-500">{errors.phone}</p>
                  )}
                </div> */}
                <span className="flex justify-center mt-2">
                  <ButtonDefault
                    size={4}
                    variant={ButtonVariants.WHITE}
                    className="p-1 w-full"
                    disabled={loading}
                    loading={loading}
                    onClick={(e) => handleSubmit(e)}
                  >
                    <span className="flex text-sm font-bold gap-x-2 px-4 py-1 whitespace-nowrap">
                      Sign up
                    </span>
                  </ButtonDefault>
                </span>

                <span className="text-center font-medium flex text-white justify-center text-base pt-4">
                  Already have an account?
                  <p
                    onClick={() => {
                      router("/");
                    }}
                    className="text-primary  px-1 hover:font-bold hover:underline cursor-pointer transition-all duration-300 ease-in-out"
                  >
                    Log in
                  </p>
                </span>

                <span className="flex justify-center">
                  <span className="text-center text-base text-fadedWhite w-1/3">
                    <Divider
                      style={{
                        margin: "8px 0",
                        // borderColor: '',
                        // color: "white",
                      }}
                      className="text-fadedWhite text-sm"
                    >
                      OR
                    </Divider>
                  </span>
                </span>
                <span className="flex relative justify-center ">
                  <ButtonDefault
                    size={4}
                    variant={ButtonVariants.UNSTYLED}
                    className="disabled:bg-bgDarkGray hover:bg-[#181818] disabled:text-gray-300 text-fadedWhite bg-black w-full  border border-bgDarkGray shadow-sm text-base"
                    rounded={true}
                    loading={googleLoading}
                    spinColor="gray"
                    disabled={googleLoading}
                    onClick={(e) => {
                      e.preventDefault();
                      handleGoogleLogin();
                    }}
                  >
                    <span className="flex  w-full justify-center text-white   items-center font-medium gap-x-3 px-8  whitespace-nowrap">
                      <span className="w-7 h-7 absolute left-4">
                        <img
                          src={GoogleIcon}
                          alt="GoogleLogo"
                          className="object-contain"
                        />
                      </span>{" "}
                      Continue with Google
                    </span>
                  </ButtonDefault>
                </span>
                <ShouldRender check={showAppleLogin}>
                  <span className="flex justify-center ">
                    <ButtonDefault
                      size={4}
                      variant={ButtonVariants.UNSTYLED}
                      loading={appleLoading}
                      spinColor="gray"
                      rounded={true}
                      disabled={appleLoading}
                      className="disabled:bg-bgDarkGray hover:bg-[#181818] disabled:text-gray-300 text-fadedWhite bg-black w-full  border border-bgDarkGray shadow-sm text-base"
                      onClick={(e) => {
                        e.preventDefault();
                        handleAppleLogin();
                      }}
                    >
                      <span className="flex relative w-full items-end justify-center text-white   font-medium gap-x-3 px-8  whitespace-nowrap">
                        <span className="w-5  absolute left-1">
                          <img
                            src={AppleLogo}
                            alt="GoogleLogo"
                            className="object-contain"
                          />
                        </span>{" "}
                        Continue with Apple
                      </span>
                    </ButtonDefault>
                  </span>
                </ShouldRender>

                <div className="flex justify-center">
                  <p className="text-center text-fadedWhite font-medium text-base w-2/3 ">
                    By using our service, you are agreeing to our{" "}
                    <a
                      href="https://zebralearn.com/Privacy_Policy_Reader.html"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary font-medium cursor-pointer hover:text-darkPrimary"
                    >
                      Terms
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://zebralearn.com/Privacy_Policy_Reader.html"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary font-medium cursor-pointer hover:text-darkPrimary"
                    >
                      conditions
                    </a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default SignUp;
