import clsx from "clsx";
import React from "react";
import { useRecoilValue } from "recoil";
import { dateFilter } from "../../../../atoms/dateFilter";
import calculateDateDifference from "../../../shared/basic/daysDifference";
import { ShouldRender } from "../../../shared/basic/ShouldRender";
import { formatIndianCurrency } from "../../../shared/formatIndianCurrency";

interface CardDataProps {
  isWithdraw?: boolean;
  data: { title: string; price: number; date: string };
}

const CardData: React.FC<CardDataProps> = ({ isWithdraw = false, data }) => {
  const { title, price } = data;
  const dateFilterValue = useRecoilValue(dateFilter);
  const { months, remainingDays } = calculateDateDifference(
    dateFilterValue?.start_date,
    dateFilterValue?.end_date
  );

  return (
    <div className=" flex justify-center w-full">
      <div className="flex gap-y-3 flex-col text-center bg-readerLightGrayBg w-full rounded-xl  ">
        <p className="text-sm text-[#CECECE] font-medium"> {title}</p>

        <p
          className={clsx(
            isWithdraw ? "text-3xl font-bold" : "text-2xl ",
            " text-fadedWhite whitespace-nowrap"
          )}
        >
          <ShouldRender check={price}>
            {" "}
            {(title === "Total withdrawable Amount" ||
              title === "Total Sale") &&
              "₹"}{" "}
            {formatIndianCurrency(+price)}{" "}
          </ShouldRender>
          <ShouldRender check={!price}>-</ShouldRender>
        </p>

        <span className="flex justify-center mt-1">
          <p className="bg-black px-6 whitespace-nowrap py-1 text-xs text-textGrayColor  rounded-full">
            <ShouldRender check={months || remainingDays}>
              Last <ShouldRender check={months}>{months} months</ShouldRender>{" "}
              <ShouldRender check={remainingDays}>
                {" "}
                {remainingDays} days
              </ShouldRender>
            </ShouldRender>
            <ShouldRender check={!(months || remainingDays)}>All</ShouldRender>
          </p>
        </span>
      </div>
    </div>
  );
};
export default CardData;
