import { Helmet } from "react-helmet";
import React from "react";
import { pageTitle } from "../../components/shared/basic/customHead";

const StatusPage = () => {
  return (
    <div>
      <Helmet>
        <title>{pageTitle("Status")}</title>
      </Helmet>
      <div className="text-white">status: OK</div>
    </div>
  );
};

export default StatusPage;
