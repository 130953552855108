import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { UserData } from "../../../utils/interfaces/user";
import { user } from "../../../atoms/toggleTheme/user";
import { Button, Input, Progress, Spin } from "antd";
import PasswordIcon from "../../../assets/Icons/password";
import { ShouldRender } from "../../shared/basic/ShouldRender";
import { changePassword, getUserById } from "../../../services/user";
import extractErrorMessage from "../../shared/basic/formateError";
import { notify } from "../../shared/basic/notify";
import { useParams } from "react-router-dom";
import { getAdminRole } from "../../../utils/lib/auth";

const SettingPage: React.FC = () => {
  const [formData, setFormData] = useState({
    oldPassword: "",
    password: "",
    retryPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState<number>(0);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState<string>();
  const { id } = useParams();
  const isAdmin = getAdminRole();

  const [errors, setErrors] = useState({
    oldPassword: "",
    password: "",
    retryPassword: "",
  });
  const userData = useRecoilValue<UserData | undefined>(user);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name === "_email" ? "email" : name === "_password" ? "password" : name]:
        value,
    });
    if (name === "_password") {
      const strength = calculatePasswordStrength(value);
      setPasswordStrength(strength);
    }
  };
  const calculatePasswordStrength = (password: string) => {
    let strength = 0;

    // Check password length
    if (password.length >= 8) {
      strength += 1;
    }

    // Check for uppercase letters
    if (/[A-Z]/.test(password)) {
      strength += 1;
    }

    // Check for lowercase letters
    if (/[a-z]/.test(password)) {
      strength += 1;
    }

    // Check for at least one digit
    if (/\d/.test(password)) {
      strength += 1;
    }

    // Check for special characters
    if (/[\W_]/.test(password)) {
      strength += 1;
    }

    return strength;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validation
    const validationErrors = {
      oldPassword: "",
      password: "",
      retryPassword: "",
    };

    if (!formData.oldPassword) {
      validationErrors.oldPassword = "Old - Password is required";
    }

    if (!formData.password) {
      validationErrors.password = "Password is required";
    } else if (formData.password.length < 8) {
      validationErrors.password = "Password must be at least 8 characters long";
    }

    if (!formData.retryPassword) {
      validationErrors.retryPassword = "Please confirm your password";
    } else if (formData.retryPassword !== formData.password) {
      validationErrors.retryPassword =
        "The confirmed password does not match the entered password. Please change and retry.";
    }

    setErrors(validationErrors);

    if (Object.values(validationErrors).every((error) => !error)) {
      try {
        setLoading(true);
        await changePassword(formData?.oldPassword, formData?.password);
        setFormData({
          oldPassword: "",
          password: "",
          retryPassword: "",
        });
        setShowPassword(false);
        notify("Password updated successfully!", "success");
      } catch (error: any) {
        const message = extractErrorMessage(error);
        notify(message, "error");
      } finally {
        setLoading(false);
      }
    }
  };

  const getUserDetailsById = async (userId: string) => {
    try {
      setLoading(true);
      const data = await getUserById(userId);
      setEmail(data?.user?.email);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isAdmin === "true") {
      getUserDetailsById(id as string);
    }
  }, [isAdmin, id]);

  const getStrengthText = () => {
    switch (passwordStrength) {
      case 1:
        return "Too Weak";
      case 2:
        return "Weak";
      case 3:
        return "Moderate";
      case 4:
        return "Strong";
      case 5:
        return "Very Strong";
      default:
        return "";
    }
  };

  return (
    <div>
      <div className="border-[#444444] border px-4 md:px-8 py-6 rounded-xl flex flex-col gap-y-8 ">
        <div>
          <span className="text-lg font-medium text-[#BABABA]">Email</span>
          <ShouldRender check={!loading}>
            <span>
              <p className="text-base text-fadedWhite">
                {email ?? userData?.email}
              </p>
              <p className="text-[#7C7C7C]">
                Use this email to sign in, change your password
              </p>
            </span>
          </ShouldRender>

          <ShouldRender check={loading}>
            <span className="py-10 flex justify-center items-center">
              {" "}
              <Spin size="large" />
            </span>
          </ShouldRender>
        </div>
        <ShouldRender check={!id}>
          <div className="flex flex-col gap-y-4">
            <span>
              <span className="text-lg font-medium text-[#BABABA]">
                Password
              </span>
              <span>
                <p className="text-[#7C7C7C]">
                  You need the password to log in to your personal account
                </p>
              </span>
            </span>
            <ShouldRender check={showPassword}>
              <div className="w-1/2">
                <form className="flex flex-col mt-2 gap-y-3">
                  <div className="col-span-1 flex-col flex ">
                    <Input.Password
                      name="oldPassword"
                      placeholder="Old Password"
                      value={formData.oldPassword}
                      style={{
                        backgroundColor: "#141414",
                      }}
                      prefix={
                        <span className="pr-2">
                          <PasswordIcon />
                        </span>
                      }
                      onChange={handleChange}
                      size="large"
                      autoComplete="new-password"
                      className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-2  text-white bg-bgColor customInputBlack makeBgBlack"
                    />
                    {errors.oldPassword && (
                      <p className="text-red-500">{errors.oldPassword}</p>
                    )}
                  </div>
                  <div className="col-span-1 flex-col flex ">
                    <Input.Password
                      name="_password"
                      placeholder="New Password"
                      value={formData.password}
                      style={{
                        backgroundColor: "#141414",
                      }}
                      prefix={
                        <span className="pr-2">
                          <PasswordIcon />
                        </span>
                      }
                      onChange={handleChange}
                      size="large"
                      autoComplete="new-password"
                      className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-2  text-white bg-bgColor customInputBlack makeBgBlack"
                    />
                    {errors.password && (
                      <p className="text-red-500">{errors.password}</p>
                    )}
                    <ShouldRender check={formData.password}>
                      {passwordStrength !== null && (
                        <span className="relative">
                          <Progress
                            percent={(passwordStrength / 5) * 100}
                            showInfo={false}
                            status={
                              passwordStrength >= 3 ? "success" : "exception"
                            }
                          />
                          <p
                            className={`text-sm text-${
                              passwordStrength >= 3 ? "green" : "red"
                            }-500 `}
                          >
                            {getStrengthText()}
                          </p>
                        </span>
                      )}
                    </ShouldRender>
                  </div>
                  <div className="col-span-1 flex-col flex ">
                    <Input.Password
                      name="retryPassword"
                      placeholder="confirm Password"
                      value={formData.retryPassword}
                      style={{
                        backgroundColor: "#141414",
                      }}
                      prefix={
                        <span className="pr-2">
                          <PasswordIcon />
                        </span>
                      }
                      onChange={handleChange}
                      size="large"
                      autoComplete="new-password"
                      className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-2  text-white bg-bgColor customInputBlack makeBgBlack"
                    />
                    {errors.retryPassword && (
                      <p className="text-red-500">{errors.retryPassword}</p>
                    )}
                  </div>
                </form>
              </div>
            </ShouldRender>

            <span>
              <Button
                disabled={loading}
                loading={loading}
                onClick={(e) => {
                  setShowPassword(true);
                  if (showPassword) {
                    handleSubmit(e);
                  }
                }}
              >
                {!showPassword ? "Change Password" : "Update Password"}
              </Button>
            </span>
          </div>
        </ShouldRender>
      </div>
    </div>
  );
};
export default SettingPage;
