import React from "react";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";
import { pageTitle } from "../../components/shared/basic/customHead";
import IFrame from "../../components/IFrameComp";

const IFramePage = () => {
  return (
    <div>
      <Helmet>
        <title>{pageTitle("IFrame")}</title>
      </Helmet>
      <div className="relative">
        <IFrame />
      </div>
      <Outlet />
    </div>
  );
};

export default IFramePage;
