import { Helmet } from "react-helmet";
import { pageTitle } from "../../components/shared/basic/customHead";
import ResetPassword from "../../components/Auth/ResetPassword";
import React from "react";

const ResetPasswordPage = () => {
  return (
    <>
      <Helmet>
        <title>{pageTitle("Reset Password")}</title>
      </Helmet>

      <ResetPassword />
    </>
  );
};
export default ResetPasswordPage;
