import React, { useEffect, useState } from "react";
import Card from "../Card";
import { DatePicker, Progress, Spin } from "antd";
import CardData from "../CardData";
import { useRecoilState, useRecoilValue } from "recoil";
import { dateFilter } from "../../../../atoms/dateFilter";
import { ShouldRender } from "../../../shared/basic/ShouldRender";
import {
  getAdminTotalEarning,
  getTotalEarning,
} from "../../../../services/dashboard";
import extractErrorMessage from "../../../shared/basic/formateError";
import { notify } from "../../../shared/basic/notify";
import dayjs, { Dayjs } from "dayjs";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { formatIndianCurrency } from "../../../shared/formatIndianCurrency";
import { useParams } from "react-router-dom";

export enum Status {
  delivered = "Delivered",
  pending = "Pending",
  canceled = "Canceled",
  paid = "Paid",
  unpaid = "Un-Paid",
  refunded = "Refunded",
  failed = "failed",
}

const WithdrawSection = () => {
  const [loading, setLoading] = useState(false);
  const [totalEarningData, setTotalEarningData] = useState<{
    total_earnings: number;
    total_earnings_by_status: {
      delivered?: number;
      pending?: number;
      canceled?: number;
      paid?: number;
      unpaid?: number;
      refunded?: number;
    };
  }>();
  const [, setDateFilter] = useRecoilState(dateFilter);

  const dateFilterValue = useRecoilValue(dateFilter);
  const { id } = useParams();

  const screens = useBreakpoint();

  const cardDate = {
    title: "Total Sale",
    price: totalEarningData?.total_earnings as number,
    date: "30days",
  };

  const handleTotalEarning = async () => {
    try {
      setLoading(true);
      let response;
      if (id) {
        response = await getAdminTotalEarning(
          +id,
          dateFilterValue?.start_date,
          dateFilterValue?.end_date
        );
      } else {
        response = await getTotalEarning(
          dateFilterValue?.start_date,
          dateFilterValue?.end_date
        );
      }

      setTotalEarningData({
        total_earnings: response?.total_earnings,
        total_earnings_by_status: {
          canceled: response?.total_earnings_by_status?.canceled || 0,
          delivered: response?.total_earnings_by_status?.delivered || 0,
          paid: response?.total_earnings_by_status?.paid || 0,
          pending: response?.total_earnings_by_status?.pending || 0,
          refunded: response?.total_earnings_by_status?.refunded || 0,
          unpaid: response?.total_earnings_by_status?.unpaid || 0,
        },
      });
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleTotalEarning();
  }, [dateFilterValue]);

  const allValuesZero = Object.values(
    totalEarningData?.total_earnings_by_status || {}
  ).every((value) => value === 0);

  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      setDateFilter({
        start_date: dateStrings[0] as string,
        end_date: dateStrings[1] as string,
      });
    } else {
      setDateFilter({
        start_date: "" as string,
        end_date: "" as string,
      });
    }
  };

  return (
    <div>
      <Card>
        <div className="flex flex-col lg:flex-row w-full gap-8 px-10 xl:px-16 py-10">
          <ShouldRender check={!loading}>
            <ShouldRender check={!screens?.md}>
              <span className="flex items-end">
                <DatePicker.RangePicker
                  onChange={onRangeChange}
                  disabledDate={(current) =>
                    current && current > dayjs().endOf("day")
                  }
                />
              </span>
            </ShouldRender>

            <span className="lg:w-5/12 flex items-center justify-center flex-col gap-y-3 py-2">
              <CardData data={cardDate} isWithdraw={true} />
            </span>
            <span className="lg:w-7/12 flex flex-col gap-y-2">
              {totalEarningData?.total_earnings_by_status &&
                (allValuesZero
                  ? Object.entries(
                      totalEarningData?.total_earnings_by_status
                    ).slice(0, 4)
                  : Object.entries(
                      totalEarningData?.total_earnings_by_status
                    ).filter(([, value]) => value !== 0)
                ).map(([key, value]) => (
                  <div key={key}>
                    <span className="flex justify-between text-fadedWhite">
                      <p className="font-medium text-sm">
                        {Status[key as keyof typeof Status]}
                      </p>
                      <p>₹ {formatIndianCurrency(value)}</p>
                    </span>
                    <Progress
                      percent={(value / totalEarningData?.total_earnings) * 100}
                      showInfo={false}
                      trailColor="#000000"
                      strokeColor={"#868686"}
                      status={
                        value >= totalEarningData?.total_earnings
                          ? "success"
                          : "exception"
                      }
                    />
                  </div>
                ))}
            </span>
          </ShouldRender>
          <ShouldRender check={loading}>
            <span className="py-10 w-full flex justify-center items-center">
              {" "}
              <Spin size="large" />
            </span>
          </ShouldRender>
        </div>
      </Card>
    </div>
  );
};
export default WithdrawSection;
