export const formatIndianCurrency = (num: number): string => {
  // Convert the number to a string
  const numStr = num?.toString();

  // Split the number into integer and decimal parts
  const parts = numStr?.split(".");
  if (parts?.length > 0) {
    let integerPart = parts[0];
    const decimalPart = parts.length > 1 ? "." + parts[1] : "";

    // Add commas to the integer part
    integerPart = integerPart?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return integerPart + decimalPart;
  }
  return "0";
  // Return the formatted number with Indian currency symbol
};
