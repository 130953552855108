import { Endpoints } from "../../network";
import apiClient from "../base";
import baseForPdf from "../readerBase";

export const getAllShortBookListApi = async (title?: string) => {
  const replacedTitle = title ? title.replace(/%2520/g, "%20") : "";
  const urlWithQuery = `${Endpoints.getAllShortBooks}${
    replacedTitle ? `?title=${replacedTitle}` : ""
  }`;
  const response = await baseForPdf.get(urlWithQuery);
  return response?.data?.data;
};

export const handleXlsxIFrameFile = async ({
  from,
  to,
}: {
  from?: string;
  to?: string;
}) => {
  let urlApi = `${Endpoints.getXlsxFile}`;
  if (from && to) {
    urlApi += `?from=${from}&to=${to}`;
  }

  const response = await apiClient.get(urlApi);
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${Date.now()}.csv`);
  document.body.appendChild(link);
  link.click();
  return response;
};
