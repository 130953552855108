import { Tabs } from "antd";
import React, { useState, useEffect } from "react";
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import SettingPage from "../../components/ProfileComp/SettingPage";
import PaymentManagement from "../../components/ProfileComp/PaymentManagement";
import { Helmet } from "react-helmet";
import { pageTitle } from "../../components/shared/basic/customHead";
import Profile from "../../components/ProfileComp/Profile";
import { getAdminRole } from "../../utils/lib/auth";
import DashboardComp from "../../components/DashboardComp/Dashboard";

const ProfilePage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState("1");
  const isAdmin = getAdminRole();

  const handleTabChange = (key: string) => {
    setActiveTab(key);
    setSearchParams({ tab: key });
  };

  useEffect(() => {
    if (searchParams.get("tab")) {
      setActiveTab(searchParams.get("tab") as string);
    }
  }, [params.tab, navigate]);

  const items = [
    {
      key: "1",
      label: <div className="text-sm font-medium px-1">Profile</div>,
      children: <Profile />,
    },
    {
      key: "2",
      label: <div className="text-sm font-medium px-1">Settings</div>,
      children: <SettingPage />,
    },
    {
      key: "3",
      label: <div className="text-sm font-medium px-1">Payment method</div>,
      children: <PaymentManagement />,
    },
    ...(isAdmin === "true"
      ? [
          {
            key: "4",
            label: (
              <div className="text-sm font-medium px-1">User Dashboard</div>
            ),
            children: <DashboardComp />,
          },
        ]
      : []),
  ];

  return (
    <div>
      <Helmet>
        <title>{pageTitle("Profile & Settings")}</title>
      </Helmet>
      <div className="relative">
        <Tabs
          activeKey={activeTab}
          items={items}
          onTabClick={handleTabChange}
          className="customTab"
        ></Tabs>
      </div>
      <Outlet />
    </div>
  );
};

export default ProfilePage;
