import React from "react";
import { CustomIconProps } from "../../utils/interfaces/user/icons";

const DashboardIcon: React.FC<CustomIconProps & { outlined?: boolean }> = ({
  width,
  height,
  fill,
  outlined,
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "24"}
      height={height ? height : "24"}
      viewBox="0 0 24 24"
      fill={outlined ? "none" : fill}
      stroke={outlined ? fill : "none"}
      strokeWidth={outlined ? "1.5" : "0"}
      {...rest}
    >
      <path
        d="M8.25 2V22H7.81C4.17 22 2 19.83 2 16.19V7.81C2 4.17 4.17 2 7.81 2H8.25Z"
        fill={outlined ? "none" : fill}
      />
      <path
        d="M22 7.81V11.25H9.75V2H16.19C19.83 2 22 4.17 22 7.81Z"
        fill={outlined ? "none" : fill}
      />
      <path
        d="M22 12.75V16.19C22 19.83 19.83 22 16.19 22H9.75V12.75H22Z"
        fill={outlined ? "none" : fill}
      />
    </svg>
  );
};

export default DashboardIcon;
