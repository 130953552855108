import React, { useEffect, useState } from "react";
import Card from "../Card";
import {
  getAdminCommission,
  getCommission,
  handleWithdraw,
} from "../../../../services/dashboard";
import { useRecoilValue } from "recoil";
import { dateFilter } from "../../../../atoms/dateFilter";
import extractErrorMessage from "../../../shared/basic/formateError";
import { notify } from "../../../shared/basic/notify";
import ButtonDefault, { ButtonVariants } from "../../../shared/basic/button";
import { ShouldRender } from "../../../shared/basic/ShouldRender";
import calculateDateDifference from "../../../shared/basic/daysDifference";
import { Popover, Spin } from "antd";
import { formatIndianCurrency } from "../../../shared/formatIndianCurrency";
import { useParams } from "react-router-dom";

const TotalWithdrawAmount = () => {
  const [loading, setLoading] = useState(false);
  const [totalWithdrawAmountData, setTotalWithdrawAmountData] = useState<{
    total_paid: number;
    total_withdrawable: number;
    pending: boolean;
  }>();
  const withdraw_amount = process.env.REACT_APP_WITHDRAW_AMOUNT;

  const dateFilterValue = useRecoilValue(dateFilter);
  const { id } = useParams();

  const { months, remainingDays } = calculateDateDifference(
    dateFilterValue?.start_date,
    dateFilterValue?.end_date
  );

  const handleCommotions = async () => {
    try {
      setLoading(true);
      let response: {
        total_paid: number;
        total_withdrawable: number;
        pending: boolean;
      } | null = null;
      if (id) {
        response = await getAdminCommission(
          +id,
          dateFilterValue?.start_date,
          dateFilterValue?.end_date
        );
      } else {
        response = await getCommission(
          dateFilterValue?.start_date,
          dateFilterValue?.end_date
        );
      }

      setTotalWithdrawAmountData({
        total_paid: response?.total_paid as number,
        total_withdrawable: response?.total_withdrawable as number,
        pending: response?.pending as boolean,
      });
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleWithdrawApi = async () => {
    try {
      await handleWithdraw();
      await handleCommotions();
      notify("Requested withdrawal!!", "success");
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    }
  };

  useEffect(() => {
    handleCommotions();
  }, [dateFilterValue]);

  return (
    <div>
      <Card>
        <div className="flex flex-col lg:flex-row w-full gap-8 px-10 xl:px-16 py-10">
          <ShouldRender check={!loading}>
            <div className="flex flex-col md:flex-row  w-full">
              <span className="md:w-1/2 flex flex-col gap-y-10">
                {" "}
                <span className="flex flex-col gap-y-2">
                  <p className="text-sm text-[#CECECE] font-medium">
                    {" "}
                    Total withdrawable Amount
                  </p>
                  <p className="text-4xl text-fadedWhite font-medium">
                    ₹{" "}
                    {formatIndianCurrency(
                      totalWithdrawAmountData?.total_withdrawable as number
                    )}
                  </p>
                </span>
                <ShouldRender check={!id}>
                  <ShouldRender
                    check={
                      (+(totalWithdrawAmountData?.total_withdrawable || 0) ||
                        0) < (+(withdraw_amount || 0) as number)
                    }
                  >
                    <Popover
                      content={
                        <div className=" gap-x-2 max-w-48">
                          Withdrawals are available once you reach a minimum
                          balance of
                          <span className="font-bold inline-flex px-2">
                            {" "}
                            ₹ {withdraw_amount}/-{" "}
                          </span>
                          in your account
                        </div>
                      }
                    >
                      <ButtonDefault
                        size={2}
                        variant={ButtonVariants.UNSTYLED}
                        className="w-min p-1 disabled:cursor-not-allowed rounded bg-fadedWhite hover:bg-gray-100 text-black disabled:bg-lightGray disabled:text-gray-400 disabled:border-0"
                        disabled={
                          (+(
                            totalWithdrawAmountData?.total_withdrawable || 0
                          ) || 0) < (+(withdraw_amount || 0) as number)
                        }
                        onClick={() => handleWithdrawApi()}
                      >
                        <span className="flex text-xs font-semibold text-black px-1 xl:px-3 whitespace-nowrap">
                          Withdraw
                        </span>
                      </ButtonDefault>
                    </Popover>
                  </ShouldRender>
                  <ShouldRender
                    check={
                      !(
                        (+(totalWithdrawAmountData?.total_withdrawable || 0) ||
                          0) < (+(withdraw_amount || 0) as number)
                      )
                    }
                  >
                    <ButtonDefault
                      size={2}
                      variant={ButtonVariants.UNSTYLED}
                      className="w-min p-1 disabled:cursor-not-allowed rounded bg-fadedWhite hover:bg-gray-100 text-black disabled:bg-lightGray disabled:text-gray-400 disabled:border-0"
                      disabled={
                        (+(totalWithdrawAmountData?.total_withdrawable || 0) ||
                          0) < (+(withdraw_amount || 0) as number)
                      }
                      onClick={() => handleWithdrawApi()}
                    >
                      <span className="flex text-xs font-semibold text-black px-1 xl:px-3 whitespace-nowrap">
                        Withdraw
                      </span>
                    </ButtonDefault>
                  </ShouldRender>
                </ShouldRender>
              </span>
              <span className="md:w-1/2 flex gap-y-2 flex-col items-end justify-end">
                <p className="bg-black px-6 w-min whitespace-nowrap py-1 text-xs text-textGrayColor rounded-full">
                  <ShouldRender check={months || remainingDays}>
                    Last{" "}
                    <ShouldRender check={months}>{months} months</ShouldRender>{" "}
                    <ShouldRender check={remainingDays}>
                      {" "}
                      {remainingDays} days
                    </ShouldRender>
                  </ShouldRender>
                  <ShouldRender check={!(months || remainingDays)}>
                    All
                  </ShouldRender>
                </p>
                <p className="text-textGrayColor">
                  Total Paid Amount: ₹{" "}
                  {formatIndianCurrency(
                    totalWithdrawAmountData?.total_paid as number
                  )}
                </p>
              </span>
            </div>
          </ShouldRender>
          <ShouldRender check={loading}>
            <span className="py-10 w-full flex justify-center items-center">
              {" "}
              <Spin size="large" />
            </span>
          </ShouldRender>
        </div>
      </Card>
    </div>
  );
};
export default TotalWithdrawAmount;
