import { SearchIcon } from "@heroicons/react/outline";
import { Input, Tabs } from "antd";
import React, { useState, useEffect } from "react";
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Books from "../../components/ProductsComp/Books";
import Courses from "../../components/ProductsComp/Courses";
import { Helmet } from "react-helmet";
import { pageTitle } from "../../components/shared/basic/customHead";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { ShouldRender } from "../../components/shared/basic/ShouldRender";
import { useRecoilState } from "recoil";
import { searchKeywords } from "../../atoms/searchKeywords";

const ProductsPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState("1");
  const [searchKeyword, setSearchKeyword] = useRecoilState(searchKeywords);
  const screens = useBreakpoint();

  const handleTabChange = (key: string) => {
    setActiveTab(key);
    setSearchParams({ tab: key });
  };

  useEffect(() => {
    if (searchParams.get("tab")) {
      setActiveTab(searchParams.get("tab") as string);
    }
  }, [params.tab, navigate]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchKeyword = e.target.value;
    setSearchKeyword(newSearchKeyword);
  };

  const items = [
    {
      key: "1",
      label: <div className="text-sm font-medium px-1">Books</div>,
      children: <Books />,
    },
    {
      key: "2",
      label: <div className="text-sm font-medium px-1">Courses</div>,
      children: <Courses />,
    },
  ];

  return (
    <div>
      <Helmet>
        <title>{pageTitle("Products")}</title>
      </Helmet>
      <div className="relative ">
        <ShouldRender check={screens?.md}>
          <div className=" absolute z-20 flex right-0 -top-2 justify-end">
            <Input
              size="large"
              placeholder="Search for Books and Courses"
              className="rounded-lg  border-none customSearch text-gray-100 text-sm placeholder:text-gray-400 h-10 bg-readerLightGrayBg"
              value={searchKeyword}
              prefix={<SearchIcon className="w-5 h-5 pr-1 text-[#A6A6A6]" />}
              onChange={handleInputChange}
            />
          </div>
        </ShouldRender>

        <Tabs
          activeKey={activeTab}
          items={items}
          onTabClick={handleTabChange}
          className="customTab"
        />
      </div>
      <Outlet />
    </div>
  );
};

export default ProductsPage;
