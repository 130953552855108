import { Endpoints } from "../../network";
import apiClient from "../base";

interface IBankDetails {
  account_number: string;
  ifsc_code: string;
  bank_name: string;
  branch: string;
}

export const addBankDetails = async (data: IBankDetails) => {
  const response = await apiClient.post(Endpoints.addBankDetails, data);

  return response;
};

export const updateBankDetails = async (data: IBankDetails, id: string) => {
  const response = await apiClient.patch(
    `${Endpoints.addBankDetails}/${id}`,
    data
  );

  return response;
};

export const updateBankDetailsStatus = async (
  is_verified: boolean,
  id: string
) => {
  const response = await apiClient.patch(`${Endpoints.addBankDetails}/${id}`, {
    is_verified,
  });

  return response;
};
export const getBankDetails = async () => {
  const response = await apiClient.get(Endpoints.addBankDetails);

  return response?.data?.data;
};

export const getAffiliateChannels = async () => {
  const response = await apiClient.get(Endpoints.affiliateChannels);

  return response?.data?.data;
};

export const addAffiliateChannels = async (profile_link: string) => {
  const response = await apiClient.post(Endpoints.affiliateChannels, {
    profile_link,
  });

  return response?.data?.data;
};

export const updateAffiliateChannels = async (
  profile_link: string,
  id: number
) => {
  const response = await apiClient.patch(
    `${Endpoints.affiliateChannels}/${id}`,
    {
      profile_link,
    }
  );

  return response?.data?.data;
};

export const deleteAffiliateChannels = async (id: number) => {
  const response = await apiClient.delete(
    `${Endpoints.affiliateChannels}/${id}`
  );

  return response?.data?.data;
};
